import React from 'react';


import {withRouter} from 'react-router-dom';
import SiteWrapper from "../../SiteWrapper";
import BreadCrumb from "../../components/BreadCrumb";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import {withStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import ProcessesPage from "./ProcessesPage";
import RulesPage from "./RulesPage";
import ApproversPage from "./ApproversPage";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import useStyles from "../../styles";


const WorkflowPage = (props) => {

    const classes = useStyles();

    const [value, setValue] = React.useState(0);

    const StyledTabs = withStyles({
        indicator: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            '& > span': {
                maxWidth: 40,
                width: '100%',
                backgroundColor: '#0061a8',
            },
        },
    })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

    const StyledTab = withStyles((theme) => ({
        root: {
            textTransform: 'none',
            color: '#051e34',
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: theme.typography.pxToRem(15),
            '&:focus': {
                opacity: 1,
            },
        },
    }))((props) => <Tab disableRipple {...props} />);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <SiteWrapper>
                <BreadCrumb crumbs={[{
                    'name': 'Workflow',
                    'link': '#'
                }]}/>

                <Grid container className={classes.marginTop}>
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <Typography className={clsx(classes.pageTitle)}>
                                Workflow
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>


                <Grid container justify="flex-end">
                    {/* Recent Deposits */}
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper>
                            <div>
                                <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example">
                                    <StyledTab label="Processes" {...a11yProps(0)}/>
                                    <StyledTab label="Rules" {...a11yProps(1)} />
                                    <StyledTab label="Approvers" {...a11yProps(2)}/>
                                </StyledTabs>
                            </div>
                            <TabPanel value={value} index={0}>
                                <ProcessesPage/>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <RulesPage/>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                               <ApproversPage/>
                            </TabPanel>
                        </Paper>
                    </Grid>

                </Grid>
            </SiteWrapper>
        </>
    );
};


export default withRouter(WorkflowPage);
import { push } from 'connected-react-router';

import {
    getPurchases,
    getPurchase,
    addPurchase,
    editPurchase,
    deletePurchase
} from "../../services/PurchaseService";


import {purchaseConstants} from "../ActionTypes";

import {alertActions} from "./AlertAction";

export const getPurchasesAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: purchaseConstants.PENDING_PURCHASE_REQUEST
        });
        return getPurchases(data).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: purchaseConstants.GET_PURCHASES_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: purchaseConstants.GET_PURCHASES_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};


export const getPurchaseAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: purchaseConstants.PENDING_PURCHASE_REQUEST
        });
        return getPurchase(data).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: purchaseConstants.GET_PURCHASE_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: purchaseConstants.GET_PURCHASE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};


export const addPurchaseAction = (purchase, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: purchaseConstants.PENDING_PURCHASE_REQUEST
        });
        return addPurchase(purchase).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: purchaseConstants.ADD_PURCHASE_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/purchases/' + response.data.id));
                    dispatch(alertActions.success('Purchase added successfully'));

                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: purchaseConstants.ADD_PURCHASE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};


export const editPurchaseAction = (id, purchase, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: purchaseConstants.PENDING_PURCHASE_REQUEST
        });
        return editPurchase(id, purchase).then(
            (response) => {
                if (response.data) {

                    dispatch({
                        type: purchaseConstants.EDIT_PURCHASE_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/purchases/' + id));
                    dispatch(alertActions.success('Purchase edited successfully'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: purchaseConstants.EDIT_PURCHASE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};

export const deletePurchaseAction = (id) => {
    return (dispatch) => {
        dispatch({
            type: purchaseConstants.PENDING_PURCHASE_REQUEST
        });
        return deletePurchase(id).then(
            (response) => {
                if (response) {
                    dispatch({
                        type: purchaseConstants.DELETE_PURCHASE_SUCCESS,
                        payload: id
                    });
                    dispatch(push('/purchases'));
                    dispatch(alertActions.success('Purchase deleted successfully'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: purchaseConstants.DELETE_PURCHASE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};
import { push } from 'connected-react-router';

import {
    getConfigurations,
    addConfiguration,
    editConfiguration,
    deleteConfiguration
} from "../../services/ConfigurationService";


import {configurationConstants} from "../ActionTypes";

import {alertActions} from "./AlertAction";

export const getConfigurationsAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: configurationConstants.PENDING_CONFIGURATION_REQUEST
        });
        return getConfigurations(data).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: configurationConstants.GET_CONFIGURATIONS_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: configurationConstants.GET_CONFIGURATIONS_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};



export const addConfigurationAction = (configuration, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: configurationConstants.PENDING_CONFIGURATION_REQUEST
        });
        return addConfiguration(configuration).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: configurationConstants.ADD_CONFIGURATION_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/configurations'));
                    dispatch(alertActions.success('Configuration added successful'));

                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: configurationConstants.ADD_CONFIGURATION_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};


export const editConfigurationAction = (id, configuration, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: configurationConstants.PENDING_CONFIGURATION_REQUEST
        });
        return editConfiguration(id, configuration).then(
            (response) => {
                if (response.data) {

                    dispatch({
                        type: configurationConstants.EDIT_CONFIGURATION_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/configurations/' + id + '/view'));
                    dispatch(alertActions.success('Configuration edited successful'));

                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: configurationConstants.EDIT_CONFIGURATION_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback(error.response.data);
                }
            }
        );
    };
};

export const deleteConfigurationAction = (id) => {
    return (dispatch) => {
        dispatch({
            type: configurationConstants.PENDING_CONFIGURATION_REQUEST
        });
        return deleteConfiguration(id).then(
            (response) => {
                if (response) {
                    dispatch({
                        type: configurationConstants.DELETE_CONFIGURATION_SUCCESS,
                        payload: id
                    });
                    dispatch(push('/configurations'));
                    dispatch(alertActions.success('Configuration deleted successful'));

                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: configurationConstants.DELETE_CONFIGURATION_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};
import React from 'react';
import {withRouter} from 'react-router-dom';
// styles
import useStyles from "./styles";

import {useSelector} from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import userIcon from "../../assets/images/generic-profile.jpeg";
import Link from "@material-ui/core/Link";
import Box from '@material-ui/core/Box';




const Header = () => {

    const classes = useStyles();

    const [profileMenu, SetProfileMenu] = React.useState(null);
    const anchorRef = React.useRef(null);

    const currentUser = useSelector(state => state.auth.user);

    const profileClick = (event) => {
        SetProfileMenu(event.currentTarget);
    };

    const profileClose = () => {
        SetProfileMenu(null);
    };

    return (

        <div>
            {currentUser && (
                <Box>
                    <Avatar
                        alt="Profile picture"
                        src={userIcon}
                        ref={anchorRef}
                        onClick={profileClick}
                    />
                    <Menu
                        id="profile-menu"
                        anchorEl={profileMenu}
                        keepMounted
                        open={Boolean(profileMenu)}
                        onClose={profileClose}
                        className={classes.headerMenu}
                        classes={{paper: classes.profileMenu}}
                        disableAutoFocusItem
                    >
                        <div className={classes.profileMenuUser}>
                            <Typography variant="h5" weight="medium">{currentUser.user.fullName}</Typography>
                            <Typography >{currentUser.user.employee && currentUser.user.employee.designation.name}</Typography>
                        </div>
                        {/*<MenuItem onClick={profileClose} className={clsx(*/}
                        {/*    classes.profileMenuItem,*/}
                        {/*    classes.headerMenuItem,*/}
                        {/*)}>Profile</MenuItem>*/}
                        {/*<MenuItem onClick={profileClose} className={clsx(*/}
                        {/*    classes.profileMenuItem,*/}
                        {/*    classes.headerMenuItem,*/}
                        {/*)}>My account</MenuItem>*/}
                        <div className={classes.profileMenuUser}>
                            <Typography>
                                <Link className={classes.profileMenuLink} href="/login">
                                    Sign Out
                                </Link>
                            </Typography>
                        </div>
                    </Menu>
                </Box>
            )}
        </div>
    );
};

export default withRouter(Header);
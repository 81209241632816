import { push } from 'connected-react-router';

import {
    getAdjustments,
    addAdjustment,
} from "../../services/StockAdjustmentService";


import {stockAdjustConstants} from "../ActionTypes";

import {alertActions} from "./AlertAction";

export const getAdjustmentsAction = () => {
    return (dispatch) => {
        dispatch({
            type: stockAdjustConstants.PENDING_ADJUSTMENT_REQUEST
        });
        return getAdjustments().then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: stockAdjustConstants.GET_ADJUSTMENTS_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: stockAdjustConstants.GET_ADJUSTMENTS_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};


export const addAdjustmentAction = (employee, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: stockAdjustConstants.PENDING_ADJUSTMENT_REQUEST
        });
        return addAdjustment(employee).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: stockAdjustConstants.ADD_ADJUSTMENT_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/stock-adjustments'));
                    dispatch(alertActions.success('Stock adjusted successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: stockAdjustConstants.ADD_ADJUSTMENT_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};
import {
    contentTypeApplicationJson,
} from "../constants/httpConstants";

import {httpWrapper} from "../helper/Http";

export const getEmployees = async (data) => {
    return httpWrapper.get('/employees/', contentTypeApplicationJson, data);
};

export const getEmployee = async (id) => {
    return httpWrapper.get('/employees/' + id, contentTypeApplicationJson);
};

export const addEmployee = async (employee) => {
    return httpWrapper.post('/employees/' , employee, contentTypeApplicationJson);
};

export const editEmployee = async (id, employee) => {
    return httpWrapper.update('/employees/' + id + '/' , employee, contentTypeApplicationJson);
};

export const deleteEmployee = async (id) => {
    return httpWrapper.delete('/employees/' + id + '/' , contentTypeApplicationJson);
};

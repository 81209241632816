import { alertConstants } from '../ActionTypes';

function AlertReducer(state = {}, action) {

    switch (action.type) {

        case alertConstants.SUCCESS:
            return {
                type: 'success',
                response: action.message
            };
        case alertConstants.ERROR:
            return {
                type: 'error',
                response: action.message
            };
        case alertConstants.CLEAR:
            return {};
        default:
            return state
    }
}

export default AlertReducer;
import { combineReducers } from 'redux';
import SupplierReducer from './SupplierReducer';
import CustomerReducer from './CustomerReducer';
import AlertReducer from './AlertReducer';
import MachineReducer from "./MachineReducer";
import TripReducer from "./TripReducer";
import ConfigurationReducer from "./ConfigurationReducer";
import SettingReducer from "./SettingReducer";
import EmployeeReducer from "./EmployeeReducer";
import ProductReducer from "./ProductReducer";
import ExpenseReducer from "./ExpenseReducer";
import SaleReducer from "./SaleReducer";
import ServiceReducer from "./ServiceReducer";
import RouteReducer from "./RouteReducer";
import UserReducer from "./UserReducer";
import AuthReducer from "./AuthReducer";
import WorkflowReducer from "./WorkflowReducer";
import PurchaseReducer from "./PurchaseReducer";
import { connectRouter } from 'connected-react-router'
import StockAdjustmentReducer from "./StockAdjustmentReducer";

const rootReducer = (history) =>  combineReducers({
    router: connectRouter(history),
    supplier: SupplierReducer,
    customer: CustomerReducer,
    sale: SaleReducer,
    alert: AlertReducer,
    machine: MachineReducer,
    trip: TripReducer,
    configuration: ConfigurationReducer,
    setting: SettingReducer,
    employee: EmployeeReducer,
    product: ProductReducer,
    expense: ExpenseReducer,
    service: ServiceReducer,
    route: RouteReducer,
    user: UserReducer,
    workflow: WorkflowReducer,
    purchase: PurchaseReducer,
    auth: AuthReducer,
    stock_adjustment: StockAdjustmentReducer,
});

export default rootReducer;
import {
    contentTypeApplicationJson,
} from "../constants/httpConstants";

import {httpWrapper} from "../helper/Http";

export const getRoutes = async (data) => {
    return httpWrapper.get('/routes/', contentTypeApplicationJson, data);
};

export const getRoute = async (id) => {
    return httpWrapper.get('/routes/' + id, contentTypeApplicationJson);
};

export const addRoute = async (route) => {
    return httpWrapper.post('/routes/' , route, contentTypeApplicationJson);
};

export const editRoute = async (id, route) => {
    return httpWrapper.update('/routes/' + id + '/' , route, contentTypeApplicationJson);
};

export const deleteRoute = async (id) => {
    return httpWrapper.delete('/routes/' + id + '/' , contentTypeApplicationJson);
};

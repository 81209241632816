import {
    contentTypeApplicationJson,
} from "../constants/httpConstants";

import {httpWrapper} from "../helper/Http";

export const getSettings = async (data) => {
    return httpWrapper.get('/settings/', contentTypeApplicationJson, data);
};

export const getSetting = async (id) => {
    return httpWrapper.get('/settings/' + id, contentTypeApplicationJson);
};

export const addSetting = async (setting) => {
    return httpWrapper.post('/settings/' , setting, contentTypeApplicationJson);
};

export const editSetting = async (id, setting) => {
    return httpWrapper.update('/settings/' + id + '/' , setting, contentTypeApplicationJson);
};

export const deleteSetting = async (id) => {
    return httpWrapper.delete('/settings/' + id + '/' , contentTypeApplicationJson);
};
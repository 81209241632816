import React from 'react';

import SiteWrapper from "../../SiteWrapper";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import useStyles from "../../styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import BreadCrumb from "../../components/BreadCrumb";

function ReportPage() {

    const classes = useStyles();

    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[]}/>
            <Grid container className={classes.marginTop}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography className={classes.medium}>
                            Reports
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Grid container className={classes.marginTop} spacing={2} >
                <Grid item xs={12} sm={3}>
                    <Card variant="outlined">
                        <CardHeader
                            className={classes.reportCardHeader}
                            title="General"
                            avatar={<AssessmentIcon />}
                        />
                        <CardContent className={classes.reportCardContent}>
                            <ListItem className={classes.reportLinks}><Link to="query-reports/master-report" className={classes.unstyledLink}>Master Report</Link></ListItem>
                            <ListItem className={classes.reportLinks}><Link to="query-reports/expenses-summary" className={classes.unstyledLink}>Expenses Summary</Link></ListItem>
                            <ListItem className={classes.reportLinks}><Link to="query-reports/service-history" className={classes.unstyledLink}>Service History</Link></ListItem>
                            <ListItem className={classes.reportLinks}><Link to="query-reports/trips" className={classes.unstyledLink}>Trips History</Link></ListItem>
                        </CardContent>

                    </Card>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <Card variant="outlined">
                        <CardHeader
                            className={classes.reportCardHeader}
                            title="Inventory"
                            avatar={<AssessmentIcon />}
                        />
                        <CardContent className={classes.reportCardContent}>
                            <ListItem className={classes.reportLinks}><Link to="query-reports/stock-items" className={classes.unstyledLink}>Stock items</Link></ListItem>
                            <ListItem className={classes.reportLinks}><Link to="query-reports/stock-balance" className={classes.unstyledLink}>Stock Balance</Link></ListItem>
                            <ListItem className={classes.reportLinks}><Link to="query-reports/stock-ledger" className={classes.unstyledLink}>Stock Ledger</Link></ListItem>
                            <ListItem className={classes.reportLinks}><Link to="query-reports/purchases-ledger" className={classes.unstyledLink}>Purchases Ledger</Link></ListItem>
                        </CardContent>

                    </Card>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <Card variant="outlined">
                        <CardHeader
                            className={classes.reportCardHeader}
                            title="Sales"
                            avatar={<AssessmentIcon />}
                        />
                        <CardContent className={classes.reportCardContent}>
                            <ListItem className={classes.reportLinks}><Link to="query-reports/transport-sales" className={classes.unstyledLink}>Transport Sales</Link></ListItem>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SiteWrapper>
    );
}

export default ReportPage;

import { push } from 'connected-react-router';

import {
    getEmployees,
    addEmployee,
    editEmployee,
    deleteEmployee
} from "../../services/EmployeeService";


import {employeeConstants} from "../ActionTypes";

import {alertActions} from "./AlertAction";

export const getEmployeesAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: employeeConstants.PENDING_EMPLOYEE_REQUEST
        });
        return getEmployees(data).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: employeeConstants.GET_EMPLOYEES_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: employeeConstants.GET_EMPLOYEES_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};


export const addEmployeeAction = (employee, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: employeeConstants.PENDING_EMPLOYEE_REQUEST
        });
        return addEmployee(employee).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: employeeConstants.ADD_EMPLOYEE_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/employees'));
                    dispatch(alertActions.success('Employee added successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: employeeConstants.ADD_EMPLOYEE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};


export const editEmployeeAction = (id, employee, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: employeeConstants.PENDING_EMPLOYEE_REQUEST
        });
        return editEmployee(id, employee).then(
            (response) => {
                if (response.data) {

                    dispatch({
                        type: employeeConstants.EDIT_EMPLOYEE_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/employees'));
                    dispatch(alertActions.success('Employee edited successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: employeeConstants.EDIT_EMPLOYEE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};

export const deleteEmployeeAction = (id) => {
    return (dispatch) => {
        dispatch({
            type: employeeConstants.PENDING_EMPLOYEE_REQUEST
        });
        return deleteEmployee(id).then(
            (response) => {
                if (response) {
                    dispatch({
                        type: employeeConstants.DELETE_EMPLOYEE_SUCCESS,
                        payload: id
                    });
                    dispatch(push('/employees'));
                    dispatch(alertActions.success('Employee deleted successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: employeeConstants.DELETE_EMPLOYEE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};
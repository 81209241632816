import React from 'react';
import MainWrapper from "../SiteWrapper";
import CountStats from "./CountStats";
import ExpensesChart from "./ExpensesChart";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import useStyles from "../styles";

export default function Dashboard() {

    const classes = useStyles();

    return (
        <>
            <MainWrapper>
                <CountStats/>
                    <Grid item xs={12} md={12} lg={12} >
                        <Box className={classes.expenseChartContainer}>
                            <ExpensesChart/>
                        </Box>
                    </Grid>
            </MainWrapper>
        </>
    );
};
import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import SiteWrapper from "../../SiteWrapper";
import {useDispatch, useSelector} from "react-redux";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import useStyles from "../../styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import BreadCrumb from "../../components/BreadCrumb";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {addConfigurationAction, editConfigurationAction} from "../../store/actions/ConfigurationAction";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

function AddEditConfigurationPage({history, match}) {

    const classes = useStyles();

    const {id} = match.params;

    const isAddMode = !id;

    const dispatch = useDispatch();

    const configurations = useSelector(state => state.configuration.configurations);
    const configuration = configurations.find(configuration => configuration.id === parseInt(id));


    const formik = useFormik({

        initialValues: {
            name: configuration ? configuration.name : '',
            description: configuration ? configuration.description : '',
            can_edit: configuration ? configuration.can_edit : false,
            can_be_assigned: configuration ? configuration.can_be_assigned : false,
            type: configuration && configuration.type ? configuration.type === 'System' ? '1' : '2' : '',
            status: configuration ? configuration.status : true,
        },

        validationSchema: Yup.object().shape({
            name: Yup.string().required('Name is required'),
            description: Yup.string().required('Description is required'),
            type: Yup.string().required('Type is required'),
        }),

        onSubmit(values) {

            isAddMode ? dispatch(addConfigurationAction(values, errorCallback)) : dispatch(editConfigurationAction(id, values, errorCallback));
        },

        enableReinitialize: true

    });

    const errorCallback = (error) => {
        formik.setSubmitting(false);
    };

    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[{
                'name': 'Configurations',
                'link': '/configurations'
            }]}/>

            <Grid container className={classes.marginTop}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography className={clsx(classes.pageTitle)}>
                            New Configuration
                        </Typography>
                    </Box>
                </Grid>
            </Grid>


            <Paper className={classes.paper}>
                <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                name="name"
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                value={formik.values.name || ''} onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="description"
                                variant="outlined"
                                required
                                fullWidth
                                id="description"
                                label="Description"
                                value={formik.values.description || ''} onChange={formik.handleChange}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            />
                        </Grid>


                        <Grid item xs={4} sm={2} md={3}>
                            <FormControl className={classes.formControl}>
                                <InputLabel>Type</InputLabel>
                                <Select
                                    name='type'
                                    onChange={formik.handleChange}
                                    value={formik.values.type || ''}
                                    error={formik.touched.type && Boolean(formik.errors.type)}
                                    helperText={formik.touched.type && formik.errors.type}
                                >
                                    <MenuItem value="" key="0">Select Type</MenuItem>
                                    <MenuItem value="1" key="1">System</MenuItem>
                                    <MenuItem value="2" key="2">User</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} sm={3}>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.can_edit}
                                        onChange={event => formik.setFieldValue('can_edit', event.target.checked)}
                                        color="primary"
                                        name="Can Edit"
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                }
                                label="Can Edit"
                            />
                        </Grid>

                        <Grid item xs={6} sm={3}>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.can_be_assigned}
                                        onChange={event => formik.setFieldValue('can_be_assigned', event.target.checked)}
                                        color="primary"
                                        name="Can be Assigned"
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                }
                                label="Can be Assigned"
                            />
                        </Grid>

                        <Grid item xs={6} sm={3}>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.status}
                                        onChange={event => formik.setFieldValue('status', event.target.checked)}
                                        color="primary"
                                        name="Active"
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                }
                                label="Active"
                            />
                        </Grid>

                        <Grid container justify="flex-end">

                            <Grid item xs={6} sm={1}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="default"
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                > Cancel </Button>

                            </Grid>

                            <Grid item xs={6} sm={1}>
                                <Button
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.success}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </form>
            </Paper>
        </SiteWrapper>
    );
}

export default AddEditConfigurationPage;

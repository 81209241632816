import {serviceConstants} from "../ActionTypes";

const initialState = {
    services: [],
    count: 0,
    service: {},
    isLoading: false
};

function ServiceReducer(state = initialState, action) {

    switch (action.type) {
        case serviceConstants.PENDING_SERVICE_REQUEST:
            return {...state, isLoading: true};
        case serviceConstants.GET_SERVICES_SUCCESS:
            return {...state, services: action.payload.results, count: action.payload.count, isLoading: false};
        case serviceConstants.GET_SERVICES_ERROR:
            return {...state, isLoading: false};
        case serviceConstants.GET_SERVICE_SUCCESS:
            return {...state, service: action.payload, isLoading: false};
        case serviceConstants.GET_SERVICE_ERROR:
            return {...state, isLoading: false};
        case serviceConstants.ADD_SERVICE_SUCCESS:
            return {...state, services: state.services.concat(action.payload), isLoading: false};
        case serviceConstants.ADD_SERVICE_ERROR:
            return {...state, isLoading: false};
        case serviceConstants.EDIT_SERVICE_SUCCESS:
            return {...state, service: action.payload, isLoading: false};
        case serviceConstants.EDIT_SERVICE_ERROR:
            return {...state, isLoading: false};
        case serviceConstants.DELETE_SERVICE_SUCCESS:
            return {
                ...state,
                services: state.services.filter((service) => service.id !== action.payload),
                count: state.count - 1,
                isLoading: false
            };
        case serviceConstants.DELETE_SERVICE_ERROR:
            return {...state.services, isLoading: false};
        default:
            return state;
    }
}

export default ServiceReducer;
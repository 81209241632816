import React, {useEffect, useState} from 'react';
import {Line} from 'react-chartjs-2';
import {utils} from "../helper/Utils";
import {httpService} from "../services/HttpService";

export default function ExpensesChart() {

    const [monthlyExpenses, setMonthlyExpenses] = useState([]);

    useEffect(() => {

        httpService.get('/reports/monthly-expenses/').then(
            (response) => {
                if (response.data) {
                    setMonthlyExpenses(response.data.results)
                }
            },
            (error) => {

            }
        );

    }, []);

    const months = {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0
    };

    const monthlyExpensesDataRe = (d) => {

        let data = [];

        d.map((item) => (
            months[item.month] = item.total
        ));

        for (let key in months) {
            data = [...data, months[key]]
        }

        return {

            labels: utils.months({count: 12}),

            datasets: [
                {
                    label: 'Expenses',
                    fill: false,
                    backgroundColor: '#7bb5ec',
                    borderColor: '#7bb5ec',
                    data: data
                }
            ]
        };
    };

    const options = {
        scales: {
            y: {

                ticks: {
                    beginAtZero: true,
                }
            },
        },
        maintainAspectRatio: false,

    };

    return (
        <>
            {monthlyExpenses && <Line data={monthlyExpensesDataRe(monthlyExpenses)} options={options}/>}
        </>
    );
};
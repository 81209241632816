import {employeeConstants} from "../ActionTypes";

const initialState = {
    employees: [],
    count: 0,
    employee: {},
    isLoading: false
};

function EmployeeReducer(state = initialState, action) {

    switch (action.type) {
        case employeeConstants.PENDING_EMPLOYEE_REQUEST:
            return {...state, isLoading: true};
        case employeeConstants.GET_EMPLOYEES_SUCCESS:
            return {...state, employees: action.payload.results, count: action.payload.count, isLoading: false};
        case employeeConstants.GET_EMPLOYEES_ERROR:
            return {...state, isLoading: false};
        case employeeConstants.ADD_EMPLOYEE_SUCCESS:
            return {...state, employees: state.employees.concat(action.payload), isLoading: false};
        case employeeConstants.ADD_EMPLOYEE_ERROR:
            return {...state, isLoading: false};
        case employeeConstants.EDIT_EMPLOYEE_SUCCESS:
            return {...state, employee: action.payload, isLoading: false};
        case employeeConstants.EDIT_EMPLOYEE_ERROR:
            return {...state, isLoading: false};
        case employeeConstants.DELETE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                employees: state.employees.filter((employee) => employee.id !== action.payload),
                count: state.count - 1,
                isLoading: false
            };
        case employeeConstants.DELETE_EMPLOYEE_ERROR:
            return {...state.employees, isLoading: false};
        default:
            return state;
    }
}

export default EmployeeReducer;
import React from 'react';
import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {Link, withRouter} from 'react-router-dom';
import useStyles from "../styles";
import PropTypes from 'prop-types'
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

const BreadCrumb = (props) => {

    const classes = useStyles();

    const {history, crumbs} = props;

    return (
        <>
            <Grid container spacing={1} className={classes.marginTop}>
                <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadCrumb}>
                    <Link to="#" onClick={() => history.goBack()} className={classes.breadCrumbLinks}>
                        <ArrowBackIcon className={classes.icon} /> Back
                    </Link>

                    {
                        crumbs && crumbs.map((crumb, ci) => {
                            return (
                                <Link
                                    key={ ci }
                                    color="inherit"
                                    to={crumb.link}
                                    className={classes.breadCrumbLinks}
                                >
                                    {crumb.name}
                                </Link>
                            );
                        })
                    }
                </Breadcrumbs>
            </Grid>
        </>
    );
};

BreadCrumb.propTypes = {
    crumbs: PropTypes.array.isRequired
};

export default withRouter(BreadCrumb);
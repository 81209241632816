import {authConstants} from '../ActionTypes';

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
    ? { isLoggedIn: true, user, message : null }
    : { isLoggedIn: false, user: null, message : null };

function AuthReducer(state = initialState, action) {

    switch (action.type) {

        case authConstants.LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: action.payload,
            };
        case authConstants.LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                message : action.payload
            };
        case authConstants.LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };

        default:
            return state;
    }
}

export default AuthReducer;
import {machineConstants} from "../ActionTypes";

const initialState = {
    machines: [],
    count: 0,
    isLoading: false
};

function MachineReducer(state = initialState, action) {

    switch (action.type) {
        case machineConstants.PENDING_MACHINE_REQUEST:
            return {...state, isLoading: true};
        case machineConstants.GET_MACHINES_SUCCESS:
            return {...state, machines: action.payload.results, count: action.payload.count, isLoading: false};
        case machineConstants.GET_MACHINES_ERROR:
            return {...state, isLoading: false};
        case machineConstants.ADD_MACHINE_SUCCESS:
            return {...state, machines: state.machines.concat(action.payload), isLoading: false};
        case machineConstants.ADD_MACHINE_ERROR:
            return {...state, isLoading: false};
        case machineConstants.EDIT_MACHINE_SUCCESS:
            return {...state, machine: action.payload, isLoading: false};
        case machineConstants.EDIT_MACHINE_ERROR:
            return {...state, isLoading: false};
        case machineConstants.DELETE_MACHINE_SUCCESS:
            return {
                ...state,
                machines: state.machines.filter((machine) => machine.id !== action.payload),
                count: state.count - 1,
                isLoading: false
            };
        case machineConstants.DELETE_MACHINE_ERROR:
            return {...state.machines, isLoading: false};
        default:
            return state;
    }
}

export default MachineReducer;
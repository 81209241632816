import React, {useEffect, useRef} from 'react';
import Grid from "@material-ui/core/Grid";
import BreadCrumb from "../../components/BreadCrumb";


import {Link} from 'react-router-dom';
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import Box from "@material-ui/core/Box";
import useStyles from "../../styles";
import {useDispatch, useSelector} from "react-redux";
import EnhancedTable from "../../components/EnhancedTable";
import SiteWrapper from "../../SiteWrapper";
import {deleteConfigurationAction, getConfigurationsAction} from "../../store/actions/ConfigurationAction";
import StatusComponent from "../../components/StatusComponent";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import MenuActionComponent from "../../components/MenuActionComponent";
import {utils} from "../../helper/Utils";


const ConfigurationsPage = () => {

    const classes = useStyles();

    const dispatch = useDispatch();
    const configurations = useSelector(state => state.configuration.configurations);

    const count = useSelector(state => state.configuration.count);

    const fetchIdRef = useRef(0);

    const fetchData = React.useCallback((pager, query) => {

        let search = Object.assign({}, {limit: pager.pageSize, offset: pager.pageIndex * pager.pageSize}, query);

        const fetchId = ++fetchIdRef.current;

        if (fetchId === fetchIdRef.current) {
            dispatch(getConfigurationsAction(search));
        }

    }, [dispatch]);


    useEffect(() => {
        fetchData({pageIndex: utils.DEFAULT_PAGE_INDEX, pageSize: utils.DEFAULT_PAGE_SIZE});
    }, [fetchData]);

    const columns = [

        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Description",
            accessor: d => (d.description ?? '-')
        },
        {
            Header: "Assigned",
            accessor: d => (d.can_be_assigned ? 'Yes' : 'No')
        },
        {
            Header: "Type",
            accessor: "type"
        },
        {
            Header: "Status",
            accessor: d => <StatusComponent status={d.status}/>
        },
        {
            Header: 'Action',
            Cell: row => (<MenuActionComponent item={{id: row.row.original.id, showView: false, hideEdit: !row.row.original.can_edit}} url={"configurations"}
                                           deleteAction={deleteConfigurationAction}/>)
        }
    ];

    return (
        <>
            <SiteWrapper>
                <BreadCrumb crumbs={[{
                    'name': 'Configurations',
                    'link': '#'
                }]}/>
                <Grid container>

                    <Grid container className={classes.marginTop}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <Typography className={clsx(classes.medium, classes.pageTitle)}>
                                    Configurations
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid container item xs={12} sm={8} justify="flex-end">
                            <Box>
                                <Button className={classes.actionButton} startIcon={<AddIcon/>}>
                                    <Link to="/configurations/create" className={classes.unstyledLink}>New Config</Link>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                    {/* Recent Deposits */}
                    <Grid item xs={12} md={12} lg={12}>
                            {configurations && <EnhancedTable
                                columns={columns}
                                data={configurations}
                                fetchData={fetchData}
                                pageCount={count && count}
                            />}
                    </Grid>

                </Grid>
            </SiteWrapper>
        </>
    );
};

export default ConfigurationsPage;
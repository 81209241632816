import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import SiteWrapper from "../../SiteWrapper";
import {useDispatch, useSelector} from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import dayjs from "dayjs";
import useStyles from "../../styles";
import {getMachines} from "../../services/MachineService";
import {alertActions} from "../../store/actions/AlertAction";
import {addTripAction, editTripAction} from "../../store/actions/TripAction";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import {getEmployeesAction} from "../../store/actions/EmployeeAction";
import {utils} from "../../helper/Utils";
import {getRoutesAction} from "../../store/actions/RouteAction";
import {getSuppliersAction} from "../../store/actions/SupplierAction";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";


function AddEditTripPage({match}) {

    const classes = useStyles();

    const {id} = match.params;

    const isAddMode = !id;

    const dispatch = useDispatch();

    const trips = useSelector(state => state.trip.trips);
    const trip = trips.find(trip => trip.id === parseInt(id));

    const employees = useSelector(state => state.employee.employees);
    const routes = useSelector(state => state.route.routes);
    const suppliers = useSelector(state => state.supplier.suppliers);


    const [horses, setHorses] = useState([]);
    const [trailers, setTrailers] = useState([]);

    useEffect(() => {

        getMachines({type: 'horse'}).then(
            (response) => {
                if (response.data) {
                    setHorses(response.data.results)
                }
            },
            (error) => {
                if (error.response) {
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );

        getMachines({type: 'trailer'}).then(
            (response) => {
                if (response.data) {
                    setTrailers(response.data.results)
                }
            },
            (error) => {
                if (error.response) {
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );

        dispatch(getEmployeesAction());
        dispatch(getRoutesAction());
        dispatch(getSuppliersAction());

    }, [dispatch]);

    const formik = useFormik({

        initialValues: {
            departure: trip ? trip.departure : dayjs(new Date()).format("YYYY-MM-DD"),
            mileage: trip ? trip.mileage : '',
            distance: trip ? trip.distance : '',
            horse: trip ? trip.horse : null,
            trailer: trip ? trip.trailer : null,
            serviced: trip ? trip.serviced : true,
            driver: trip ? trip.driver : null,
            co_driver: trip ? trip.co_driver : null,
            route: trip ? trip.route : '',
            fuel: trip && trip.fuel ? trip.fuel : 0.00,
            fuel_price: trip && trip.fuel_price ? trip.fuel_price : 0.00,
            fuel_supplier: trip ? trip.fuel_supplier : '',
            status: 0
        },

        validationSchema: Yup.object().shape({
            departure: Yup.date("YYYY-mm-dd").required('Departure date is required'),
            mileage: Yup.number().required('Mileage is required'),
            horse: Yup.string().required('Horse model is required'),
            driver: Yup.string().required('Driver is required'),
            route: Yup.string().required('Route is required'),
            fuel: Yup.number().min(0, 'Fuel must be greater than zero').required('Fuel is required'),
            fuel_price: Yup.number().min(0, 'Fuel Price must be greater than zero').required('Fuel Price is required'),
            fuel_supplier: Yup.string().when("fuel", {
                is: fuel => fuel > 0,
                then: Yup.string().required("Fuel supplier is required")
            }),
        }),

        onSubmit(values) {
            isAddMode ? dispatch(addTripAction(values, errorCallback)) : dispatch(editTripAction(id, values, errorCallback));
        },

        enableReinitialize: true

    });

    const errorCallback = (error) => {
        formik.setSubmitting(false);
    };

    const onSelectRoute = (value, setFieldValue) => {

        if (null !== value) {

            setFieldValue('route', value.url);
            setFieldValue('mileage', value.mileage);
            setFieldValue('distance', value.distance);
        }
    };

    const handleInputChange = (event, name, value, setFieldValue) => {

        // Check if event is null to prevent multiple value set
        // If on change occurred, the event will be click

        if (null === event && value.url) {

            setFieldValue(name, value.url);
        }

    };

    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[{
                'name': 'Trips',
                'link': '/trips'
            }]}/>


            <Grid container className={classes.marginTop}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography className={clsx(classes.pageTitle)}>
                            New Trip
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Paper className={classes.paper}>
                <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <MuiPickersUtilsProvider utils={DayjsUtils}>
                                <KeyboardDatePicker
                                    id="date-picker-dialog"
                                    label="Departure Date"
                                    format="MM/DD/YYYY"
                                    maxDate={utils.maxDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    value={formik.values.departure}
                                    onChange={val => formik.setFieldValue('departure', dayjs(val).format("YYYY-MM-DD"))}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {/*<FormControl className={classes.formControlMin}>*/}
                            {/*    <InputLabel>Horse</InputLabel>*/}
                            {/*    <Select*/}
                            {/*        name='horse'*/}
                            {/*        onChange={formik.handleChange}*/}
                            {/*        value={formik.values.horse}*/}
                            {/*        error={formik.errors.horse && Boolean(formik.touched.horse)}*/}
                            {/*        helperText={formik.errors.horse && formik.touched.horse}*/}
                            {/*    >*/}
                            {/*        {horses && horses.map((m, idx) => <MenuItem*/}
                            {/*            value={m.url}*/}
                            {/*            key={idx}>{m.reg_no}</MenuItem>)}*/}
                            {/*    </Select>*/}
                            {/*</FormControl>*/}

                            <Autocomplete
                                filterOptions={utils.autocompleteFilterOptions}
                                className={classes.formControlMin}
                                disablePortal
                                options={horses}
                                getOptionLabel={option => option.reg_no}
                                name='horse'
                                defaultValue={formik.values.horse || null}
                                getOptionSelected={(option, value) => option.url === value.url}
                                onInputChange={(event) => {
                                    handleInputChange(event, 'horse', formik.values.horse, formik.setFieldValue);
                                }}
                                onChange={(_, value) => value != null ? formik.setFieldValue('horse', value.url) : ''}
                                renderInput={(params) => <TextField {...params}
                                                                    error={formik.errors.horse && Boolean(formik.touched.horse)}
                                                                    helperText={formik.errors.horse && formik.touched.horse}
                                                                    name='horse'
                                                                    label="Horse"/>}
                            />
                        </Grid>

                        <Grid item xs={4} sm={2}>
                            {/*<FormControl className={classes.formControlMin}>*/}
                            {/*    <InputLabel>Trailer</InputLabel>*/}
                            {/*    <Select*/}
                            {/*        name='trailer'*/}
                            {/*        onChange={formik.handleChange}*/}
                            {/*        value={formik.values.trailer}*/}
                            {/*        error={formik.errors.trailer && Boolean(formik.touched.trailer)}*/}
                            {/*        helperText={formik.errors.trailer && formik.touched.trailer}*/}
                            {/*    >*/}
                            {/*        <MenuItem value="">Select Value</MenuItem>*/}
                            {/*        {trailers && trailers.map((m, idx) => <MenuItem*/}
                            {/*            value={m.url}*/}
                            {/*            key={idx}>{m.reg_no}</MenuItem>)}*/}
                            {/*    </Select>*/}
                            {/*</FormControl>*/}
                            <Autocomplete
                                filterOptions={utils.autocompleteFilterOptions}
                                className={classes.formControlMin}
                                disablePortal
                                options={trailers}
                                getOptionLabel={option => option.reg_no}
                                name='trailer'
                                defaultValue={formik.values.trailer || null}
                                getOptionSelected={(option, value) => option.url === value.url}
                                onInputChange={(event, value) => {
                                    handleInputChange(event, 'trailer', formik.values.trailer, formik.setFieldValue);
                                }}
                                onChange={(_, value) => value != null ? formik.setFieldValue('trailer', value.url) : ''}
                                renderInput={(params) => <TextField {...params}
                                                                    error={formik.errors.trailer && Boolean(formik.touched.trailer)}
                                                                    helperText={formik.errors.trailer && formik.touched.trailer}
                                                                    name={'trailer'}
                                                                    label="Trailer"/>}

                            />
                        </Grid>

                        <Grid item xs={4} sm={5}>
                            {/*<FormControl className={classes.formControlMin}>*/}
                            {/*    <InputLabel>Route</InputLabel>*/}
                            {/*    <Select*/}
                            {/*        name='route'*/}
                            {/*        value={formik.values.route}*/}
                            {/*        error={formik.errors.route && Boolean(formik.touched.route)}*/}
                            {/*        helperText={formik.errors.route && formik.touched.route}*/}
                            {/*        onChange={(e) => {*/}
                            {/*            formik.handleChange(e);*/}
                            {/*            onSelectRoute(e);*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        {routes && routes.map((m, idx) => <MenuItem*/}
                            {/*            value={m.url} data-mileage={m.mileage} data-distance={m.distance}*/}
                            {/*            key={idx}>{m.name}</MenuItem>)}*/}
                            {/*    </Select>*/}
                            {/*</FormControl>*/}

                            <Autocomplete
                                filterOptions={utils.autocompleteFilterOptions}
                                className={classes.formControl}
                                disablePortal
                                options={routes}
                                getOptionLabel={option => option.name}
                                name='route'
                                defaultValue={formik.values.route || null}
                                getOptionSelected={(option, value) => option.url === value.url}
                                onInputChange={(event, value) => {
                                    handleInputChange(event, 'route', formik.values.route, formik.setFieldValue);
                                }}
                                onChange={(_, value) => onSelectRoute(value, formik.setFieldValue)}
                                renderInput={(params) => <TextField {...params}
                                                                    error={formik.errors.route && Boolean(formik.touched.route)}
                                                                    helperText={formik.errors.route && formik.touched.route}
                                                                    name={'route'}
                                                                    label="Route"/>}
                                classes={{paper: classes.autocomplete}}

                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            {/*<FormControl className={classes.formControlMin}>*/}
                            {/*    <InputLabel>Driver</InputLabel>*/}
                            {/*    <Select*/}
                            {/*        name='driver'*/}
                            {/*        onChange={formik.handleChange}*/}
                            {/*        value={formik.values.driver}*/}
                            {/*        error={formik.errors.driver && Boolean(formik.touched.driver)}*/}
                            {/*        helperText={formik.errors.driver && formik.touched.driver}*/}
                            {/*    >*/}
                            {/*        {employees && employees.map((m, idx) => <MenuItem*/}
                            {/*            value={m.url}*/}
                            {/*            key={idx}>{m.full_name}</MenuItem>)}*/}
                            {/*    </Select>*/}
                            {/*</FormControl>*/}

                            <Autocomplete
                                filterOptions={utils.autocompleteFilterOptions}
                                className={classes.formControlMin}
                                disablePortal
                                options={employees}
                                getOptionLabel={option => option.full_name}
                                name='driver'
                                defaultValue={formik.values.driver || null}
                                getOptionSelected={(option, value) => option.url === value.url}
                                onInputChange={(event, value) => {
                                    handleInputChange(event, 'driver', formik.values.driver, formik.setFieldValue);
                                }}
                                onChange={(_, value) => value != null ? formik.setFieldValue('driver', value.url) : ''}
                                renderInput={(params) => <TextField {...params}
                                                                    error={formik.errors.driver && Boolean(formik.touched.driver)}
                                                                    helperText={formik.errors.driver && formik.touched.driver}
                                                                    name={'driver'}
                                                                    label="Driver"/>}
                                classes={{paper: classes.autocomplete}}
                            />
                        </Grid>

                        <Grid item xs={4} sm={3}>
                            {/*<FormControl className={classes.formControlMin}>*/}
                            {/*    <InputLabel>Co-Driver</InputLabel>*/}
                            {/*    <Select*/}
                            {/*        name='co_driver'*/}
                            {/*        onChange={formik.handleChange}*/}
                            {/*        value={formik.values.co_driver}*/}
                            {/*        error={formik.errors.co_driver && Boolean(formik.touched.co_driver)}*/}
                            {/*        helperText={formik.errors.co_driver && formik.touched.co_driver}*/}
                            {/*    >*/}
                            {/*        {employees && employees.map((m, idx) => <MenuItem*/}
                            {/*            value={m.url}*/}
                            {/*            key={idx}>{m.full_name}</MenuItem>)}*/}
                            {/*    </Select>*/}
                            {/*</FormControl>*/}

                            <Autocomplete
                                filterOptions={utils.autocompleteFilterOptions}
                                className={classes.formControlMin}
                                disablePortal
                                options={employees}
                                getOptionLabel={option => option.full_name}
                                name='driver'
                                defaultValue={formik.values.co_driver || null}
                                getOptionSelected={(option, value) => option.url === value.url}
                                onInputChange={(event, value) => {
                                    handleInputChange(event, 'co_driver', formik.values.co_driver, formik.setFieldValue);
                                }}
                                onChange={(_, value) => value != null ? formik.setFieldValue('co_driver', value.url) : ''}
                                renderInput={(params) => <TextField {...params}
                                                                    error={formik.errors.co_driver && Boolean(formik.touched.co_driver)}
                                                                    helperText={formik.errors.co_driver && formik.touched.co_driver}
                                                                    name={'co_driver'}
                                                                    label="Co Driver"/>}
                                classes={{paper: classes.autocomplete}}
                            />
                        </Grid>

                        <Grid item xs={3} sm={3}>
                            <TextField
                                name='mileage'
                                label='Mileage'
                                fullWidth
                                onChange={formik.handleChange}
                                value={formik.values.mileage}
                                error={formik.errors.mileage && Boolean(formik.touched.mileage)}
                                helperText={formik.errors.mileage && formik.touched.mileage}
                            />
                        </Grid>

                        <Grid item xs={3} sm={3}>
                            <TextField
                                name='distance'
                                label='Distance'
                                fullWidth
                                onChange={formik.handleChange}
                                value={formik.values.distance}
                                error={formik.errors.distance && Boolean(formik.touched.distance)}
                                helperText={formik.errors.distance && formik.touched.distance}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>

                        <Grid item xs={3} sm={3}>
                            <TextField
                                name='fuel'
                                label='Fuel'
                                fullWidth
                                onChange={formik.handleChange}
                                value={formik.values.fuel}
                                error={formik.errors.fuel && Boolean(formik.touched.fuel)}
                                helperText={formik.errors.fuel && formik.touched.fuel}
                            />
                        </Grid>

                        <Grid item xs={3} sm={3}>
                            <TextField
                                name='fuel_price'
                                label='Fuel Price (Per Lts & VAT Incl.)'
                                fullWidth
                                onChange={formik.handleChange}
                                value={formik.values.fuel_price}
                                error={formik.errors.fuel_price && Boolean(formik.touched.fuel_price)}
                                helperText={formik.errors.fuel_price && formik.touched.fuel_price}
                            />
                        </Grid>

                        <Grid item xs={4} sm={2}>
                            {/*<FormControl className={classes.formControlMin}>*/}
                            {/*    <InputLabel>Fuel Supplier</InputLabel>*/}
                            {/*    <Select*/}
                            {/*        name='fuel_supplier'*/}
                            {/*        onChange={formik.handleChange}*/}
                            {/*        value={formik.values.fuel_supplier}*/}
                            {/*        error={formik.errors.fuel_supplier && Boolean(formik.touched.fuel_supplier)}*/}
                            {/*        helperText={formik.errors.fuel_supplier && formik.touched.fuel_supplier}*/}
                            {/*    >*/}
                            {/*        {suppliers && suppliers.map((m, idx) => <MenuItem*/}
                            {/*            value={m.url}*/}
                            {/*            key={idx}>{m.name}</MenuItem>)}*/}
                            {/*    </Select>*/}
                            {/*</FormControl>*/}

                            <Autocomplete
                                filterOptions={utils.autocompleteFilterOptions}
                                className={classes.formControl}
                                disablePortal
                                options={suppliers}
                                getOptionLabel={option => option.name}
                                name='fuel_supplier'
                                defaultValue={formik.values.fuel_supplier || null}
                                getOptionSelected={(option, value) => option.url === value.url}
                                onInputChange={(event, value) => {
                                    handleInputChange(event, 'fuel_supplier', formik.values.fuel_supplier, formik.setFieldValue);
                                }}
                                onChange={(_, value) => value != null ? formik.setFieldValue('fuel_supplier', value.url) : ''}
                                renderInput={(params) => <TextField {...params}
                                                                    error={formik.errors.fuel_supplier && Boolean(formik.touched.fuel_supplier)}
                                                                    helperText={formik.errors.fuel_supplier && formik.touched.fuel_supplier}
                                                                    name={'fuel_supplier'}
                                                                    label="Fuel Supplier"/>}
                                classes={{paper: classes.autocomplete}}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.serviced}
                                        onChange={event => formik.setFieldValue('round_trip', event.target.checked)}
                                        color="primary"
                                        name="Serviced"
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                }
                                label="Serviced"
                            />
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-end" className={classes.marginTop}>
                        <Grid item xs={6} sm={2}>
                            <Button
                                type="submit"
                                size="small"
                                variant="contained"
                                className={classes.success}
                                disabled={formik.isSubmitting}
                                onClick={() => formik.setFieldValue('status', 1)}
                            >
                                Submit for approval
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={1}>
                            <Button
                                type="submit"
                                size="small"
                                variant="contained"
                                color="default"
                                className={classes.primary}
                                onClick={() => formik.setFieldValue('status', 0)}
                                disabled={formik.isSubmitting}
                            > Save </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </SiteWrapper>
    );
}

export default AddEditTripPage;

import React, {useEffect, useRef} from 'react';
import Grid from "@material-ui/core/Grid";
import BreadCrumb from "../../components/BreadCrumb";


import {Link, withRouter} from 'react-router-dom';
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import Box from "@material-ui/core/Box";
import useStyles from "../../styles";
import {useDispatch, useSelector} from "react-redux";
import {deleteSupplierAction, getSuppliersAction} from "../../store/actions/SupplierAction";
import EnhancedTable from "../../components/EnhancedTable";

import StatusComponent from "../../components/StatusComponent";
import SiteWrapper from "../../SiteWrapper";
import Typography from "@material-ui/core/Typography";
import MenuActionComponent from "../../components/MenuActionComponent";
import {utils} from "../../helper/Utils";


const SuppliersPage = () => {

    const classes = useStyles();

    const dispatch = useDispatch();
    const suppliers = useSelector(state => state.supplier.suppliers);
    const count = useSelector(state => state.supplier.count);

    const fetchIdRef = useRef(0);

    const fetchData = React.useCallback(({pageIndex, pageSize}) => {

        const fetchId = ++fetchIdRef.current;

        if (fetchId === fetchIdRef.current) {
            dispatch(getSuppliersAction({limit: pageSize, offset: pageIndex * pageSize}));
        }

    }, [dispatch]);


    useEffect(() => {
        fetchData({pageIndex: utils.DEFAULT_PAGE_INDEX,  pageSize: utils.DEFAULT_PAGE_SIZE});
    }, [fetchData]);


    const columns = [

        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Address",
            accessor: "address"
        },
        {
            Header: "Tel",
            accessor: "tel"
        },
        {
            Header: "Status",
            accessor: d => <StatusComponent status={d.status}/>
        },
        {
            Header: 'Action',
            Cell: row => (<MenuActionComponent item={{id: row.row.original.id, showView: true}}
                                               url={"suppliers"} deleteAction={deleteSupplierAction}
            />)
        }
    ];

    return (
        <>
            <SiteWrapper>
                <BreadCrumb crumbs={[{
                    'name': 'Suppliers',
                    'link': '#'
                }]}/>
                <Grid container>
                    <Grid container className={classes.marginTop}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <Typography className={clsx(classes.medium, classes.pageTitle)}>
                                    Suppliers
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid container item xs={12} sm={8} justify="flex-end">
                            <Box>
                                <Button className={classes.actionButton} startIcon={<AddIcon/>}>
                                    <Link to="/suppliers/create" className={classes.unstyledLink}>New Supplier</Link>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    {/* Recent Deposits */}
                    <Grid item xs={12} md={12} lg={12}>
                        {suppliers && <EnhancedTable
                            columns={columns}
                            data={suppliers}
                            fetchData={fetchData}
                            pageCount={count && count}
                        />}
                    </Grid>
                </Grid>
            </SiteWrapper>
        </>
    );
};

export default withRouter(SuppliersPage);
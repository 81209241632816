import React, {useEffect, useRef} from 'react';
import Grid from "@material-ui/core/Grid";


import {withRouter} from 'react-router-dom';
import Box from "@material-ui/core/Box";
import useStyles from "../../styles";
import {useDispatch, useSelector} from "react-redux";
import EnhancedTable from "../../components/EnhancedTable";
import SiteWrapper from "../../SiteWrapper";
import Typography from "@material-ui/core/Typography";
import {useFormik} from "formik";
import dayjs from "dayjs"
import * as Yup from "yup";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import {Link} from "react-router-dom";
import {getWorkflowTransactionsAction} from "../../store/actions/WorkflowAction";
import {utils} from "../../helper/Utils";
import BreadCrumb from "../../components/BreadCrumb";


const PendingApprovalsPage = () => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const transactions = useSelector(state => state.workflow.transactions);

    const count = useSelector(state => state.workflow.transactionsCount);

    const fetchIdRef = useRef(0);

    const cb = () => {
        formik.setSubmitting(false);
    };


    const fetchData = React.useCallback((pager, query, cb) => {

        let search = Object.assign({}, {limit: pager.pageSize, offset: pager.pageIndex * pager.pageSize}, query);

        const fetchId = ++fetchIdRef.current;

        if (fetchId === fetchIdRef.current) {
            dispatch(getWorkflowTransactionsAction(search, cb));
        }

    }, [dispatch]);


    useEffect(() => {
        fetchData({pageIndex: utils.DEFAULT_PAGE_INDEX, pageSize: utils.DEFAULT_PAGE_SIZE}, {}, null);
    }, [fetchData]);

    const formik = useFormik({

        initialValues: {

            start_date: dayjs().subtract(400, 'day').format("YYYY-MM-DD"),
            end_date: dayjs().format("YYYY-MM-DD"),
            machine: '',
            type: '',
            product: ''
        },

        validationSchema: Yup.object().shape({
            start_date: Yup.date("YYYY-mm-dd").required('Start date is required'),
            end_date: Yup.date("YYYY-mm-dd").required('End date is required'),
        }),

        onSubmit(values) {

            fetchData({
                pageIndex: utils.DEFAULT_PAGE_INDEX,
                pageSize: utils.DEFAULT_PAGE_SIZE
            }, values, cb);

        },

        enableReinitialize: true

    });

    const viewFunction = (id) => {

        return <><Link to={`/workflow/transactions/${id}`}>View</Link></>
    };


    const columns = [
        {
            Header: "Date",
            accessor: d => (dayjs(d.created_at).format(utils.dateFormat))
        },
        {
            Header: "From",
            accessor: d => (d.initiator ? d.initiator.full_name : '-')
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "Action",
            accessor: d => (viewFunction(d.id))
        },
    ];

    return (
        <>
            <SiteWrapper>
                <BreadCrumb crumbs={[]}/>
                <Grid container className={classes.marginTop}>
                    <Grid item xs={12} md={12}>
                        <Box>
                            <Typography className={classes.medium}>
                                Pending Approvals
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container className={classes.marginTop}>

                    <form className={classes.form} noValidate>

                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={4}>
                                <MuiPickersUtilsProvider utils={DayjsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Start Date"
                                        maxDate={utils.maxDate}
                                        format="MM/DD/YYYY"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        value={formik.values.start_date}
                                        onChange={val => formik.setFieldValue('start_date', dayjs(val).format("YYYY-MM-DD"))}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>


                            <Grid item xs={12} sm={4}>
                                <MuiPickersUtilsProvider utils={DayjsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="End Date"
                                        maxDate={utils.maxDate}
                                        format="MM/DD/YYYY"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        value={formik.values.end_date}
                                        onChange={val => formik.setFieldValue('end_date', dayjs(val).format("YYYY-MM-DD"))}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                        </Grid>

                    </form>
                    <Grid container justify="center">
                        <Button
                            onClick={formik.handleSubmit}
                            type="button"
                            size="small"
                            variant="contained"
                            color="secondary"
                            className={clsx(classes.primary, classes.formsWithMui)}
                            disabled={formik.isSubmitting}
                        >
                            Filter
                        </Button>
                    </Grid>
                </Grid>

                <Grid container justify="flex-end" className={classes.marginTop}>
                    {/* Recent Deposits */}
                    <Grid item xs={12} md={12} lg={12}>

                        {transactions && <EnhancedTable
                            columns={columns}
                            data={transactions}
                            summaries={false}
                            fetchData={fetchData}
                            pageCount={count && count}
                        />}

                    </Grid>
                </Grid>
            </SiteWrapper>
        </>
    );
};

export default withRouter(PendingApprovalsPage);
import { push } from 'connected-react-router';

import {
    getProducts,
    addProduct,
    editProduct,
    deleteProduct
} from "../../services/ProductService";


import {productConstants} from "../ActionTypes";

import {alertActions} from "./AlertAction";

export const getProductsAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: productConstants.PENDING_PRODUCT_REQUEST
        });
        return getProducts(data).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: productConstants.GET_PRODUCTS_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: productConstants.GET_PRODUCTS_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};



export const addProductAction = (product, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: productConstants.PENDING_PRODUCT_REQUEST
        });
        return addProduct(product).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: productConstants.ADD_PRODUCT_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/parts'));
                    dispatch(alertActions.success('Product added successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: productConstants.ADD_PRODUCT_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};


export const editProductAction = (id, product, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: productConstants.PENDING_PRODUCT_REQUEST
        });
        return editProduct(id, product).then(
            (response) => {
                if (response.data) {

                    dispatch({
                        type: productConstants.EDIT_PRODUCT_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/parts'));
                    dispatch(alertActions.success('Product edited successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: productConstants.EDIT_PRODUCT_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data))   ;
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};

export const deleteProductAction = (id) => {
    return (dispatch) => {
        dispatch({
            type: productConstants.PENDING_PRODUCT_REQUEST
        });
        return deleteProduct(id).then(
            (response) => {
                if (response) {
                    dispatch({
                        type: productConstants.DELETE_PRODUCT_SUCCESS,
                        payload: id
                    });
                    dispatch(push('/parts'));
                    dispatch(alertActions.success('Product deleted successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: productConstants.DELETE_PRODUCT_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};
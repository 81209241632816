import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";


import {Link} from 'react-router-dom';
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import Box from "@material-ui/core/Box";
import {useDispatch, useSelector} from "react-redux";
import EnhancedTable from "../../components/EnhancedTable";
import dayjs from "dayjs";
import StatusComponent from "../../components/StatusComponent";
import {deleteWorkflowRuleAction, getWorkflowRulesAction} from "../../store/actions/WorkflowAction";
import useStyles from "../../styles";
import MenuActionComponent from "../../components/MenuActionComponent";
import {utils} from "../../helper/Utils";


const RulesPage = () => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const rules = useSelector(state => state.workflow.rules);

    useEffect(() => {
        dispatch(getWorkflowRulesAction());
    }, [dispatch]);


    const columns = [
        {
            Header: "Process",
            accessor: d => (d.workflow_process.name ?? '-')
        },
        {
            Header: "Lower Limit",
            accessor: d => (d.lower_limit ?? '-')
        },
        {
            Header: "Upper Limit",
            accessor: d => (d.upper_limit ?? '-')
        },
        {
            Header: "Created By",
            accessor: d => (d.created_by ? d.created_by.full_name : '-')
        },
        {
            Header: "Created At",
            accessor: d => (dayjs(d.created_at).format(utils.dateFormat))
        },
        {
            Header: "Status",
            accessor: d => <StatusComponent status={d.status}/>
        },
        {
            Header: 'Action',
            Cell: row => (<MenuActionComponent item={{id: row.row.original.id, showView: false}} url={"workflow/rules"}
                                           deleteAction={deleteWorkflowRuleAction}/>)
        }
    ];

    return (
        <>
            <Grid container justify="flex-end">
                <Box className={classes.headingBar}>
                    <Grid container>
                        <Grid item>
                            <Button className={classes.actionButton} startIcon={<AddIcon/>}>
                                <Link to="workflow/rules/create" className={classes.unstyledLink}>New Rule</Link>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                {/* Recent Deposits */}
                <Grid item xs={12} md={12} lg={12}>
                    {rules && <EnhancedTable
                        columns={columns}
                        data={rules}
                        pageCount={rules.length}
                        component={false}
                    />}
                </Grid>
            </Grid>
        </>
    );
};

export default RulesPage;
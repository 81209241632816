import {
    contentTypeApplicationJson,
} from "../constants/httpConstants";

import {httpWrapper} from "../helper/Http";

export const getExpenses = async (data) => {
    return httpWrapper.get('/expenses/', contentTypeApplicationJson, data);
};

export const getExpense = async (id) => {
    return httpWrapper.get('/expenses/' + id, contentTypeApplicationJson);
};

export const addExpense = async (expense) => {
    return httpWrapper.post('/expenses/' , expense, contentTypeApplicationJson);
};

export const editExpense = async (id, expense) => {
    return httpWrapper.update('/expenses/' + id + '/' , expense, contentTypeApplicationJson);
};

export const deleteExpense = async (id) => {
    return httpWrapper.delete('/expenses/' + id + '/' , contentTypeApplicationJson);
};
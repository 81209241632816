import { push } from 'connected-react-router';

import {
    getUsers,
    addUser,
    editUser,
    deleteUser,
    changeUserPassword,
} from "../../services/UserService";


import {userConstants} from "../ActionTypes";

import {alertActions} from "./AlertAction";

export const getUsersAction = () => {
    return (dispatch) => {
        dispatch({
            type: userConstants.PENDING_USER_REQUEST
        });
        return getUsers().then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: userConstants.GET_USERS_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: userConstants.GET_USERS_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};


export const addUserAction = (user, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: userConstants.PENDING_USER_REQUEST
        });
        return addUser(user).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: userConstants.ADD_USER_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/users'));
                    dispatch(alertActions.success('User added successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: userConstants.ADD_USER_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};


export const editUserAction = (id, user, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: userConstants.PENDING_USER_REQUEST
        });
        return editUser(id, user).then(
            (response) => {
                if (response.data) {

                    dispatch({
                        type: userConstants.EDIT_USER_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/users/'));
                    dispatch(alertActions.success('User edited successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: userConstants.EDIT_USER_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};

export const deleteUserAction = (id) => {
    return (dispatch) => {
        dispatch({
            type: userConstants.PENDING_USER_REQUEST
        });
        return deleteUser(id).then(
            (response) => {
                if (response) {
                    dispatch({
                        type: userConstants.DELETE_USER_SUCCESS,
                        payload: id
                    });
                    dispatch(push('/users'));
                    dispatch(alertActions.success('User deleted successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: userConstants.DELETE_USER_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};


export const changeUserPasswordAction = (id, user) => {
    return (dispatch) => {
        dispatch({
            type: userConstants.PENDING_USER_REQUEST
        });
        return changeUserPassword(id, user).then(
            (response) => {
                if (response.data) {

                    dispatch({
                        type: userConstants.CHANGE_USER_PASSWORD_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/users/'));
                    dispatch(alertActions.success('User Password updated successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: userConstants.CHANGE_USER_PASSWORD_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};
import {
    contentTypeApplicationJson,
} from "../constants/httpConstants";

import {httpWrapper} from "../helper/Http";

export const getMachines = async (data) => {
    return httpWrapper.get('/machines/', contentTypeApplicationJson, data);
};

export const getMachine = async (supplier) => {
    return httpWrapper.get('/machines/' + supplier, contentTypeApplicationJson);
};

export const addMachine = async (machine) => {
    return httpWrapper.post('/machines/' , machine, contentTypeApplicationJson);
};

export const editMachine = async (id, machines) => {
    return httpWrapper.update('/machines/' + id + '/' , machines, contentTypeApplicationJson);
};

export const deleteMachine = async (id) => {
    return httpWrapper.delete('/machines/' + id + '/' , contentTypeApplicationJson);
};

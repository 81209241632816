import {
    contentTypeApplicationJson,
} from "../constants/httpConstants";

import {httpWrapper} from "../helper/Http";

const get = (url, data) => {
    return httpWrapper.get(url, contentTypeApplicationJson, data);
};


const post =  (url, data) => {
    return httpWrapper.post(url, data, contentTypeApplicationJson);
};

export const httpService = {
    get, post
};
import React, {useEffect, useRef, useState} from 'react';
import Grid from "@material-ui/core/Grid";


import {withRouter} from 'react-router-dom';
import Box from "@material-ui/core/Box";
import useStyles from "../../styles";
import {useDispatch, useSelector} from "react-redux";
import EnhancedTable from "../../components/EnhancedTable";
import SiteWrapper from "../../SiteWrapper";
import Typography from "@material-ui/core/Typography";
import {httpWrapper} from "../../helper/Http";
import {contentTypeApplicationJson} from "../../constants/httpConstants";
import {utils} from "../../helper/Utils";
import {useFormik} from "formik";
import dayjs from "dayjs"
import * as Yup from "yup";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import {getProductsAction} from "../../store/actions/ProductAction";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import ExportToPdfComponent from "../../components/ExportToPdfComponent";
import BreadCrumb from "../../components/BreadCrumb";
import TextField from "@material-ui/core/TextField/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";


const StockLedgerPage = () => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const [items, setItems] = useState([]);
    const products = useSelector(state => state.product.products);
    const [count, setCount] = useState(0);

    const [openingBalance, setOpeningBalance] = useState(0.00);
    const [closingBalance, setClosingBalance] = useState(0.00);

    const fetchIdRef = useRef(0);

    const url = '/reports/stock-ledger/';


    const fetchData = React.useCallback((query, cb) => {

        const fetchId = ++fetchIdRef.current;

        if (fetchId === fetchIdRef.current) {

            httpWrapper.get(url, contentTypeApplicationJson, query).then(
                (response) => {

                    if (response.data) {
                        setItems(response.data.results.transactions);
                        setCount(response.data.results.transactions.length);
                        setOpeningBalance(utils.numberFormatter(response.data.results.opening_balance));
                        setClosingBalance(utils.numberFormatter(response.data.results.closing_balance));
                    }

                    cb && cb();
                },
                (error) => {

                    cb && cb();
                }
            );
        }
    }, []);


    useEffect(() => {
        dispatch(getProductsAction());

    }, [dispatch]);


    const formik = useFormik({

        initialValues: {

            start_date: utils.DEFAULT_START_DATE,
            end_date: utils.DEFAULT_END_DATE,
            product: ''
        },

        validationSchema: Yup.object().shape({
            start_date: Yup.date("YYYY-mm-dd").required('Start date is required'),
            end_date: Yup.date("YYYY-mm-dd").required('End date is required'),
            product: Yup.number().required('Product is required')
        }),

        onSubmit(values) {

            fetchData(values, callback);
        },

        enableReinitialize: true

    });

    const callback = () => {
        formik.setSubmitting(false);
    };

    const columns = [

        {
            Header: "Date",
            accessor: d => (dayjs(d.date).format(utils.dateFormat))
        },
        {
            Header: "Reference",
            accessor: (d => d.reference ?? '-')
        },
        {
            Header: "S/N",
            accessor: (d => d.sn_no ?? '-')
        },
        {
            Header: "Type",
            accessor: 'type'
        },
        {
            Header: "Description",
            accessor: (d => d.description ?? '-')
        },
        {
            Header: 'Received',
            accessor: (d => utils.numberFormatter(d.receive))

        },
        {
            Header: 'Issue',
            accessor: (d => utils.numberFormatter(d.issue))

        },
        {
            Header: 'Balance',
            accessor: (d => utils.numberFormatter(d.balance))

        }
    ];

    return (
        <>
            <SiteWrapper>
                <BreadCrumb crumbs={[{
                    'name': 'Reports',
                    'link': '/reports'
                },
                    {
                        'name': 'Stock Ledger',
                        'link': '#'
                    }]}/>
                <Grid container className={classes.marginTop}>
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <Typography className={classes.medium}>
                                Stock Ledger
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid container item xs={12} sm={8} justify="flex-end">
                        <Box>
                            {items && <ExportToPdfComponent url={url} values={formik.values}/>}
                        </Box>
                    </Grid>
                </Grid>

                <Grid container className={classes.marginTop}>

                    <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>

                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={3}>
                                <MuiPickersUtilsProvider utils={DayjsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Start Date"
                                        maxDate={utils.maxDate}
                                        format="MM/DD/YYYY"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        value={formik.values.start_date}
                                        onChange={val => formik.setFieldValue('start_date', dayjs(val).format("YYYY-MM-DD"))}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>


                            <Grid item xs={12} sm={3}>
                                <MuiPickersUtilsProvider utils={DayjsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="End Date"
                                        format="MM/DD/YYYY"
                                        maxDate={utils.maxDate}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        value={formik.values.end_date}
                                        onChange={val => formik.setFieldValue('end_date', dayjs(val).format("YYYY-MM-DD"))}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>


                            <Grid item xs={12} sm={3}>
                                {/*<FormControl className={clsx(classes.formControl, classes.formsWithMui)}>*/}
                                {/*    <InputLabel>Product</InputLabel>*/}
                                {/*    <Select*/}
                                {/*        name="product"*/}
                                {/*        onChange={formik.handleChange}*/}
                                {/*        value={formik.values.product || ''}*/}
                                {/*        error={formik.touched.product && Boolean(formik.errors.product)}*/}
                                {/*        helperText={formik.touched.product && formik.errors.product}*/}
                                {/*    >*/}
                                {/*        <MenuItem value="">Select Value</MenuItem>*/}
                                {/*        {products && products.map((m, idp) => <MenuItem*/}
                                {/*            value={m.id}*/}
                                {/*            key={idp}>{m.name}</MenuItem>)}*/}
                                {/*    </Select>*/}
                                {/*</FormControl>*/}

                                <Autocomplete
                                    filterOptions={utils.autocompleteFilterOptions}
                                    className={clsx(classes.formControl, classes.formsWithMui)}
                                    disablePortal
                                    options={products}
                                    getOptionLabel={option => option.name}
                                    name='product'
                                    defaultValue={formik.values.product || null}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    onChange={(_, value) => value != null ? formik.setFieldValue('product', value.id) : ''}
                                    renderInput={(params) => <TextField {...params}
                                                                        error={formik.errors.product && Boolean(formik.touched.product)}
                                                                        helperText={formik.errors.product && formik.touched.product}
                                                                        name='product'
                                                                        label="Spare"/>}
                                    classes={{paper: classes.autocomplete}}
                                />
                            </Grid>
                        </Grid>
                    </form>
                    <Grid container justify="center">
                        <Button
                            onClick={formik.handleSubmit}
                            type="button"
                            size="small"
                            variant="contained"
                            color="secondary"
                            className={clsx(classes.primary, classes.formsWithMui)}
                            disabled={formik.isSubmitting}
                        >
                            Filter
                        </Button>
                    </Grid>
                </Grid>

                <Grid container justify="flex-end" className={classes.marginTop}>
                    {/* Recent Deposits */}
                    <Grid item xs={12} md={12} lg={12}>
                        {items && <EnhancedTable
                            columns={columns}
                            data={items}
                            summaries={[{
                                "title": "Closing Balance",
                                "value": closingBalance,
                                "colSpan": 2,
                                "freeSpan": 5
                            }]}
                            headings={[{
                                "title": "Opening Balance",
                                "value": openingBalance,
                                "colSpan": 2,
                                "freeSpan": 5
                            }]}
                            pageCount={count}
                        />}
                    </Grid>
                </Grid>
            </SiteWrapper>
        </>
    );
};

export default withRouter(StockLedgerPage);
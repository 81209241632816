import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import ListItem from "@material-ui/core/ListItem";
import SidebarComponent from "./components/SidebarComponent";
import {Link, withRouter} from 'react-router-dom';

import 'pace-js'
import 'pace-js/themes/blue/pace-theme-minimal.css'

import useStyles from "./styles";
import AlertComponent from "./components/AlertComponent";
import AppBarComponent from "./AppBarComponent";
import ConfirmDialog from "./components/Dialog/ConfirmDialog";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" to="#">
                ProTrack System
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const SiteWrapper = (props) => {

    const classes = useStyles();

    return (

        <div className={classes.root}>
            <AppBarComponent/>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper),
                }}
                open={true}
            >
                <List disablePadding>
                    <ListItem className={clsx(classes.logo)}>ProTrack System</ListItem>
                </List>
                <SidebarComponent/>
            </Drawer>
            <main className={clsx(classes.content, "Fade")}>
                <div className={classes.appBarSpacer}/>
                <Container className={classes.container}>
                    <AlertComponent/>
                    <ConfirmDialog/>
                    {props.children}
                </Container>
                <Box mt={1}>
                    <Copyright/>
                </Box>
            </main>
        </div>
    );
};

export default withRouter(SiteWrapper);
import {expenseConstants} from "../ActionTypes";

const initialState = {
    expenses: [],
    count: 0,
    expense: {},
    isLoading: false
};

function ExpenseReducer(state = initialState, action) {

    switch (action.type) {
        case expenseConstants.PENDING_EXPENSE_REQUEST:
            return { ...state, isLoading: true };
        case expenseConstants.GET_EXPENSES_SUCCESS:
            return { ...state, expenses: action.payload.results, count: action.payload.count, isLoading: false };
        case expenseConstants.GET_EXPENSES_ERROR:
            return {...state, isLoading: false};
        case expenseConstants.GET_EXPENSE_SUCCESS:
            return { ...state, expense: action.payload, isLoading: false };
        case expenseConstants.GET_EXPENSE_ERROR:
            return {...state, isLoading: false};
        case expenseConstants.ADD_EXPENSE_SUCCESS:
            return { ...state, expenses : state.expenses.concat(action.payload),  isLoading: false };
        case expenseConstants.ADD_EXPENSE_ERROR:
            return {...state, isLoading: false};
        case expenseConstants.EDIT_EXPENSE_SUCCESS:
            return { ...state, expense : action.payload, isLoading: false };
        case expenseConstants.EDIT_EXPENSE_ERROR:
            return { ...state, isLoading: false };
        case expenseConstants.DELETE_EXPENSE_SUCCESS:
            return {...state, expenses: state.expenses.filter((expense)=> expense.id !== action.payload), count: state.count - 1, isLoading: false };
        case expenseConstants.DELETE_EXPENSE_ERROR:
            return { ...state.expenses, isLoading: false };
        default:
            return state;
    }
}

export default ExpenseReducer;
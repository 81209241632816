import {
    contentTypeApplicationJson,
} from "../constants/httpConstants";

import {httpWrapper} from "../helper/Http";

export const getServices = async (data) => {
    return httpWrapper.get('/services/', contentTypeApplicationJson, data);
};

export const getService = async (id) => {
    return httpWrapper.get('/services/' + id, contentTypeApplicationJson);
};

export const addService = async (service) => {
    return httpWrapper.post('/services/' , service, contentTypeApplicationJson);
};

export const editService = async (id, service) => {
    return httpWrapper.update('/services/' + id + '/' , service, contentTypeApplicationJson);
};

export const deleteService = async (id) => {
    return httpWrapper.delete('/services/' + id + '/' , contentTypeApplicationJson);
};
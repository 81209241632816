import {
    contentTypeApplicationJson,
} from "../constants/httpConstants";

import {httpWrapper} from "../helper/Http";

export const getSales = async (data) => {
    return httpWrapper.get('/sales/', contentTypeApplicationJson, data);
};

export const getSale = async (sale) => {
    return httpWrapper.get('/sales/' + sale, contentTypeApplicationJson);
};

export const addSale = async (sale) => {
    return httpWrapper.post('/sales/' , sale, contentTypeApplicationJson);
};

export const editSale = async (id, sale) => {
    return httpWrapper.update('/sales/' + id + '/' , sale, contentTypeApplicationJson);
};

export const deleteSale = async (id) => {
    return httpWrapper.delete('/sales/' + id + '/' , contentTypeApplicationJson);
};

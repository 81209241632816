import {
    contentTypeApplicationJson,
} from "../constants/httpConstants";

import {httpWrapper} from "../helper/Http";

export const getProducts = async (data) => {
    return httpWrapper.get('/products/', contentTypeApplicationJson, data);
};

export const getProduct = async (id) => {
    return httpWrapper.get('/products/' + id, contentTypeApplicationJson);
};

export const addProduct = async (product) => {
    return httpWrapper.post('/products/' , product, contentTypeApplicationJson);
};

export const editProduct = async (id, product) => {
    return httpWrapper.update('/products/' + id + '/' , product, contentTypeApplicationJson);
};

export const deleteProduct = async (id) => {
    return httpWrapper.delete('/products/' + id + '/' , contentTypeApplicationJson);
};

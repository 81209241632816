import {drawerWidth} from "./constants";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        "& .MuiDataGrid-colCellWrapper": {
            color: '#051e34'
        }
    },
    toolbar: {
        backgroundColor: '#ffffff',
        color: '#757575'

    },
    brand: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 5px',
        ...theme.mixins.toolbar
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        boxShadow: 'none',
    },

    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingBottom: theme.spacing(4),
        minHeight: '87vh',

    },
    paper: {
        padding: theme.spacing(2, 2, 5, 2),
        display: 'flex',
        overflow: 'auto',
        textAlign: 'center',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    logo: {
        color: '#fff',
        minHeight: 63,
        // color: theme.palette.common.white,
    },
    itemPrimary: {
        fontSize: 'inherit',
    },
    itemIcon: {
        minWidth: 'auto',
        marginRight: theme.spacing(4),
    },
    breadCrumb: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        fontSize: '0.9rem'
    },
    appMenu: {
        width: '100%',
    },
    menuItemIcon: {
        minWidth: 'auto',
        marginRight: theme.spacing(4),
    },
    divider: {
        marginTop: theme.spacing(2),
    },
    item: {
        color: '#8E9BA2',
        transition: 'color .15s ease-in-out, background-color .15s ease-in-out',
        '&:hover': {
            backgroundColor: '#223044',
            color: '#e1e1e1'
        },
    },
    itemCategory: {
        backgroundColor: '#6e7680',
        boxShadow: '0 -1px 0 #404854 inset',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
    },
    headingBar: {
        minHeight: 40,
    },
    tablePagination: {
        flexShrink: 0,
    },
    success: {
        backgroundColor: "#5cb85c",
        color: "#fff",
        '&:hover': {
            backgroundColor: "#5cb85c",
        },
    },
    danger: {
        backgroundColor: '#df4759',
        color: "#fff",
        '&:hover': {
            backgroundColor: "#df4759",
        },
    },
    primary: {
        backgroundColor: '#0275d8',
        color: "#fff",
        '&:hover': {
            backgroundColor: "#0275d8",
        },
    },
    secondary: {
        backgroundColor: '#6c757d',
        color: "#fff",
        '&:hover': {
            backgroundColor: "#6c757d",
        },
    },
    info: {
        backgroundColor: '#17a2b8',
        color: "#fff",
        '&:hover': {
            backgroundColor: "#17a2b8",
        },
    },

    cellStyle: {
        margin: 0,
        padding: 5
    },
    actionLink: {
        marginRight: theme.spacing(0.7),
        color: '#757575',
        textDecoration: 'none',
        fontSize: '0.9rem'
    },
    actionLinkIcon: {
        fontSize: '.8rem',
        marginRight: '0.1rem'
    },
    actionMenu: {},
    unstyledLink: {
        textDecoration: 'none',
        color: 'inherit',
        margin: theme.spacing(0, 1, 0, 1)
    },
    formControl: {
        minWidth: 230,
        width: '100%'
    },
    formControlMin: {
        minWidth: 140,
        width: '100%'
    },

    badge: {
        marginRight: theme.spacing(1)
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    countStatsContainer: {
        marginTop: theme.spacing(3),
        fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif'
    },
    totalCount: {
        fontWeight: "bold"
    },
    totalName: {
        marginTop: theme.spacing(1),
        opacity: 0.7
    },
    textPrimary: {
        color: '#2196F3'
    },
    textSuccess: {
        color: '#43A047'
    },
    textMaroon: {
        color: '#d81b60'
    },
    expenseChartContainer: {
        minHeight: '35vw',
        marginTop: theme.spacing(2),
    },
    marginTop: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    medium: {
        fontSize: '1.3rem'
    },
    sizeAvatar: {
        width: theme.spacing(17),
        height: theme.spacing(17),
        backgroundColor: '#f4f5f6',
        color: '#99a1a9',
        textAlign: 'center',
        align: 'center',
        marginBottom: '1rem'
    },
    formsWithMui: {
        margin: theme.spacing(2, 1, 0, 0),
    },
    reportCardHeader: {
        padding: theme.spacing(2, 2, 0, 2),
    },
    reportCardContent: {
        margin: 0,
    },
    reportLinks: {
        padding: theme.spacing(0, 0, 2, 0),
        '&:hover': {
            opacity: 0.7,
            transition: 'opacity .15s ease-in-out',
        },
    },
    mainMeu: {
        marginTop: theme.spacing(4.5)
    },
    menuItem: {
        fontSize: '.8rem',
        '&:hover': {
            transition: 'opacity .15s ease-in-out',
            background: 'none'
        },
    },
    actionButton: {
        color: '#0061a8',
        margin: 0
    },
    linkItem: {
        color: '#0061a8',
        textDecoration: 'none',
        '&:hover': {
            transition: 'opacity .15s ease-in-out',
            opacity: 0.7,
            textDecoration: 'none'
        },
    },
    breadCrumbLinks: {
        color: '#0061a8',
        display: 'flex',
        textDecoration: 'none',
        '&:hover': {
            transition: 'opacity .15s ease-in-out',
            opacity: 0.7,
            textDecoration: 'none'
        }
    },
    icon: {
        marginRight: theme.spacing(0.5),
    },
    bolded: {
        fontWeight: "bold"
    },
    pageTitle: {
        fontSize: '1.0rem',
        marginTop: theme.spacing(0.6),
    },
    autocomplete: {
        width: '25rem'
    },
    alignColumnRight : {
        textAlign: 'right',
    }


}), {index: 1});

export default useStyles;
import {BASE_URL, isDevelopmentMode} from "../constants";
import {auth} from "./Auth";
import {createFilterOptions} from "@material-ui/lab";
import dayjs from "dayjs";


const numberFormatter = (number, decimalPoints = 2) => {

    if (number === null || isNaN(number)){

        return 0.00;
    }

    return parseFloat(number).toLocaleString('en-US',
        {minimumFractionDigits: decimalPoints, maximumFractionDigits: 2});
};

const getScheme = () => {

    if ((auth.getUser() && auth.getUser().company === 'demo') || isDevelopmentMode)  return 'http://';

    return 'https://';

};



const getAppUrl = () => {

    return auth.getUser() ? getScheme() + auth.getUser().company + BASE_URL :  'http://not-authorized' + BASE_URL;

};

const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

const months = (config) => {
    let cfg = config || {};
    let count = cfg.count || 12;
    let section = cfg.section;
    let values = [];
    let i, value;

    for (i = 0; i < count; ++i) {
        value = MONTHS[Math.ceil(i) % 12];
        values.push(value.substring(0, section));
    }

    return values;
};

const downloadFileLink = (uri, params) => {

    let para = new URLSearchParams(params).toString();

    return getAppUrl() + uri + "?" + para;
};

const maxDate = new Date();

const filterStart = 7;

const autocompleteFilterOptions = createFilterOptions({
    matchFrom: 'start',
    limit: 100,
});

const dateFormat = "DD-MM-YYYY";

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_INDEX = 0;

const DEFAULT_START_DATE = dayjs().subtract(30, 'day').format("YYYY-MM-DD");
const DEFAULT_END_DATE = dayjs().format("YYYY-MM-DD");

export const utils = {
    numberFormatter,
    getAppUrl,
    months,
    downloadFileLink,
    maxDate,
    filterStart,
    autocompleteFilterOptions,
    dateFormat,
    DEFAULT_PAGE_SIZE,
    DEFAULT_PAGE_INDEX,
    DEFAULT_START_DATE,
    DEFAULT_END_DATE,
    getScheme
};

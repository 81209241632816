import React, {useEffect} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import SiteWrapper from "../../SiteWrapper";
import {useDispatch, useSelector} from "react-redux";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import BreadCrumb from "../../components/BreadCrumb";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
    addWorkflowApproverAction,
    editWorkflowApproverAction,
    getWorkflowApproverAction,
    getWorkflowRulesAction
} from "../../store/actions/WorkflowAction";

import useStyles from "../../styles";
import {getUsersAction} from "../../store/actions/UserAction";

function AddEditApproverPage({history, match}) {

    const classes = useStyles();

    const {id} = match.params;

    const isAddMode = !id;
    const dispatch = useDispatch();

    const rules = useSelector(state => state.workflow.rules);
    const approvers = useSelector(state => state.user.users);
    const approver =  useSelector(state => state.workflow.approver);

    useEffect(() => {

        if (!isAddMode) {

            dispatch(getWorkflowApproverAction(id));
        }

        dispatch(getWorkflowRulesAction());
        dispatch(getUsersAction());


    }, [dispatch, isAddMode, id]);

    const formik = useFormik({

        initialValues: {
            rule: approver.rule ? approver.rule.url : '',
            approver: approver.approver ? approver.approver.url : '',
            sequence: approver ? approver.sequence : ''
        },

        validationSchema: Yup.object().shape({
            rule: Yup.string("Must be a string").required('Rule is required'),
            approver: Yup.string("Must be a string").required('Approver is required'),
            sequence: Yup.number().typeError('Amount must be a number').required('Sequence is required')
        }),

        onSubmit(values) {

            isAddMode ? dispatch(addWorkflowApproverAction(values, errorCallback)) : dispatch(editWorkflowApproverAction(id, values, errorCallback));
        },

        enableReinitialize: true
    });

    const errorCallback = (error) => {
        formik.setSubmitting(false);
    };

    return (
        <SiteWrapper>
            <BreadCrumb page="New Approver"/>
            <Paper className={classes.paper}>
                <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                    <Grid container spacing={4}>

                        <Grid item xs={6} sm={2} md={2}>
                            <FormControl className={classes.formControl}>
                                <InputLabel>Work Rule</InputLabel>
                                <Select
                                    name='rule'
                                    onChange={formik.handleChange}
                                    value={formik.values.rule || ''}
                                    error={formik.touched.rule && Boolean(formik.errors.rule)}
                                    helperText={formik.touched.rule && formik.errors.rule}
                                >
                                    {rules && rules.map((m, idx) => <MenuItem
                                        value={m.url}
                                        key={idx}>{m.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} sm={2} md={10}>
                            <FormControl className={classes.formControl}>
                                <InputLabel>Approver</InputLabel>
                                <Select
                                    name='approver'
                                    onChange={formik.handleChange}
                                    value={formik.values.approver || ''}
                                    error={formik.touched.approver && Boolean(formik.errors.approver)}
                                    helperText={formik.touched.approver && formik.errors.approver}
                                >
                                    {approvers && approvers.map((m, idx) => <MenuItem
                                        value={m.url}
                                        key={idx}>{m.full_name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="sequence"
                                variant="outlined"
                                required
                                fullWidth
                                id="sequence"
                                label="sequence"
                                value={formik.values.sequence || ''} onChange={formik.handleChange}
                                error={formik.touched.sequence && Boolean(formik.errors.sequence)}
                                helperText={formik.touched.sequence && formik.errors.sequence}
                            />
                        </Grid>

                        <Grid item xs={6} sm={2}>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.status}
                                        onChange={event => formik.setFieldValue('status', event.target.checked)}
                                        color="primary"
                                        name="Active"
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                }
                                label="Active"
                            />
                        </Grid>

                        <Grid container justify="flex-end">

                            <Grid item xs={6} sm={1}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="default"
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                > Cancel </Button>

                            </Grid>

                            <Grid item xs={6} sm={1}>
                                <Button
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.success}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </SiteWrapper>
    );
}

export default AddEditApproverPage;

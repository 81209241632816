import {
    contentTypeApplicationJson,
} from "../constants/httpConstants";

import {httpWrapper} from "../helper/Http";

export const getConfigurations = async (data) => {
    return httpWrapper.get('/configurations/', contentTypeApplicationJson, data);
};

export const getConfiguration = async (id) => {
    return httpWrapper.get('/configurations/' + id, contentTypeApplicationJson);
};

export const addConfiguration = async (configuration) => {
    return httpWrapper.post('/configurations/' , configuration, contentTypeApplicationJson);
};

export const editConfiguration = async (id, configuration) => {
    return httpWrapper.update('/configurations/' + id + '/' , configuration, contentTypeApplicationJson);
};

export const deleteConfiguration = async (id) => {
    return httpWrapper.delete('/configurations/' + id + '/' , contentTypeApplicationJson);
};
import {purchaseConstants} from "../ActionTypes";

const initialState = {
    purchases: [],
    count: 0,
    purchase: {},
    isLoading: false
};

function PurchaseReducer(state = initialState, action) {

    switch (action.type) {
        case purchaseConstants.PENDING_PURCHASE_REQUEST:
            return { ...state, isLoading: true };
        case purchaseConstants.GET_PURCHASES_SUCCESS:
            return { ...state, purchases: action.payload.results, count: action.payload.count, isLoading: false };
        case purchaseConstants.GET_PURCHASES_ERROR:
            return {...state, isLoading: false};
        case purchaseConstants.GET_PURCHASE_SUCCESS:
            return { ...state, purchase: action.payload, isLoading: false };
        case purchaseConstants.GET_PURCHASE_ERROR:
            return {...state, isLoading: false};
        case purchaseConstants.ADD_PURCHASE_SUCCESS:
            return { ...state, purchases : state.purchases.concat(action.payload),  isLoading: false };
        case purchaseConstants.ADD_PURCHASE_ERROR:
            return {...state, isLoading: false};
        case purchaseConstants.EDIT_PURCHASE_SUCCESS:
            return { ...state, purchase : action.payload, isLoading: false };
        case purchaseConstants.EDIT_PURCHASE_ERROR:
            return { ...state, isLoading: false };
        case purchaseConstants.DELETE_PURCHASE_SUCCESS:
            return {...state, purchases: state.purchases.filter((purchase)=>purchase.id !== action.payload), count: state.count - 1, isLoading: false };
        case purchaseConstants.DELETE_PURCHASE_ERROR:
            return { ...state.purchases, isLoading: false };
        default:
            return state;
    }
}

export default PurchaseReducer;
import React, {useEffect, useRef} from 'react';
import Grid from "@material-ui/core/Grid";
import BreadCrumb from "../../components/BreadCrumb";


import {Link, withRouter} from 'react-router-dom';
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import Box from "@material-ui/core/Box";
import useStyles from "../../styles";
import {useDispatch, useSelector} from "react-redux";
import EnhancedTable from "../../components/EnhancedTable";
import SiteWrapper from "../../SiteWrapper";
import dayjs from "dayjs";
import {getAdjustmentsAction} from "../../store/actions/StockAdjustmentAction";
import {utils} from "../../helper/Utils";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";


const StockAdjustmentPage = () => {

    const classes = useStyles();

    const dispatch = useDispatch();
    const adjustments = useSelector(state => state.stock_adjustment.adjustments);
    const count = useSelector(state => state.stock_adjustment.count);

    const fetchIdRef = useRef(0);

    const fetchData = React.useCallback((pager, query) => {

        let search = Object.assign({}, {limit: pager.pageSize, offset: pager.pageIndex * pager.pageSize}, query);

        const fetchId = ++fetchIdRef.current;

        if (fetchId === fetchIdRef.current) {
            dispatch(getAdjustmentsAction(search));
        }

    }, [dispatch]);


    useEffect(() => {
        fetchData({pageIndex: utils.DEFAULT_PAGE_INDEX, pageSize: utils.DEFAULT_PAGE_SIZE});
    }, [fetchData]);


    const columns = [

        {
            Header: "Date",
            accessor: d => (dayjs(d.effective_date).format(utils.dateFormat))
        },
        {
            Header: "Product",
            accessor: d => (d.product ? d.product.name : '-')
        },
        {
            Header: "Serial",
            accessor: d => (d.sn_no ? d.sn_no : '-')
        },
        {
            Header: "Quantity",
            accessor: d => (utils.numberFormatter(d.quantity))
        },
        {
            Header: "Price",
            accessor: d => (utils.numberFormatter(d.price))
        },
        {
            Header: "Type",
            accessor: 'type'
        },
    ];

    return (
        <>
            <SiteWrapper>
                <BreadCrumb crumbs={[{
                    'name': 'Stock adjustments',
                    'link': '#'
                }]}/>

                <Grid container>

                    <Grid container className={classes.marginTop}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <Typography className={clsx(classes.medium, classes.pageTitle)}>
                                    Stock Adjustments
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid container item xs={12} sm={8} justify="flex-end">
                            <Box>
                                <Button className={classes.actionButton} startIcon={<AddIcon/>}>
                                    <Link to="/stock-adjustments/create" className={classes.unstyledLink}>Adjust Stock</Link>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                    {/* Recent Deposits */}
                    <Grid item xs={12} md={12} lg={12}>
                            {adjustments && <EnhancedTable
                                columns={columns}
                                data={adjustments}
                                fetchData={fetchData}
                                pageCount={count && count}
                            />}
                    </Grid>

                </Grid>
            </SiteWrapper>
        </>
    );
};

export default withRouter(StockAdjustmentPage);
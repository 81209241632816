import { push } from 'connected-react-router';

import {
    getSales,
    getSale,
    addSale,
    editSale,
    deleteSale
} from "../../services/SaleService";


import {saleConstants} from "../ActionTypes";

import {alertActions} from "./AlertAction";



export const getSalesAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: saleConstants.PENDING_SALE_REQUEST
        });
        return getSales(data).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: saleConstants.GET_SALES_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: saleConstants.GET_SALES_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};

export const getSaleAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: saleConstants.PENDING_SALE_REQUEST
        });
        return getSale(data).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: saleConstants.GET_SALE_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: saleConstants.GET_SALE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};


export const addSaleAction = (sale, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: saleConstants.PENDING_SALE_REQUEST
        });
        return addSale(sale).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: saleConstants.ADD_SALE_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/sales'));
                    dispatch(alertActions.success('Sale added successfully'));

                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: saleConstants.ADD_SALE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};


export const editSaleAction = (id, sale, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: saleConstants.PENDING_SALE_REQUEST
        });
        return editSale(id, sale).then(
            (response) => {
                if (response.data) {

                    dispatch({
                        type: saleConstants.EDIT_SALE_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/sales'));
                    dispatch(alertActions.success('Sale edited successfully'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: saleConstants.EDIT_SALE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};

export const deleteSaleAction = (id) => {
    return (dispatch) => {
        dispatch({
            type: saleConstants.PENDING_SALE_REQUEST
        });
        return deleteSale(id).then(
            (response) => {
                if (response) {
                    dispatch({
                        type: saleConstants.DELETE_SALE_SUCCESS,
                        payload: id
                    });
                    dispatch(push('/sales'));
                    dispatch(alertActions.success('Sale deleted successfully'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: saleConstants.DELETE_SALE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};
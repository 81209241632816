import React from 'react';
import {Link} from 'react-router-dom'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';

import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconShoppingCart from '@material-ui/icons/ShoppingCart';
import IconPeople from '@material-ui/icons/People';
import IconBarChart from '@material-ui/icons/BarChart';
import LocalShipping from '@material-ui/icons/LocalShipping';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import AssignmentTurnedInSharpIcon from '@material-ui/icons/AssignmentTurnedInSharp';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PaymentIcon from '@material-ui/icons/Payment';
import HomeIcon from '@material-ui/icons/Home';
import useStyles from "../styles";

const appMenuItems = [
    {
        name: 'Dashboard',
        Icon: HomeIcon,
        link: '/'
    },
    {
        name: 'Payable',
        Icon: PaymentIcon,
        items: [
            {
                name: 'Suppliers',
                link: '/suppliers'
            },
            {
                name: 'Expenses',
                link: '/expenses'
            },
            {
                name: 'Purchases',
                link: '/purchases'
            }
        ],
    },
    {
        name: 'Receivable',
        Icon: AccountBalanceIcon,
        items: [
            {
                name: 'Customers',
                link: '/customers'
            },
            {
                name: 'Sales',
                link: '/sales'
            }
        ],
    },
    {
        name: 'Logistic',
        Icon: LocalShipping,
        items: [
            {
                name: 'Vehicles',
                link: '/vehicles'
            },
            {
                name: 'Trips',
                link: '/trips'
            },
            {
                name: 'Routes',
                link: '/routes'
            }
        ],
    },
    {
        name: 'Inventory',
        Icon: IconShoppingCart,
        items: [
            {
                name: 'Spare Parts',
                link: '/parts'
            },
            {
                name: 'Stock Adjustment',
                link: '/stock-adjustments'
            },
            {
                name: 'Services',
                link: '/services'
            }
        ],
    },
    {
        name: 'Employees',
        link: '/employees',
        Icon: IconPeople,
    },
    {
        name: 'Settings',
        Icon: SettingsIcon,
        items: [
            {
                name: 'Users',
                link: '/users'
            },
            {
                name: 'Configurations',
                link: '/configurations'
            },
            {
                name: 'System Settings',
                link: '/settings'
            }
        ],
    },
    {
        name: 'Workflow',
        Icon: SettingsApplicationsIcon,
        link: '/workflow'
    },
    {
        name: 'Approvals',
        Icon: AssignmentTurnedInSharpIcon,
        link: '/workflow/pending-approvals'
    },
    {
        name: 'Reports',
        link: '/reports',
        Icon: IconBarChart,
    }
];

function SidebarMenuItem(props) {

    const {className, onClick, link, children} = props;

    // If link is not set return the orinary ListItem
    if (!link || typeof link !== 'string') {
        return (
            <ListItem
                button
                className={className}
                children={children}
                onClick={onClick}
            />
        )
    }
    // Return a LitItem with a link component
    return (
        <ListItem
            button
            className={className}
            children={children}
            component={Link}
            to={link}
        />
    )

};

const SidebarMenu = (props) => {

    const {name, Icon, link, items = []} = props;

    const classes = useStyles();
    const isExpandable = items && items.length > 0;
    const [open, setOpen] = React.useState(false);

    function handleClick() {
        setOpen(!open)
    }

    const MenuItemRoot = (
        <SidebarMenuItem button className={classes.item} onClick={handleClick} link={link}>
            {/* Display an icon if any */}
            {!!Icon && (
                <ListItemIcon className={classes.menuItemIcon}>
                    <Icon/>
                </ListItemIcon>
            )}
            <ListItemText primary={name} inset={!Icon} classes={{
                primary: classes.itemPrimary
            }}/>
            {/* Display the expand menu if the item has children */}
            {/*{isExpandable && !open && <IconExpandMore/>}*/}
            {isExpandable && open && <IconExpandLess/>}
        </SidebarMenuItem>
    );

    const MenuItemChildren = isExpandable ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider/>
            <List component="div" disablePadding>
                {items.map((item, index) => (
                    <SidebarMenu {...item} key={index} />
                ))}
            </List>
        </Collapse>
    ) : null;

    return (
        <>
            {MenuItemRoot}
            {MenuItemChildren}
        </>
    )
};

function SidebarComponent() {

    const classes = useStyles();

    return (
        <List component="nav" className={classes.appMenu} disablePadding>
            {appMenuItems.map((item, index) => (
                <SidebarMenu {...item} key={index}/>
            ))}
        </List>
    );

}

export default SidebarComponent;

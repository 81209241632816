import React, {useEffect} from 'react';

import SiteWrapper from "../../SiteWrapper";
import {Link, withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import useStyles from "../../styles";
import Grid from "@material-ui/core/Grid";

import Paper from "@material-ui/core/Paper";
import {utils} from "../../helper/Utils";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {deleteExpenseAction, getExpenseAction} from "../../store/actions/ExpenseAction";
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import clsx from "clsx";
import dayjs from "dayjs";
import MenuActionComponent from "../../components/MenuActionComponent";


const ExpensePage = ({match}) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const {id} = match.params;

    const expense = useSelector(state => state.expense.expense);

    useEffect(() => {
        dispatch(getExpenseAction(id));
    }, [dispatch, id]);

    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[{
                'name': 'Expenses',
                'link': '/expenses'
            }]}/>


            {expense ?
                <div>
                    <Grid container className={classes.marginTop}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <Typography className={clsx(classes.pageTitle)}>
                                    {expense.reference}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid container item xs={12} md={8} justify="flex-end">
                            <Box>
                                <Button className={classes.actionButton} startIcon={<AddIcon/>}>
                                    <Link to="/expenses/create" className={classes.unstyledLink}>New Expense</Link>
                                </Button>
                            </Box>
                            <Box>
                                <MenuActionComponent item={{
                                    id: expense.id,
                                    showView: false,
                                    hideEdit: expense.status !== 'Saved',
                                    hideDelete: expense.status !== 'Saved'
                                }}
                                                     url={"expenses"} deleteAction={deleteExpenseAction}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.marginTop}>
                        <Grid item xs={12} sm={2}>
                            <Box mr={2}>
                                <Box mt={3}></Box>
                                <Box mt={2}>
                                    <Typography variant="caption">
                                        Date
                                    </Typography>
                                    <Typography variant="body1">
                                        {dayjs(expense.effective_date).format(utils.dateFormat)}
                                    </Typography>
                                </Box>
                                {expense.created_by && <Box mt={2}>
                                    <Typography variant="caption">
                                        Created By
                                    </Typography>
                                    <Typography variant="body1">
                                        {expense.created_by.full_name}
                                    </Typography>
                                </Box>}

                                {expense.created_at && <Box mt={2}>
                                    <Typography variant="caption">
                                        Created At
                                    </Typography>
                                    <Typography variant="body1">
                                        {dayjs(expense.created_at).format(utils.dateFormat)}
                                    </Typography>
                                </Box>}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <Paper className={classes.paper}>

                                <Grid container spacing={4}>
                                    <Grid item><Typography variant="subtitle1">Expense Items</Typography>
                                    </Grid></Grid>


                                <Grid container spacing={2}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Truck</TableCell>
                                                <TableCell>Driver</TableCell>
                                                <TableCell align="right">Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                expense.expense_lines && expense.expense_lines.map((expense_line, i) => {

                                                    return (expense_line && <TableRow key={i}>
                                                            <TableCell component="th"
                                                                       scope="row">{expense_line.type.name}</TableCell>
                                                            <TableCell>{expense_line.machine.reg_no}</TableCell>
                                                            <TableCell>{expense_line.driver.full_name}</TableCell>
                                                            <TableCell
                                                                align="right">{utils.numberFormatter(expense_line.amount)}</TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            }
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell component="th"
                                                           scope="row">Total</TableCell>
                                                <TableCell
                                                    align="right">{utils.numberFormatter(expense.total_expenses)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>

                            </Paper>
                        </Grid>
                    </Grid></div> :

                <Grid container className={classes.marginTop}>
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <Typography>
                                Oops! Expense not found. View all expenses
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            }
        </SiteWrapper>
    )
};

export default withRouter(ExpensePage);
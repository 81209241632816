import {settingConstants, workflowConstants} from "../ActionTypes";

const initialState = {
    processes: [],
    transactions: [],
    transactionsCount: 0,
    transaction: {},
    rules: [],
    approvers: [],
    approver: {},
    isLoading: false
};

function WorkflowReducer(state = initialState, action) {

    switch (action.type) {
        case workflowConstants.PENDING_WORKFLOW_REQUEST:
            return {...state, isLoading: true};
        case workflowConstants.GET_PROCESSES_SUCCESS:
            return {...state, processes: action.payload, isLoading: false};
        case workflowConstants.GET_PROCESSES_ERROR:
            return {...state, isLoading: false};
        case workflowConstants.GET_RULES_SUCCESS:
            return {...state, rules: action.payload, isLoading: false};
        case workflowConstants.GET_RULES_ERROR:
            return {...state, isLoading: false};
        case workflowConstants.ADD_RULE_SUCCESS:
            return {...state, rules: state.rules.concat(action.payload), isLoading: false};
        case workflowConstants.ADD_RULE_ERROR:
            return {...state, isLoading: false};
        case workflowConstants.EDIT_RULE_SUCCESS:
            return {...state, rule: action.payload, isLoading: false};
        case workflowConstants.EDIT_RULE_ERROR:
            return {...state, isLoading: false};
        case workflowConstants.DELETE_RULE_SUCCESS:
            return {...state, rules: state.rules.filter((rule) => rule.id !== action.payload), isLoading: false};
        case settingConstants.DELETE_RULE_ERROR:
            return {...state.settings, isLoading: false};
        case workflowConstants.GET_APPROVERS_SUCCESS:
            return {...state, approvers: action.payload, isLoading: false};
        case workflowConstants.GET_APPROVERS_ERROR:
            return {...state, isLoading: false};
        case workflowConstants.GET_APPROVER_SUCCESS:
            return {...state, approver: action.payload, isLoading: false};
        case workflowConstants.GET_APPROVER_ERROR:
            return {...state, isLoading: false};
        case workflowConstants.ADD_APPROVER_SUCCESS:
            return {...state, approvers: state.approvers.concat(action.payload), isLoading: false};
        case workflowConstants.ADD_APPROVER_ERROR:
            return {...state, isLoading: false};
        case workflowConstants.EDIT_APPROVER_SUCCESS:
            return {...state, approver: action.payload, isLoading: false};
        case workflowConstants.EDIT_APPROVER_ERROR:
            return {...state, isLoading: false};
        case workflowConstants.DELETE_APPROVER_SUCCESS:
            return {
                ...state,
                approvers: state.approvers.filter((approver) => approver.id !== action.payload),
                isLoading: false
            };
        case settingConstants.DELETE_APPROVER_ERROR:
            return {...state.approvers, isLoading: false};
        case workflowConstants.GET_TRANSACTIONS_SUCCESS:
            return {...state, transactions: action.payload.results, transactionsCount: action.payload.count, isLoading: false};
        case workflowConstants.GET_TRANSACTIONS_ERROR:
            return {...state, isLoading: false};
        default:
            return state;
    }
}

export default WorkflowReducer;
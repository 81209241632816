import React from 'react';
import Badge from "@material-ui/core/Badge";
import useStyles from "../styles";

function StatusComponent(props) {

    const classes = useStyles();

    const status = props.status;

    return status ?  <div><Badge classes={{ badge: classes.success}} className={classes.badge} variant="dot"></Badge> Active</div>
        : <div><Badge classes={{ badge: classes.danger}} className={classes.badge} variant="dot"></Badge> Inactive</div>;
}

export default StatusComponent;

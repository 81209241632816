import React from 'react';

import SiteWrapper from "../../SiteWrapper";
import {useSelector} from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import useStyles from "../../styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import BreadCrumb from "../../components/BreadCrumb";
import MenuActionComponent from "../../components/MenuActionComponent";
import {deleteCustomerAction} from "../../store/actions/CustomerAction";
import dayjs from "../trip/TripPage";
import {utils} from "../../helper/Utils";

function CustomerPage({ match}) {

    const classes = useStyles();

    const {id} = match.params;

    const customers = useSelector(state => state.customer.customers);

    const customer = customers.find(customer => customer.id === parseInt(id));

    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[{
                'name': 'Customers',
                'link': '/customers'
            }]}/>
            <Grid container className={classes.marginTop}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography className={classes.medium}>
                            {customer && customer.reference}
                        </Typography>
                    </Box>
                </Grid>
                <Grid container item xs={12} sm={8} justify="flex-end">
                    {customer &&
                    <MenuActionComponent item={{id: customer.id, showView: false}}
                                         url={"customers"}  deleteAction={deleteCustomerAction}
                    />}
                </Grid>
            </Grid>

            <Grid container className={classes.marginTop}>
                <Grid item xs={12} sm={2}>
                    <Box mr={2}>
                        <Avatar variant="square" className={classes.sizeAvatar}>
                            {customer && customer.name}
                        </Avatar>
                    </Box>

                    {customer.created_by && <Box mt={2}>
                        <Typography variant="caption">
                            Created By
                        </Typography>
                        <Typography variant="body1">
                            {customer.created_by.full_name}
                        </Typography>
                    </Box>}

                    {customer.created_at && <Box mt={2}>
                        <Typography variant="caption">
                            Created At
                        </Typography>
                        <Typography variant="body1">
                            {dayjs(customer.created_at).format(utils.dateFormat)}
                        </Typography>
                    </Box>}
                </Grid>
                <Grid item xs={12} sm={10}>
                    <Paper className={classes.paper}>

                        {customer ?
                        <form className={classes.form} noValidate>

                            <Grid container spacing={4}>
                                <Grid item md="auto"><Typography variant="subtitle1">Customer
                                    Information</Typography></Grid>
                            </Grid>

                            <Grid container direction="row" spacing={4} align="left">
                                <Grid item md={3}><Typography variant="subtitle2">Name</Typography></Grid>
                                <Grid item md={6} align="left">{customer.name}</Grid>
                            </Grid>

                            <Grid container direction="row" spacing={4} align="left">
                                <Grid item md={3}><Typography variant="subtitle2">Address</Typography></Grid>
                                <Grid item md={6} align="left">{customer.address ? customer.address : '-'}</Grid>
                            </Grid>

                            <Grid container direction="row" spacing={4} align="left">
                                <Grid item md={3}><Typography variant="subtitle2">Email</Typography></Grid>
                                <Grid item md={6} align="left">{customer.email ? customer.email : '-'}</Grid>
                            </Grid>

                            <Grid container direction="row" spacing={4} align="left">
                                <Grid item md={3}><Typography variant="subtitle2">Tell</Typography></Grid>
                                <Grid item md={6} align="left">{customer.tel ? customer.tel : '-'}</Grid>
                            </Grid>

                            <Grid container direction="row" spacing={4} align="left">
                                <Grid item md={3}><Typography variant="subtitle2">Status</Typography></Grid>
                                <Grid item md={6} align="left">{customer.status ? 'Active': 'Inactive'}</Grid>
                            </Grid>
                        </form> :

                            <Grid container className={classes.marginTop}>
                                <Grid item xs={12} sm={4}>
                                    <Box>
                                        <Typography>
                                            Oops! Customer not found. View all customers
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        }
                    </Paper>
                </Grid>
            </Grid>
        </SiteWrapper>
    );
}

export default CustomerPage;

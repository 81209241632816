import {makeStyles} from "@material-ui/core";

export default makeStyles(theme => ({
    headerMenu: {
        marginTop: theme.spacing(5),
    },
    headerMenuList: {
        display: "flex",
        flexDirection: "column",
    },
    headerMenuItem: {
        "&:hover, &:focus": {
            backgroundColor: theme.palette.background.light,
            // color: "white",
        },
    },
    headerMenuButton: {
        marginLeft: theme.spacing(2),
        padding: theme.spacing(0.5),
    },
    headerMenuButtonSandwich: {
        marginLeft: 9,
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0
        },
        padding: theme.spacing(0.5),
    },
    headerMenuButtonCollapse: {
        marginRight: theme.spacing(2),
    },
    headerIcon: {
        fontSize: 28,
        color: "rgba(255, 255, 255, 0.35)",
    },
    headerIconCollapse: {
        color: "white",
    },
    profileMenu: {
        minWidth: 265,
    },
    profileMenuUser: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1),
    },
    profileMenuItem: {
        color: theme.palette.text.hint,
    },
    profileMenuIcon: {
        marginRight: theme.spacing(2),
        color: theme.palette.text.hint,
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    profileMenuLink: {
        fontSize: 16,
        textDecoration: "none",
        "&:hover": {
            cursor: "pointer",
        },
    }
}));
import {
    GET_SUPPLIERS_SUCCESS,
    GET_SUPPLIERS_ERROR,
    ADD_SUPPLIER_SUCCESS,
    ADD_SUPPLIER_ERROR,
    EDIT_SUPPLIER_SUCCESS,
    EDIT_SUPPLIER_ERROR,
    DELETE_SUPPLIER_SUCCESS,
    DELETE_SUPPLIER_ERROR,
    PENDING_SUPPLIER_REQUEST
} from "../ActionTypes";

const initialState = {
    suppliers: [],
    count: 0,
    supplier:{},
    isLoading: false
};

function SupplierReducer(state = initialState, action) {

    switch (action.type) {
        case PENDING_SUPPLIER_REQUEST:
            return { ...state, isLoading: true };
        case GET_SUPPLIERS_SUCCESS:
            return { ...state, suppliers: action.payload.results, count: action.payload.count, isLoading: false };
        case GET_SUPPLIERS_ERROR:
            return {...state, isLoading: false};
        case ADD_SUPPLIER_SUCCESS:
            return { ...state, suppliers : state.suppliers.concat(action.payload),  isLoading: false };
        case ADD_SUPPLIER_ERROR:
            return {...state, isLoading: false};
        case EDIT_SUPPLIER_SUCCESS:
            return { ...state, supplier : action.payload, isLoading: false };
        case EDIT_SUPPLIER_ERROR:
            return { ...state, isLoading: false };
        case DELETE_SUPPLIER_SUCCESS:
            return {...state, suppliers: state.suppliers.filter((supplier) => supplier.id !== action.payload), count: state.count - 1, isLoading: false };
        case DELETE_SUPPLIER_ERROR:
            return { ...state.suppliers, isLoading: false };
        default:
            return state;
    }
}

export default SupplierReducer;
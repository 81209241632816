import {
    contentTypeApplicationJson,
} from "../constants/httpConstants";

import {httpWrapper} from "../helper/Http";

export const getUsers = async () => {
    return httpWrapper.get('/users/', contentTypeApplicationJson);
};

export const getUser = async (id) => {
    return httpWrapper.get('/users/' + id, contentTypeApplicationJson);
};

export const addUser = async (user) => {
    return httpWrapper.post('/users/' , user, contentTypeApplicationJson);
};

export const editUser = async (id, user) => {
    return httpWrapper.update('/users/' + id + '/' , user, contentTypeApplicationJson);
};

export const changeUserPassword = async (id, user) => {
    return httpWrapper.post('/users/' + id + '/change-user-password/' , user, contentTypeApplicationJson);
};

export const deleteUser = async (id) => {
    return httpWrapper.delete('/users/' + id + '/' , contentTypeApplicationJson);
};

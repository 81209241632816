import {
    contentTypeApplicationJson,
} from "../constants/httpConstants";

import {httpWrapper} from "../helper/Http";

export const getPurchases = async (data) => {
    return httpWrapper.get('/purchases/', contentTypeApplicationJson, data);
};

export const getPurchase = async (id) => {
    return httpWrapper.get('/purchases/' + id,  contentTypeApplicationJson);
};

export const addPurchase = async (purchase) => {
    return httpWrapper.post('/purchases/' , purchase, contentTypeApplicationJson);
};

export const editPurchase = async (id, purchase) => {
    return httpWrapper.update('/purchases/' + id + '/' , purchase, contentTypeApplicationJson);
};

export const deletePurchase = async (id) => {
    return httpWrapper.delete('/purchases/' + id + '/' , contentTypeApplicationJson);
};
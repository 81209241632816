import axios from "axios";

import {utils} from './Utils';
import {logoutService} from "../services/AuthService";

const APP_URL = utils.getAppUrl();

const post = async (uri, data, options) => {
    return await axios
        .post(`${APP_URL}` + uri, data, options)
        .then((data) => data);
};

const update = async (uri, data, options) => {
    return await axios
        .put(`${APP_URL}` + uri, data, options)
        .then((data) => data);
};

const get = async (uri, options, data) => {

    options['params'] = data;

    return await axios
        .get(`${APP_URL}` + uri, options)
        .then((data) => data);
};

const _delete = async (uri, options) => {
    return await axios
        .delete(`${APP_URL}` + uri, options)
        .then((data) => data);
};

const responseSuccessHandler = response => {
    return response;
};

const responseErrorHandler = error => {

    if (error.response.status === 401) {
        logoutService();
        window.location.reload();
    }

    return Promise.reject(error);
};

axios.interceptors.response.use(
    response => responseSuccessHandler(response),
    error => responseErrorHandler(error)
);


export const httpWrapper = {
    get,
    post,
    update,
    delete: _delete
};
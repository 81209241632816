import {push} from 'connected-react-router';

import {
    addWorkflowApprover,
    addWorkflowRule, approveWorkflowTransaction,
    deleteWorkflowApprover,
    deleteWorkflowRule,
    editWorkflowApprover,
    editWorkflowRule, getWorkflowApprover,
    getWorkflowApprovers,
    getWorkflowProcesses,
    getWorkflowRules, getWorkflowTransactions
} from "../../services/WorkflowService";

import {
    workflowConstants
} from "../ActionTypes";

import {alertActions} from "./AlertAction";


export const getWorkflowProcessesAction = () => {
    return (dispatch) => {
        dispatch({
            type: workflowConstants.PENDING_WORKFLOW_REQUEST
        });
        return getWorkflowProcesses().then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: workflowConstants.GET_PROCESSES_SUCCESS,
                        payload: response.data.results
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: workflowConstants.GET_PROCESSES_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};

export const getWorkflowRulesAction = () => {
    return (dispatch) => {
        dispatch({
            type: workflowConstants.PENDING_WORKFLOW_REQUEST
        });
        return getWorkflowRules().then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: workflowConstants.GET_RULES_SUCCESS,
                        payload: response.data.results
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: workflowConstants.GET_RULES_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};


export const addWorkflowRuleAction = (rule, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: workflowConstants.PENDING_WORKFLOW_REQUEST
        });
        return addWorkflowRule(rule).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: workflowConstants.ADD_RULE_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/workflow'));
                    dispatch(alertActions.success('Rule added successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: workflowConstants.ADD_RULE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback(error.response.data);
                }
            }
        );
    };
};


export const editWorkflowRuleAction = (id, rule, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: workflowConstants.PENDING_WORKFLOW_REQUEST
        });
        return editWorkflowRule(id, rule).then(
            (response) => {
                if (response.data) {

                    dispatch({
                        type: workflowConstants.EDIT_RULE_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/workflow'));
                    dispatch(alertActions.success('Rule edited successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: workflowConstants.EDIT_RULE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback(error.response.data);
                }
            }
        );
    };
};

export const deleteWorkflowRuleAction = (id) => {
    return (dispatch) => {
        dispatch({
            type: workflowConstants.PENDING_WORKFLOW_REQUEST
        });
        return deleteWorkflowRule(id).then(
            (response) => {
                if (response) {
                    dispatch({
                        type: workflowConstants.DELETE_RULE_SUCCESS,
                        payload: id
                    });
                    dispatch(push('/workflow'));
                    dispatch(alertActions.success('Rule deleted successful'));
                }
            },
            (error) => {
                if (error.response) {

                    dispatch({
                        type: workflowConstants.DELETE_RULE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};


export const getWorkflowApproversAction = () => {
    return (dispatch) => {
        dispatch({
            type: workflowConstants.PENDING_WORKFLOW_REQUEST
        });
        return getWorkflowApprovers().then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: workflowConstants.GET_APPROVERS_SUCCESS,
                        payload: response.data.results
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: workflowConstants.GET_APPROVERS_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};

export const getWorkflowApproverAction = (id) => {
    return (dispatch) => {
        dispatch({
            type: workflowConstants.PENDING_WORKFLOW_REQUEST
        });
        return getWorkflowApprover(id).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: workflowConstants.GET_APPROVER_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: workflowConstants.GET_APPROVER_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};


export const addWorkflowApproverAction = (approver, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: workflowConstants.PENDING_WORKFLOW_REQUEST
        });
        return addWorkflowApprover(approver).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: workflowConstants.ADD_APPROVER_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/workflow'));
                    dispatch(alertActions.success('Approver added successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: workflowConstants.ADD_APPROVER_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback(error.response.data);
                }
            }
        );
    };
};


export const editWorkflowApproverAction = (id, approver, errorCallback) => {
    return (dispatch) => {

        dispatch({
            type: workflowConstants.PENDING_WORKFLOW_REQUEST
        });
        return editWorkflowApprover(id, approver).then(
            (response) => {
                if (response.data) {

                    dispatch({
                        type: workflowConstants.EDIT_APPROVER_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/workflow'));
                    dispatch(alertActions.success('Approver edited successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: workflowConstants.EDIT_APPROVER_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback(error.response.data);
                }
            }
        );
    };
};

export const deleteWorkflowApproverAction = (id) => {

    return (dispatch) => {

        dispatch({
            type: workflowConstants.PENDING_WORKFLOW_REQUEST
        });
        return deleteWorkflowApprover(id).then(
            (response) => {
                if (response) {
                    dispatch({
                        type: workflowConstants.DELETE_APPROVER_SUCCESS,
                        payload: id
                    });
                    dispatch(push('/workflow'));
                    dispatch(alertActions.success('Approver deleted successful'));
                }
            },
            (error) => {
                if (error.response) {

                    dispatch({
                        type: workflowConstants.DELETE_APPROVER_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};


export const getWorkflowTransactionsAction = (data, cb) => {

    return (dispatch) => {

        dispatch({
            type: workflowConstants.PENDING_WORKFLOW_REQUEST
        });
        return getWorkflowTransactions(data).then(
            (response) => {
                if (response.data) {
                    dispatch({
                            type: workflowConstants.GET_TRANSACTIONS_SUCCESS,
                            payload: response.data
                        },
                    );
                    cb && cb();
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: workflowConstants.GET_TRANSACTIONS_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    cb && cb();
                }
            }
        );
    };
};

export const approveWorkflowTransactionAction = (data, errorCallback) => {

    return (dispatch) => {

        dispatch({
            type: workflowConstants.PENDING_WORKFLOW_REQUEST
        });
        return approveWorkflowTransaction(data).then(
            (response) => {
                if (response.data) {
                    dispatch(push('/workflow/pending-approvals'));
                    dispatch(alertActions.success('Transaction approved successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};
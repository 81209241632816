import {
    loginService,
    logoutService
} from "../../services/AuthService";

import {authConstants} from "../ActionTypes";

export const loginAction = (data) => {

    return (dispatch) => {
        dispatch({
            type: authConstants.LOGIN_REQUEST
        });

        return loginService(data).then(
            (response) => {
                if (response.data) {

                    dispatch({
                        type: authConstants.LOGIN_SUCCESS,
                        payload: response.data
                    });


                }
            },
            (error) => {

                const message = (error.response && error.response.data && error.response.data.message) ||
                    error.message || error.toString();

                dispatch({
                    type: authConstants.LOGIN_FAIL,
                    payload: message
                });

                return Promise.reject();
            }
        );
    };

};

export const logoutAction = () => {

    return (dispatch) => {
        dispatch({
            type: authConstants.LOGOUT,
        });
        logoutService();
    };
};

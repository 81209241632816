export const GET_SUPPLIERS_SUCCESS = "GET_SUPPLIERS_SUCCESS";
export const GET_SUPPLIERS_ERROR = "GET_SUPPLIERS_ERROR";
export const ADD_SUPPLIER_SUCCESS = "ADD_SUPPLIER_SUCCESS";
export const ADD_SUPPLIER_ERROR = "ADD_SUPPLIER_ERROR";
export const EDIT_SUPPLIER_SUCCESS = "EDIT_SUPPLIER_SUCCESS";
export const EDIT_SUPPLIER_ERROR = "EDIT_SUPPLIER_ERROR";
export const DELETE_SUPPLIER_SUCCESS = "DELETE_SUPPLIER_SUCCESS";
export const DELETE_SUPPLIER_ERROR = "DELETE_SUPPLIER_ERROR";
export const PENDING_SUPPLIER_REQUEST = "PENDING_SUPPLIER_REQUEST";

export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_ERROR = "GET_CUSTOMERS_ERROR";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_ERROR = "ADD_CUSTOMER_ERROR";
export const EDIT_CUSTOMER_SUCCESS = "EDIT_CUSTOMER_SUCCESS";
export const EDIT_CUSTOMER_ERROR = "EDIT_CUSTOMER_ERROR";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_ERROR = "DELETE_CUSTOMER_ERROR";
export const PENDING_CUSTOMER_REQUEST = "PENDING_CUSTOMER_REQUEST";

export const alertConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR'
};

export const apiConstants = {
    REDIRECT: 'API_REDIRECT',
    SUCCESS: 'API_SUCCESS',
    CLEAR: 'API_CLEAR_REDIRECT'
};

export const machineConstants = {
    GET_MACHINES_SUCCESS: "GET_MACHINES_SUCCESS",
    GET_MACHINES_ERROR: "GET_MACHINES_ERROR",
    ADD_MACHINE_SUCCESS: "ADD_MACHINE_SUCCESS",
    ADD_MACHINE_ERROR: "ADD_MACHINE_ERROR",
    EDIT_MACHINE_SUCCESS: "EDIT_MACHINE_SUCCESS",
    EDIT_MACHINE_ERROR: "EDIT_MACHINE_ERROR",
    DELETE_MACHINE_SUCCESS: "DELETE_MACHINE_SUCCESS",
    DELETE_MACHINE_ERROR: "DELETE_MACHINE_ERROR",
    PENDING_MACHINE_REQUEST: "PENDING_MACHINE_REQUEST"
};

export const tripConstants = {
    GET_TRIPS_SUCCESS: "GET_TRIPS_SUCCESS",
    GET_TRIPS_ERROR: "GET_TRIPS_ERROR",
    GET_TRIP_SUCCESS: "GET_TRIP_SUCCESS",
    GET_TRIP_ERROR: "GET_TRIP_ERROR",
    ADD_TRIP_SUCCESS: "ADD_TRIP_SUCCESS",
    ADD_TRIP_ERROR: "ADD_TRIP_ERROR",
    EDIT_TRIP_SUCCESS: "EDIT_TRIP_SUCCESS",
    EDIT_TRIP_ERROR: "EDIT_TRIP_ERROR",
    DELETE_TRIP_SUCCESS: "DELETE_TRIP_SUCCESS",
    DELETE_TRIP_ERROR: "DELETE_TRIP_ERROR",
    PENDING_TRIP_REQUEST: "PENDING_TRIP_REQUEST"
};


export const settingConstants = {
    GET_SETTINGS_SUCCESS: "GET_SETTINGS_SUCCESS",
    GET_SETTINGS_ERROR: "GET_SETTINGS_ERROR",
    ADD_SETTING_SUCCESS: "ADD_SETTING_SUCCESS",
    ADD_SETTING_ERROR: "ADD_SETTING_ERROR",
    EDIT_SETTING_SUCCESS: "EDIT_SETTING_SUCCESS",
    EDIT_SETTING_ERROR: "EDIT_SETTING_ERROR",
    DELETE_SETTING_SUCCESS: "DELETE_SETTING_SUCCESS",
    DELETE_SETTING_ERROR: "DELETE_SETTING_ERROR",
    PENDING_SETTING_REQUEST: "PENDING_SETTING_REQUEST"
};

export const configurationConstants = {
    GET_CONFIGURATIONS_SUCCESS: "GET_CONFIGURATIONS_SUCCESS",
    GET_CONFIGURATIONS_ERROR: "GET_CONFIGURATIONS_ERROR",
    ADD_CONFIGURATION_SUCCESS: "ADD_CONFIGURATION_SUCCESS",
    ADD_CONFIGURATION_ERROR: "ADD_CONFIGURATION_ERROR",
    EDIT_CONFIGURATION_SUCCESS: "EDIT_CONFIGURATION_SUCCESS",
    EDIT_CONFIGURATION_ERROR: "EDIT_CONFIGURATION_ERROR",
    DELETE_CONFIGURATION_SUCCESS: "DELETE_CONFIGURATION_SUCCESS",
    DELETE_CONFIGURATION_ERROR: "DELETE_CONFIGURATION_ERROR",
    PENDING_CONFIGURATION_REQUEST: "PENDING_CONFIGURATION_REQUEST"
};

export const employeeConstants = {
    GET_EMPLOYEES_SUCCESS: "GET_EMPLOYEES_SUCCESS",
    GET_EMPLOYEES_ERROR: "GET_EMPLOYEES_ERROR",
    ADD_EMPLOYEE_SUCCESS: "ADD_EMPLOYEE_SUCCESS",
    ADD_EMPLOYEE_ERROR: "ADD_EMPLOYEE_ERROR",
    EDIT_EMPLOYEE_SUCCESS: "EDIT_EMPLOYEE_SUCCESS",
    EDIT_EMPLOYEE_ERROR: "EDIT_EMPLOYEE_ERROR",
    DELETE_EMPLOYEE_SUCCESS: "DELETE_EMPLOYEE_SUCCESS",
    DELETE_EMPLOYEE_ERROR: "DELETE_EMPLOYEE_ERROR",
    PENDING_EMPLOYEE_REQUEST: "PENDING_EMPLOYEE_REQUEST"
};

export const productConstants = {
    GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
    GET_PRODUCTS_ERROR: "GET_PRODUCTS_ERROR",
    ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_SUCCESS",
    ADD_PRODUCT_ERROR: "ADD_PRODUCT_ERROR",
    EDIT_PRODUCT_SUCCESS: "EDIT_PRODUCT_SUCCESS",
    EDIT_PRODUCT_ERROR: "EDIT_PRODUCT_ERROR",
    DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
    DELETE_PRODUCT_ERROR: "DELETE_PRODUCT_ERROR",
    PENDING_PRODUCT_REQUEST: "PENDING_PRODUCT_REQUEST"
};

export const expenseConstants = {
    GET_EXPENSES_SUCCESS: "GET_EXPENSES_SUCCESS",
    GET_EXPENSES_ERROR: "GET_EXPENSES_ERROR",
    GET_EXPENSE_SUCCESS: "GET_EXPENSE_SUCCESS",
    GET_EXPENSE_ERROR: "GET_EXPENSE_ERROR",
    ADD_EXPENSE_SUCCESS: "ADD_EXPENSE_SUCCESS",
    ADD_EXPENSE_ERROR: "ADD_EXPENSE_ERROR",
    EDIT_EXPENSE_SUCCESS: "EDIT_EXPENSE_SUCCESS",
    EDIT_EXPENSE_ERROR: "EDIT_EXPENSE_ERROR",
    DELETE_EXPENSE_SUCCESS: "DELETE_EXPENSE_SUCCESS",
    DELETE_EXPENSE_ERROR: "DELETE_EXPENSE_ERROR",
    PENDING_EXPENSE_REQUEST: "PENDING_EXPENSE_REQUEST"
};

export const saleConstants = {
    GET_SALES_SUCCESS: "GET_SALES_SUCCESS",
    GET_SALES_ERROR: "GET_SALES_ERROR",
    GET_SALE_SUCCESS: "GET_SALE_SUCCESS",
    GET_SALE_ERROR: "GET_SALE_ERROR",
    ADD_SALE_SUCCESS: "ADD_SALE_SUCCESS",
    ADD_SALE_ERROR: "ADD_SALE_ERROR",
    EDIT_SALE_SUCCESS: "EDIT_SALE_SUCCESS",
    EDIT_SALE_ERROR: "EDIT_SALE_ERROR",
    DELETE_SALE_SUCCESS: "DELETE_SALE_SUCCESS",
    DELETE_SALE_ERROR: "DELETE_SALE_ERROR",
    PENDING_SALE_REQUEST: "PENDING_SALE_REQUEST"
};


export const serviceConstants = {
    GET_SERVICES_SUCCESS: "GET_SERVICES_SUCCESS",
    GET_SERVICES_ERROR: "GET_SERVICES_ERROR",
    GET_SERVICE_SUCCESS: "GET_SERVICE_SUCCESS",
    GET_SERVICE_ERROR: "GET_SERVICE_ERROR",
    ADD_SERVICE_SUCCESS: "ADD_SERVICE_SUCCESS",
    ADD_SERVICE_ERROR: "ADD_SERVICE_ERROR",
    EDIT_SERVICE_SUCCESS: "EDIT_SERVICE_SUCCESS",
    EDIT_SERVICE_ERROR: "EDIT_SERVICE_ERROR",
    DELETE_SERVICE_SUCCESS: "DELETE_SERVICE_SUCCESS",
    DELETE_SERVICE_ERROR: "DELETE_SERVICE_ERROR",
    PENDING_SERVICE_REQUEST: "PENDING_SERVICE_REQUEST"
};

export const routeConstants = {
    GET_ROUTES_SUCCESS: "GET_ROUTES_SUCCESS",
    GET_ROUTES_ERROR: "GET_ROUTES_ERROR",
    GET_ROUTE_SUCCESS: "GET_ROUTE_SUCCESS",
    GET_ROUTE_ERROR: "GET_ROUTE_ERROR",
    ADD_ROUTE_SUCCESS: "ADD_ROUTE_SUCCESS",
    ADD_ROUTE_ERROR: "ADD_ROUTE_ERROR",
    EDIT_ROUTE_SUCCESS: "EDIT_ROUTE_SUCCESS",
    EDIT_ROUTE_ERROR: "EDIT_ROUTE_ERROR",
    DELETE_ROUTE_SUCCESS: "DELETE_ROUTE_SUCCESS",
    DELETE_ROUTE_ERROR: "DELETE_ROUTE_ERROR",
    PENDING_ROUTE_REQUEST: "PENDING_ROUTE_REQUEST"
};

export const userConstants = {
    GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
    GET_USERS_ERROR: "GET_USERS_ERROR",
    ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
    ADD_USER_ERROR: "ADD_USER_ERROR",
    EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
    EDIT_USER_ERROR: "EDIT_USER_ERROR",
    DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
    DELETE_USER_ERROR: "DELETE_USER_ERROR",
    CHANGE_USER_PASSWORD_SUCCESS: "CHANGE_USER_PASSWORD_SUCCESS",
    CHANGE_USER_PASSWORD_ERROR: "CHANGE_USER_PASSWORD_ERROR",
    PENDING_USER_REQUEST: "PENDING_USER_REQUEST"
};


export const workflowConstants = {
    GET_PROCESSES_SUCCESS: "GET_PROCESSES_SUCCESS",
    GET_PROCESSES_ERROR: "GET_PROCESSES_ERROR",
    GET_RULES_SUCCESS: "GET_RULES_SUCCESS",
    GET_RULES_ERROR: "GET_RULES_ERROR",
    GET_RULE_SUCCESS: "GET_RULE_SUCCESS",
    ADD_RULE_SUCCESS: "ADD_RULE_SUCCESS",
    EDIT_RULE_SUCCESS: "EDIT_RULE_SUCCESS",
    DELETE_RULE_SUCCESS: "DELETE_RULE_SUCCESS",
    GET_RULE_ERROR: "GET_RULE_ERROR",
    ADD_RULE_ERROR: "ADD_RULE_ERROR",
    EDIT_RULE_ERROR: "EDIT_RULE_ERROR",
    DELETE_RULE_ERROR: "DELETE_RULE_ERROR",
    GET_APPROVERS_SUCCESS: "GET_APPROVERS_SUCCESS",
    GET_APPROVERS_ERROR: "GET_APPROVERS_ERROR",
    GET_APPROVER_SUCCESS: "GET_APPROVER_SUCCESS",
    GET_APPROVER_ERROR: "GET_APPROVER_ERROR",
    ADD_APPROVER_SUCCESS: "ADD_APPROVER_SUCCESS",
    ADD_APPROVER_ERROR: "ADD_APPROVER_ERROR",
    EDIT_APPROVER_SUCCESS: "EDIT_APPROVER_SUCCESS",
    EDIT_APPROVER_ERROR: "EDIT_APPROVER_ERROR",
    DELETE_APPROVER_SUCCESS: "DELETE_APPROVER_SUCCESS",
    DELETE_APPROVER_ERROR: "DELETE_APPROVER_ERROR",
    GET_TRANSACTIONS_SUCCESS: "GET_TRANSACTIONS_SUCCESS",
    GET_TRANSACTIONS_ERROR: "GET_TRANSACTIONS_ERROR",
    PENDING_WORKFLOW_REQUEST: "PENDING_WORKFLOW_REQUEST"
};


export const purchaseConstants = {
    GET_PURCHASES_SUCCESS: "GET_PURCHASES_SUCCESS",
    GET_PURCHASES_ERROR: "GET_PURCHASES_ERROR",
    GET_PURCHASE_SUCCESS: "GET_PURCHASE_SUCCESS",
    GET_PURCHASE_ERROR: "GET_PURCHASE_ERROR",
    ADD_PURCHASE_SUCCESS: "ADD_PURCHASE_SUCCESS",
    ADD_PURCHASE_ERROR: "ADD_PURCHASE_ERROR",
    EDIT_PURCHASE_SUCCESS: "EDIT_PURCHASE_SUCCESS",
    EDIT_PURCHASE_ERROR: "EDIT_PURCHASE_ERROR",
    DELETE_PURCHASE_SUCCESS: "DELETE_PURCHASE_SUCCESS",
    DELETE_PURCHASE_ERROR: "DELETE_PURCHASE_ERROR",
    PENDING_PURCHASE_REQUEST: "PENDING_PURCHASE_REQUEST"
};

export const authConstants = {
     LOGIN_REQUEST: "LOGIN_REQUEST",
     LOGIN_SUCCESS : "LOGIN_SUCCESS",
     LOGIN_FAIL : "LOGIN_FAIL",
     LOGOUT : "LOGOUT"
};

export const stockAdjustConstants = {
    GET_ADJUSTMENTS_SUCCESS: "GET_ADJUSTMENTS_SUCCESS",
    GET_ADJUSTMENTS_ERROR: "GET_ADJUSTMENTS_ERROR",
    ADD_ADJUSTMENT_SUCCESS: "ADD_ADJUSTMENT_SUCCESS",
    ADD_ADJUSTMENT_ERROR: "ADD_ADJUSTMENT_ERROR",
    PENDING_ADJUSTMENT_REQUEST: "PENDING_ADJUSTMENT_REQUEST"
};


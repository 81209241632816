import { push } from 'connected-react-router';

import {
    getCustomers,
    addCustomer,
    editCustomer,
    deleteCustomer
} from "../../services/CustomerService";


import {
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMERS_ERROR,
    ADD_CUSTOMER_SUCCESS,
    ADD_CUSTOMER_ERROR,
    EDIT_CUSTOMER_SUCCESS,
    EDIT_CUSTOMER_ERROR,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_ERROR,
    PENDING_CUSTOMER_REQUEST
} from "../ActionTypes";

import {alertActions} from "./AlertAction";

export const getCustomersAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: PENDING_CUSTOMER_REQUEST
        });
        return getCustomers(data).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: GET_CUSTOMERS_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: GET_CUSTOMERS_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};



export const addCustomerAction = (customer, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: PENDING_CUSTOMER_REQUEST
        });
        return addCustomer(customer).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: ADD_CUSTOMER_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/customers'));
                    dispatch(alertActions.success('Customer added successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: ADD_CUSTOMER_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};


export const editCustomerAction = (id, customer, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: PENDING_CUSTOMER_REQUEST
        });
        return editCustomer(id, customer).then(
            (response) => {
                if (response.data) {

                    dispatch({
                        type: EDIT_CUSTOMER_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/customers'));
                    dispatch(alertActions.success('Customer edited successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: EDIT_CUSTOMER_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};

export const deleteCustomerAction = (id) => {
    return (dispatch) => {
        dispatch({
            type: PENDING_CUSTOMER_REQUEST
        });
        return deleteCustomer(id).then(
            (response) => {
                if (response) {
                    dispatch({
                        type: DELETE_CUSTOMER_SUCCESS,
                        payload: id
                    });
                    dispatch(push('/customers'));
                    dispatch(alertActions.success('Customer deleted successful'));
                }
            },
            (error) => {
                if (error.response) {

                    dispatch({
                        type: DELETE_CUSTOMER_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};
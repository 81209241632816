import { push } from 'connected-react-router';

import {
    getExpenses,
    getExpense,
    addExpense,
    editExpense,
    deleteExpense
} from "../../services/ExpenseService";


import {expenseConstants} from "../ActionTypes";

import {alertActions} from "./AlertAction";



export const getExpensesAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: expenseConstants.PENDING_EXPENSE_REQUEST
        });
        return getExpenses(data).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: expenseConstants.GET_EXPENSES_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: expenseConstants.GET_EXPENSES_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};

export const getExpenseAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: expenseConstants.PENDING_EXPENSE_REQUEST
        });
        return getExpense(data).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: expenseConstants.GET_EXPENSE_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: expenseConstants.GET_EXPENSE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};


export const addExpenseAction = (expense, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: expenseConstants.PENDING_EXPENSE_REQUEST
        });
        return addExpense(expense).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: expenseConstants.ADD_EXPENSE_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/expenses/' + response.data.id));
                    dispatch(alertActions.success('Expense added successfully'));

                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: expenseConstants.ADD_EXPENSE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};


export const editExpenseAction = (id, expense, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: expenseConstants.PENDING_EXPENSE_REQUEST
        });
        return editExpense(id, expense).then(
            (response) => {
                if (response.data) {

                    dispatch({
                        type: expenseConstants.EDIT_EXPENSE_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/expenses/' + id));
                    dispatch(alertActions.success('Expense edited successfully'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: expenseConstants.EDIT_EXPENSE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};

export const deleteExpenseAction = (id) => {
    return (dispatch) => {
        dispatch({
            type: expenseConstants.PENDING_EXPENSE_REQUEST
        });
        return deleteExpense(id).then(
            (response) => {
                if (response) {
                    dispatch({
                        type: expenseConstants.DELETE_EXPENSE_SUCCESS,
                        payload: id
                    });
                    dispatch(push('/expenses'));
                    dispatch(alertActions.success('Expense deleted successfully'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: expenseConstants.DELETE_EXPENSE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};
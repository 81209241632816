import React, {useEffect, useRef} from 'react';
import Grid from "@material-ui/core/Grid";
import BreadCrumb from "../../components/BreadCrumb";


import {Link} from 'react-router-dom';
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import Box from "@material-ui/core/Box";
import useStyles from "../../styles";
import {useDispatch, useSelector} from "react-redux";
import EnhancedTable from "../../components/EnhancedTable";
import SiteWrapper from "../../SiteWrapper";
import {deleteSettingAction, getSettingsAction} from "../../store/actions/SettingAction";
import StatusComponent from "../../components/StatusComponent";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import MenuActionComponent from "../../components/MenuActionComponent";
import {utils} from "../../helper/Utils";


const SettingsPage = () => {

    const classes = useStyles();

    const dispatch = useDispatch();
    const settings = useSelector(state => state.setting.settings);

    const count = useSelector(state => state.setting.count);

    const fetchIdRef = useRef(0);

    const fetchData = React.useCallback((pager, query) => {

        let search = Object.assign({}, {limit: pager.pageSize, offset: pager.pageIndex * pager.pageSize}, query);

        const fetchId = ++fetchIdRef.current;

        if (fetchId === fetchIdRef.current) {
            dispatch(getSettingsAction(search));
        }

    }, [dispatch]);


    useEffect(() => {
        fetchData({pageIndex: utils.DEFAULT_PAGE_INDEX, pageSize: utils.DEFAULT_PAGE_SIZE});
    }, [fetchData]);
    const columns = [

        {
            Header: "Type",
            accessor: d => (d.configuration.name ?? '-')
        },
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Description",
            accessor: d => (d.description ?? '-')
        },
        {
            Header: "Status",
            accessor: d => <StatusComponent status={d.status}/>
        },
        {
            Header: 'Action',
            Cell: row => (<MenuActionComponent item={{id: row.row.original.id, showView: false, hideEdit: !row.row.original.can_edit}} url={"settings"}
                                           deleteAction={deleteSettingAction}/>)
        }
    ];

    return (
        <>
            <SiteWrapper>
                <BreadCrumb crumbs={[{
                    'name': 'Settings',
                    'link': '#'
                }]}/>


                <Grid container justify="flex-end">

                    <Grid container className={classes.marginTop}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <Typography className={clsx(classes.medium, classes.pageTitle)}>
                                    Settings
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid container item xs={12} sm={8} justify="flex-end">
                            <Box>
                                <Button className={classes.actionButton} startIcon={<AddIcon/>}>
                                    <Link to="/settings/create" className={classes.unstyledLink}>New Setting</Link>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                    {/* Recent Deposits */}
                    <Grid item xs={12} md={12} lg={12}>
                            {settings && <EnhancedTable
                                columns={columns}
                                data={settings}
                                fetchData={fetchData}
                                pageCount={count && count}
                            />}
                    </Grid>
                </Grid>
            </SiteWrapper>
        </>
    );
};

export default SettingsPage;
import React from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {Link, withRouter} from 'react-router-dom';

import 'pace-js'
import 'pace-js/themes/blue/pace-theme-minimal.css'

import useStyles from "./styles";
import Header from "./components/Header/Header";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import AppsIcon from '@material-ui/icons/Apps';
import RefreshIcon from '@material-ui/icons/Refresh';


const AppBarComponent = () => {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const menuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const menuClose = () => {
        setAnchorEl(null);
    };

    const refreshApp = () => {
        window.location.reload(false);
    };

    return (
        <AppBar position="absolute" className={clsx(classes.appBar, classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                <Grid container spacing={2}>
                    <Grid item md={1}>
                        <div>
                            <IconButton
                                size="medium"
                                edge="start"
                                color="inherit"
                                aria-label="open Menu"
                                onClick={menuOpen}
                            >
                                <AppsIcon/>
                            </IconButton>
                            <Menu
                                id="master-menu"
                                anchorEl={anchorEl}
                                open={Boolean(openMenu)}
                                onClose={menuClose}
                                className={clsx(
                                    classes.mainMeu
                                )}
                            >
                                <Grid container spacing={1}>
                                    <Grid item md={3}>
                                        <MenuItem onClick={menuClose} className={clsx(
                                            classes.menuItem
                                        )}><Link className={classes.linkItem} to="/">Home</Link></MenuItem>
                                        <MenuItem onClick={menuClose} className={clsx(
                                            classes.menuItem
                                        )}><Link className={classes.linkItem}
                                                 to="/vehicles">Trucks</Link></MenuItem>
                                        <MenuItem onClick={menuClose} className={clsx(
                                            classes.menuItem
                                        )}><Link className={classes.linkItem} to="/parts">Stock</Link></MenuItem>
                                        <MenuItem onClick={menuClose} className={clsx(
                                            classes.menuItem
                                        )}><Link className={classes.linkItem}
                                                 to="/settings">Settings</Link></MenuItem>
                                    </Grid>
                                    <Grid item md={3}>
                                        <MenuItem onClick={menuClose} className={clsx(
                                            classes.menuItem
                                        )}><Link className={classes.linkItem}
                                                 to="/suppliers">Suppliers</Link></MenuItem>
                                        <MenuItem onClick={menuClose} className={clsx(
                                            classes.menuItem
                                        )}><Link className={classes.linkItem} to="/trips">Trips</Link></MenuItem>
                                        <MenuItem onClick={menuClose} className={clsx(
                                            classes.menuItem
                                        )}><Link className={classes.linkItem} to="/stock-adjustments">Adjust
                                            Stock</Link></MenuItem>
                                        <MenuItem onClick={menuClose} className={clsx(
                                            classes.menuItem
                                        )}><Link className={classes.linkItem}
                                                 to="/workflow">Workflow</Link></MenuItem>
                                    </Grid>
                                    <Grid item md={3}>
                                        <MenuItem onClick={menuClose} className={clsx(
                                            classes.menuItem
                                        )}><Link className={classes.linkItem}
                                                 to="/expenses">Expenses</Link></MenuItem>
                                        <MenuItem onClick={menuClose} className={clsx(
                                            classes.menuItem
                                        )}><Link className={classes.linkItem} to="/routes">Routes</Link></MenuItem>
                                        <MenuItem onClick={menuClose} className={clsx(
                                            classes.menuItem
                                        )}><Link className={classes.linkItem} to="/users">Users</Link></MenuItem>
                                        <MenuItem onClick={menuClose} className={clsx(
                                            classes.menuItem
                                        )}><Link className={classes.linkItem}
                                                 to="/reports">Reports</Link></MenuItem>
                                    </Grid>
                                    <Grid item md={3}>
                                        <MenuItem onClick={menuClose} className={clsx(
                                            classes.menuItem
                                        )}><Link className={classes.linkItem}
                                                 to="/purchases">Purchases</Link></MenuItem>
                                        <MenuItem onClick={menuClose} className={clsx(
                                            classes.menuItem
                                        )}><Link className={classes.linkItem}
                                                 to="/services">Services</Link></MenuItem>
                                        <MenuItem onClick={menuClose} className={clsx(
                                            classes.menuItem
                                        )}><Link className={classes.linkItem}
                                                 to="/employees">Employees</Link></MenuItem>
                                    </Grid>
                                </Grid>
                            </Menu>
                        </div>
                    </Grid>
                </Grid>
                <Grid item md={2}>
                    <Box display="flex" flexDirection="row-reverse">
                        <Header/>
                        <IconButton
                            size="medium"
                            edge="start"
                            aria-label="Refresh App"
                            onClick={refreshApp}
                        >
                            <RefreshIcon/>
                        </IconButton>
                    </Box>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default withRouter(AppBarComponent);
import {
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMERS_ERROR,
    ADD_CUSTOMER_SUCCESS,
    ADD_CUSTOMER_ERROR,
    EDIT_CUSTOMER_SUCCESS,
    EDIT_CUSTOMER_ERROR,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_ERROR,
    PENDING_CUSTOMER_REQUEST
} from "../ActionTypes";

const initialState = {
    customers: [],
    count: 0,
    customer:{},
    isLoading: false
};

function CustomerReducer(state = initialState, action) {

    switch (action.type) {
        case PENDING_CUSTOMER_REQUEST:
            return { ...state, isLoading: true };
        case GET_CUSTOMERS_SUCCESS:
            return { ...state, customers: action.payload.results, count: action.payload.count, isLoading: false };
        case GET_CUSTOMERS_ERROR:
            return {...state, isLoading: false};
        case ADD_CUSTOMER_SUCCESS:
            return { ...state, customers : state.customers.concat(action.payload),  isLoading: false };
        case ADD_CUSTOMER_ERROR:
            return {...state, isLoading: false};
        case EDIT_CUSTOMER_SUCCESS:
            return { ...state, customer : action.payload, isLoading: false };
        case EDIT_CUSTOMER_ERROR:
            return { ...state, isLoading: false };
        case DELETE_CUSTOMER_SUCCESS:
            return {...state, customers: state.customers.filter((customer) => customer.id !== action.payload), count: state.count - 1, isLoading: false };
        case DELETE_CUSTOMER_ERROR:
            return { ...state.customers, isLoading: false };
        default:
            return state;
    }
}

export default CustomerReducer;
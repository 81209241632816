import { push } from 'connected-react-router';

import {
    getMachines,
    addMachine,
    editMachine,
    deleteMachine
} from "../../services/MachineService";


import {machineConstants} from "../ActionTypes";

import {alertActions} from "./AlertAction";

export const getMachinesAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: machineConstants.PENDING_MACHINE_REQUEST
        });
        return getMachines(data).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: machineConstants.GET_MACHINES_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: machineConstants.GET_MACHINES_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};


export const addMachineAction = (machine, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: machineConstants.PENDING_MACHINE_REQUEST
        });
        return addMachine(machine).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: machineConstants.ADD_MACHINE_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/vehicles'));
                    dispatch(alertActions.success('Machine added successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: machineConstants.ADD_MACHINE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};


export const editMachineAction = (id, machine, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: machineConstants.PENDING_MACHINE_REQUEST
        });
        return editMachine(id, machine).then(
            (response) => {
                if (response.data) {

                    dispatch({
                        type: machineConstants.EDIT_MACHINE_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/vehicles/' + id + '/view'));
                    dispatch(alertActions.success('Machine edited successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: machineConstants.EDIT_MACHINE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};

export const deleteMachineAction = (id) => {
    return (dispatch) => {
        dispatch({
            type: machineConstants.PENDING_MACHINE_REQUEST
        });
        return deleteMachine(id).then(
            (response) => {
                if (response) {
                    dispatch({
                        type: machineConstants.DELETE_MACHINE_SUCCESS,
                        payload: id
                    });
                    dispatch(push('/vehicles'));
                    dispatch(alertActions.success('Machine deleted successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: machineConstants.DELETE_MACHINE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};
import React from 'react';

import SiteWrapper from "../../SiteWrapper";
import {useSelector} from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import useStyles from "../../styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import BreadCrumb from "../../components/BreadCrumb";
import MenuActionComponent from "../../components/MenuActionComponent";
import {deleteSaleAction} from "../../store/actions/SaleAction";
import dayjs from "dayjs";
import {utils} from "../../helper/Utils";

function SalePage({ match}) {

    const classes = useStyles();

    const {id} = match.params;

    const sales = useSelector(state => state.sale.sales);

    const sale = sales.find(sale => sale.id === parseInt(id));

    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[{
                'name': 'Sales',
                'link': '/sales'
            }]}/>
            <Grid container className={classes.marginTop}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography className={classes.medium}>
                            {sale && sale.invoice}
                        </Typography>
                    </Box>
                </Grid>
                <Grid container item xs={12} sm={8} justify="flex-end">
                    {sale &&
                    <MenuActionComponent item={{id: sale.id, showView: false}}
                                         url={"sales"}  deleteAction={deleteSaleAction}
                    />}
                </Grid>
            </Grid>

            <Grid container className={classes.marginTop}>
                <Grid item xs={12} sm={2}>
                    <Box mr={2}>
                        <Avatar variant="square" className={classes.sizeAvatar}>
                            {sale && sale.truck.reg_no}
                        </Avatar>
                    </Box>

                    {sale.created_by && <Box mt={2}>
                        <Typography variant="caption">
                            Created By
                        </Typography>
                        <Typography variant="body1">
                            {sale.created_by.full_name}
                        </Typography>
                    </Box>}

                    {sale.created_at && <Box mt={2}>
                        <Typography variant="caption">
                            Created At
                        </Typography>
                        <Typography variant="body1">
                            {dayjs(sale.created_at).format(utils.dateFormat)}
                        </Typography>
                    </Box>}
                </Grid>
                <Grid item xs={12} sm={10}>
                    <Paper className={classes.paper}>

                        {sale ?
                        <form className={classes.form} noValidate>

                            <Grid container spacing={4}>
                                <Grid item md="auto"><Typography variant="subtitle1">Sale
                                    Information</Typography></Grid>
                            </Grid>

                            <Grid container direction="row" spacing={4} align="left">
                                <Grid item md={3}><Typography variant="subtitle2">Date</Typography></Grid>
                                <Grid item md={6} align="left">{sale.effective_date ? dayjs(sale.effective_date).format(utils.dateFormat) : '-'}</Grid>
                            </Grid>

                            <Grid container direction="row" spacing={4} align="left">
                                <Grid item md={3}><Typography variant="subtitle2">Invoice</Typography></Grid>
                                <Grid item md={6} align="left">{sale.invoice ? sale.invoice : '-'}</Grid>
                            </Grid>

                            <Grid container direction="row" spacing={4} align="left">
                                <Grid item md={3}><Typography variant="subtitle2">Manual Invoice</Typography></Grid>
                                <Grid item md={6} align="left">{sale.invoice_no ? sale.invoice_no : '-'}</Grid>
                            </Grid>

                            <Grid container direction="row" spacing={4} align="left">
                                <Grid item md={3}><Typography variant="subtitle2">Customer</Typography></Grid>
                                <Grid item md={6} align="left">{sale.customer.name}</Grid>
                            </Grid>

                            <Grid container direction="row" spacing={4} align="left">
                                <Grid item md={3}><Typography variant="subtitle2">Trip</Typography></Grid>
                                <Grid item md={6} align="left">{sale.trip ? sale.trip.route.name : '-'}</Grid>
                            </Grid>

                            <Grid container direction="row" spacing={4} align="left">
                                <Grid item md={3}><Typography variant="subtitle2">Truck</Typography></Grid>
                                <Grid item md={6} align="left">{sale.truck ? sale.truck.reg_no : '-'}</Grid>
                            </Grid>

                            <Grid container direction="row" spacing={4} align="left">
                                <Grid item md={3}><Typography variant="subtitle2">Amount</Typography></Grid>
                                <Grid item md={6} align="left">{sale.amount ? utils.numberFormatter(sale.amount, 2) : 0.00}</Grid>
                            </Grid>

                            <Grid container direction="row" spacing={4} align="left">
                                <Grid item md={3}><Typography variant="subtitle2">Status</Typography></Grid>
                                <Grid item md={6} align="left">{sale.status ? 'Active': 'Inactive'}</Grid>
                            </Grid>
                        </form> :

                            <Grid container className={classes.marginTop}>
                                <Grid item xs={12} sm={4}>
                                    <Box>
                                        <Typography>
                                            Oops! Sale not found. View all sales
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        }
                    </Paper>
                </Grid>
            </Grid>
        </SiteWrapper>
    );
}

export default SalePage;

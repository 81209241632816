import React, {useEffect, useRef, useState} from 'react';
import Grid from "@material-ui/core/Grid";


import {withRouter} from 'react-router-dom';
import Box from "@material-ui/core/Box";
import useStyles from "../../styles";
import EnhancedTable from "../../components/EnhancedTable";
import SiteWrapper from "../../SiteWrapper";
import Typography from "@material-ui/core/Typography";
import {httpWrapper} from "../../helper/Http";
import {contentTypeApplicationJson} from "../../constants/httpConstants";
import ExportToPdfComponent from "../../components/ExportToPdfComponent";
import BreadCrumb from "../../components/BreadCrumb";


const StockItemsPage = () => {

    const classes = useStyles();

    const [items, setItems] = useState([]);
    const [count, setCount] = useState(0);

    const fetchIdRef = useRef(0);

    const url = '/reports/stock-list/';


    const fetchData = React.useCallback(() => {

        const fetchId = ++fetchIdRef.current;

        if (fetchId === fetchIdRef.current) {

            httpWrapper.get(url, contentTypeApplicationJson).then(
                (response) => {
                    if (response.data) {
                        setItems(response.data.results.products);
                        setCount(response.data.results.products.length);
                    }

                },
                (error) => {

                }
            );
        }
    }, []);

    useEffect(() => {

        fetchData();

    }, [fetchData]);


    const columns = [

        {
            Header: "Code",
            accessor: (d => d.code ?? '-')
        },
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Unit",
            accessor: "uom"
        }
    ];

    return (
        <>
            <SiteWrapper>
                <BreadCrumb crumbs={[{
                    'name': 'Reports',
                    'link': '/reports'
                },
                    {
                        'name': 'Stock Items',
                        'link': '#'
                    }]}/>
                <Grid container className={classes.marginTop}>
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <Typography className={classes.medium}>
                                Stock items
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid container justify="flex-end">
                        <Box>
                            {items && <ExportToPdfComponent url={url} />}
                        </Box>
                    </Grid>
                </Grid>

                <Grid container justify="flex-end" className={classes.marginTop}>
                    {/* Recent Deposits */}
                    <Grid item xs={12} md={12} lg={12}>
                            {items && <EnhancedTable
                                columns={columns}
                                data={items}
                                summaries={false}
                                headers={false}
                                pageCount={count}
                            />}
                    </Grid>
                </Grid>
            </SiteWrapper>
        </>
    );
};

export default withRouter(StockItemsPage);
import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {ConnectedRouter} from 'connected-react-router'
import {CssBaseline, MuiThemeProvider} from "@material-ui/core";
import PropTypes from 'prop-types';

import './App.css';

import Suppliers from "./pages/ap/SuppliersPage";
import CustomersPage from "./pages/customer/CustomersPage";
import Dashboard from "./pages/Dashboard";
import theme from "./theme";
import AddEditSupplierPage from "./pages/ap/AddEditSupplierPage";
import AddEditCustomerPage from "./pages/customer/AddEditCustomerPage";
import ExpensesPage from "./pages/expense/ExpensesPage";
import AddEditExpensePage from "./pages/expense/AddEditExpensePage";
import LoginPage from "./pages/LoginPage";
import PurchasesPage from "./pages/purchases/PurchasesPage";
import AddEditPurchasePage from "./pages/purchases/AddEditPurchasePage";
import VehiclesPage from "./pages/vehicle/VehiclesPage";
import AddEditVehiclePage from "./pages/vehicle/AddEditVehiclePage";
import RoutesPage from "./pages/Routes/RoutesPage";
import AddEditRoutesPage from "./pages/Routes/AddEditRoutesPage";
import TripsPage from "./pages/trip/TripsPage";
import AddEditTripPage from "./pages/trip/AddEditTripPage";
import ProductsPage from "./pages/product/ProductsPage";
import AddEditProductPage from "./pages/product/AddEditProductPage";
import EmployeesPage from "./pages/employee/EmployeesPage";
import AddEditEmployeePage from "./pages/employee/AddEditEmployeePage";
import ServicesPage from "./pages/service/ServicesPage";
import AddEditServicePage from "./pages/service/AddEditServicePage";
import UsersPage from "./pages/user/UsersPage";
import AddEditUserPage from "./pages/user/AddEditUserPage";
import ConfigurationsPage from "./pages/configuration/ConfigurationsPage";
import AddEditConfigurationPage from "./pages/configuration/AddEditConfigurationPage";
import SettingsPage from "./pages/setting/SettingsPage";
import AddEditSettingPage from "./pages/setting/AddEditSettingPage";
import WorkflowPage from "./pages/workflow/WorkflowPage";
import AddEditRulePage from "./pages/workflow/AddEditRulePage";
import AddEditApproverPage from "./pages/workflow/AddEditApproverPage";
import SupplierPage from "./pages/ap/SupplierPage";
import CustomerPage from "./pages/customer/CustomerPage";
import ReportPage from "./pages/report/ReportPage";
import ReportMasterPage from "./pages/report/ReportMasterPage";
import ServiceReportPage from "./pages/report/ServiceReportPage";
import TripReportPage from "./pages/report/TripReportPage";
import PendingApprovalsPage from "./pages/workflow/PendingApprovalsPage";
import ApprovePage from "./pages/workflow/ApprovePage";
import StockItemsPage from "./pages/report/StockItemsPage";
import StockBalancePage from "./pages/report/StockBalancePage";
import StockLedgerPage from "./pages/report/StockLedgerPage";
import PurchaseLedgerPage from "./pages/report/PurchaseLedgerPage";
import {PrivateRoute} from "./components/PrivateRoute";
import ExpensePage from "./pages/expense/ExpensePage";
import PurchasePage from "./pages/purchases/PurchasePage";
import ServicePage from "./pages/service/ServicePage";
import TripPage from "./pages/trip/TripPage";
import StockAdjustmentPage from "./pages/stockAdjustment/StockAdjustmentPage";
import AddStockAdjustmentPage from "./pages/stockAdjustment/AddStockAdjustmentPage";
import ConfirmDialogProvider from "./components/Dialog/ConfirmDialogProvider";
import ExpensesSummaryPage from "./pages/report/ExpensesSummaryPage";
import SalesPage from "./pages/sales/SalesPage";
import AddEditSalePage from "./pages/sales/AddEditSalePage";
import SalePage from "./pages/sales/SalePage";
import TransportSalesReportPage from "./pages/report/TransportSalesReportPage";

const App = ({history}) => {

    return (
        <React.StrictMode>
            <ConnectedRouter history={history}>
                <MuiThemeProvider theme={theme}>
                    <ConfirmDialogProvider>
                        <CssBaseline/>
                        <Switch>
                            <Route exact path="/login" component={LoginPage}/>
                            <PrivateRoute exact path="/" component={Dashboard}/>
                            <PrivateRoute exact path="/suppliers" component={Suppliers}/>
                            <PrivateRoute exact path="/suppliers/create" component={AddEditSupplierPage}/>
                            <PrivateRoute exact path="/suppliers/:id/edit" component={AddEditSupplierPage}/>
                            <PrivateRoute exact path="/suppliers/:id" component={SupplierPage}/>
                            <PrivateRoute exact path="/customers" component={CustomersPage}/>
                            <PrivateRoute exact path="/customers/create" component={AddEditCustomerPage}/>
                            <PrivateRoute exact path="/customers/:id/edit" component={AddEditCustomerPage}/>
                            <PrivateRoute exact path="/customers/:id" component={CustomerPage}/>
                            <PrivateRoute exact path="/sales" component={SalesPage}/>
                            <PrivateRoute exact path="/sales/create" component={AddEditSalePage}/>
                            <PrivateRoute exact path="/sales/:id/edit" component={AddEditSalePage}/>
                            <PrivateRoute exact path="/sales/:id" component={SalePage}/>
                            <PrivateRoute exact path="/expenses" component={ExpensesPage}/>
                            <PrivateRoute exact path="/expenses/create" component={AddEditExpensePage}/>
                            <PrivateRoute exact path="/expenses/:id/edit" component={AddEditExpensePage}/>
                            <PrivateRoute exact path="/expenses/:id" component={ExpensePage}/>
                            <PrivateRoute exact path="/purchases" component={PurchasesPage}/>
                            <PrivateRoute exact path="/purchases/create" component={AddEditPurchasePage}/>
                            <PrivateRoute exact path="/purchases/:id/edit" component={AddEditPurchasePage}/>
                            <PrivateRoute exact path="/purchases/:id" component={PurchasePage}/>
                            <PrivateRoute exact path="/vehicles" component={VehiclesPage}/>
                            <PrivateRoute exact path="/vehicles/create" component={AddEditVehiclePage}/>
                            <PrivateRoute exact path="/vehicles/:id/edit" component={AddEditVehiclePage}/>
                            <PrivateRoute exact path="/routes" component={RoutesPage}/>
                            <PrivateRoute exact path="/routes/create" component={AddEditRoutesPage}/>
                            <PrivateRoute exact path="/routes/:id/edit" component={AddEditRoutesPage}/>
                            <PrivateRoute exact path="/trips" component={TripsPage}/>
                            <PrivateRoute exact path="/trips/create" component={AddEditTripPage}/>
                            <PrivateRoute exact path="/trips/:id/edit" component={AddEditTripPage}/>
                            <PrivateRoute exact path="/trips/:id" component={TripPage}/>
                            <PrivateRoute exact path="/parts" component={ProductsPage}/>
                            <PrivateRoute exact path="/parts/create" component={AddEditProductPage}/>
                            <PrivateRoute exact path="/parts/:id/edit" component={AddEditProductPage}/>
                            <PrivateRoute exact path="/stock-adjustments" component={StockAdjustmentPage}/>
                            <PrivateRoute exact path="/stock-adjustments/create" component={AddStockAdjustmentPage}/>
                            <PrivateRoute exact path="/employees" component={EmployeesPage}/>
                            <PrivateRoute exact path="/employees/create" component={AddEditEmployeePage}/>
                            <PrivateRoute exact path="/employees/:id/edit" component={AddEditEmployeePage}/>
                            <PrivateRoute exact path="/services" component={ServicesPage}/>
                            <PrivateRoute exact path="/services/create" component={AddEditServicePage}/>
                            <PrivateRoute exact path="/services/:id/edit" component={AddEditServicePage}/>
                            <PrivateRoute exact path="/services/:id" component={ServicePage}/>
                            <PrivateRoute exact path="/users" component={UsersPage}/>
                            <PrivateRoute exact path="/users/create" component={AddEditUserPage}/>
                            <PrivateRoute exact path="/users/:id/edit" component={AddEditUserPage}/>
                            <PrivateRoute exact path="/configurations" component={ConfigurationsPage}/>
                            <PrivateRoute exact path="/configurations/create" component={AddEditConfigurationPage}/>
                            <PrivateRoute exact path="/configurations/:id/edit" component={AddEditConfigurationPage}/>
                            <PrivateRoute exact path="/settings" component={SettingsPage}/>
                            <PrivateRoute exact path="/settings/create" component={AddEditSettingPage}/>
                            <PrivateRoute exact path="/settings/:id/edit" component={AddEditSettingPage}/>
                            <PrivateRoute exact path="/workflow/rules/create" component={AddEditRulePage}/>
                            <PrivateRoute exact path="/workflow/rules/:id/edit" component={AddEditRulePage}/>
                            <PrivateRoute exact path="/workflow/approvers/create" component={AddEditApproverPage}/>
                            <PrivateRoute exact path="/workflow/approvers/:id/edit" component={AddEditApproverPage}/>
                            <PrivateRoute exact path="/workflow/pending-approvals" component={PendingApprovalsPage}/>
                            <PrivateRoute exact path="/workflow/transactions/:id" component={ApprovePage}/>
                            <PrivateRoute exact path="/workflow" component={WorkflowPage}/>
                            <PrivateRoute exact path="/reports" component={ReportPage}/>
                            <PrivateRoute exact path="/query-reports/master-report" component={ReportMasterPage}/>
                            <PrivateRoute exact path="/query-reports/service-history" component={ServiceReportPage}/>
                            <PrivateRoute exact path="/query-reports/trips" component={TripReportPage}/>
                            <PrivateRoute exact path="/query-reports/stock-items" component={StockItemsPage}/>
                            <PrivateRoute exact path="/query-reports/stock-balance" component={StockBalancePage}/>
                            <PrivateRoute exact path="/query-reports/stock-ledger" component={StockLedgerPage}/>
                            <PrivateRoute exact path="/query-reports/purchases-ledger" component={PurchaseLedgerPage}/>
                            <PrivateRoute exact path="/query-reports/expenses-summary" component={ExpensesSummaryPage}/>
                            <PrivateRoute exact path="/query-reports/transport-sales" component={TransportSalesReportPage}/>
                            <Redirect from="*" to="/"/>
                        </Switch>
                    </ConfirmDialogProvider>
                </MuiThemeProvider>
            </ConnectedRouter>
        </React.StrictMode>
    );
};

App.propTypes = {
    history: PropTypes.object,
};

export default App;

import {push} from 'connected-react-router';

import {
    getSettings,
    addSetting,
    editSetting,
    deleteSetting
} from "../../services/SettingService";


import {settingConstants} from "../ActionTypes";

import {alertActions} from "./AlertAction";

export const getSettingsAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: settingConstants.PENDING_SETTING_REQUEST
        });
        return getSettings(data).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: settingConstants.GET_SETTINGS_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: settingConstants.GET_SETTINGS_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};


export const addSettingAction = (setting, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: settingConstants.PENDING_SETTING_REQUEST
        });
        return addSetting(setting).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: settingConstants.ADD_SETTING_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/settings'));
                    dispatch(alertActions.success('Setting added successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: settingConstants.ADD_SETTING_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};


export const editSettingAction = (id, setting, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: settingConstants.PENDING_SETTING_REQUEST
        });
        return editSetting(id, setting).then(
            (response) => {
                if (response.data) {

                    dispatch({
                        type: settingConstants.EDIT_SETTING_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/settings/' + id + '/view'));
                    dispatch(alertActions.success('Setting edited successful'));

                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: settingConstants.EDIT_SETTING_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};

export const deleteSettingAction = (id) => {
    return (dispatch) => {
        dispatch({
            type: settingConstants.PENDING_SETTING_REQUEST
        });
        return deleteSetting(id).then(
            (response) => {
                if (response) {
                    dispatch({
                        type: settingConstants.DELETE_SETTING_SUCCESS,
                        payload: id
                    });
                    dispatch(push('/settings'));
                    dispatch(alertActions.success('Setting deleted successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: settingConstants.DELETE_SETTING_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};
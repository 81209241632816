import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useStyles from "../styles";
import clsx from 'clsx';
import {httpService} from "../services/HttpService";

export default function CountStats() {

    const classes = useStyles();


    const [topCount, setTopCount] = useState([]);


    useEffect(() => {

        httpService.get('/reports/count-stats/').then(
            (response) => {
                if (response.data) {
                    setTopCount(response.data.results)
                }
            },
            (error) => {

            }
        );

    }, []);

    return (
        <>

            <Grid container justify="flex-end" className={classes.countStatsContainer}>

                <Grid item xs={6} md={3} lg={3}>
                    <Box align="center">
                        <Typography
                            className={clsx(classes.totalCount, classes.textPrimary)}>{topCount && topCount.horse}</Typography>
                        <Typography className={classes.totalName}>Trucks</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                    <Box align="center">
                        <Typography
                            className={clsx(classes.totalCount, classes.textSuccess)}>{topCount && topCount.trailers}</Typography>
                        <Typography className={classes.totalName}>Trailers</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                    <Box align="center">
                        <Typography
                            className={clsx(classes.totalCount, classes.textMaroon)}>{topCount && topCount.employees}</Typography>
                        <Typography className={classes.totalName}>Employees</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                    <Box align="center">
                        <Typography className={clsx(classes.totalCount)}>{topCount && topCount.trips}</Typography>
                        <Typography className={classes.totalName}>Trips</Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};
import {configurationConstants} from "../ActionTypes";

const initialState = {
    configurations: [],
    count: 0,
    configuration: {},
    isLoading: false
};

function ConfigurationReducer(state = initialState, action) {

    switch (action.type) {
        case configurationConstants.PENDING_CONFIGURATION_REQUEST:
            return {...state, isLoading: true};
        case configurationConstants.GET_CONFIGURATIONS_SUCCESS:
            return {...state, configurations: action.payload.results, count: action.payload.count, isLoading: false};
        case configurationConstants.GET_CONFIGURATIONS_ERROR:
            return {...state, isLoading: false};
        case configurationConstants.GET_CONFIGURATION_SUCCESS:
            return {...state, configuration: action.payload, isLoading: false};
        case configurationConstants.GET_CONFIGURATION_ERROR:
            return {...state, isLoading: false};
        case configurationConstants.ADD_CONFIGURATION_SUCCESS:
            return {...state, configurations: state.configurations.concat(action.payload), isLoading: false};
        case configurationConstants.ADD_CONFIGURATION_ERROR:
            return {...state, isLoading: false};
        case configurationConstants.EDIT_CONFIGURATION_SUCCESS:
            return {...state, configuration: action.payload, isLoading: false};
        case configurationConstants.EDIT_CONFIGURATION_ERROR:
            return {...state, isLoading: false};
        case configurationConstants.DELETE_CONFIGURATION_SUCCESS:
            return {
                ...state,
                configurations: state.configurations.filter((configuration) => configuration.id !== action.payload),
                count: state.count - 1,
                isLoading: false
            };
        case configurationConstants.DELETE_CONFIGURATION_ERROR:
            return {...state.configurations, isLoading: false};
        default:
            return state;
    }
}

export default ConfigurationReducer;
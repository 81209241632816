import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import {useDispatch, useSelector} from "react-redux";
import EnhancedTable from "../../components/EnhancedTable";
import dayjs from "dayjs";
import StatusComponent from "../../components/StatusComponent";
import {getWorkflowProcessesAction} from "../../store/actions/WorkflowAction";
import {utils} from "../../helper/Utils";


const ProcessesPage = () => {

    const dispatch = useDispatch();

    const processes = useSelector(state => state.workflow.processes);

    useEffect(() => {
        dispatch(getWorkflowProcessesAction());
    }, [dispatch]);


    const columns = [
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Code",
            accessor: "code_name"
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "Created By",
            accessor: d => (d.created_by ? d.created_by.full_name : '-')
        },
        {
            Header: "Created At",
            accessor: d => (dayjs(d.created_at).format(utils.dateFormat))
        },
        {
            Header: "Status",
            accessor: d => <StatusComponent status={d.status}/>
        },
    ];

    return (
        <>
            <Grid container justify="flex-end">
                {/* Recent Deposits */}
                <Grid item xs={12} md={12} lg={12}>
                    {processes && <EnhancedTable
                        columns={columns}
                        data={processes}
                        pageCount={processes.length}
                        component={false}
                    />}
                </Grid>
            </Grid>
        </>
    );
};

export default ProcessesPage;
import { push } from 'connected-react-router';

import {
    getServices,
    getService,
    addService,
    editService,
    deleteService
} from "../../services/ServiceService";


import {serviceConstants} from "../ActionTypes";

import {alertActions} from "./AlertAction";

export const getServicesAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: serviceConstants.PENDING_SERVICE_REQUEST
        });
        return getServices(data).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: serviceConstants.GET_SERVICES_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: serviceConstants.GET_SERVICES_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};

export const getServiceAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: serviceConstants.PENDING_SERVICE_REQUEST
        });
        return getService(data).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: serviceConstants.GET_SERVICE_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: serviceConstants.GET_SERVICE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};


export const addServiceAction = (service, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: serviceConstants.PENDING_SERVICE_REQUEST
        });
        return addService(service).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: serviceConstants.ADD_SERVICE_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/services/' + response.data.id));
                    dispatch(alertActions.success('Service added successfully'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: serviceConstants.ADD_SERVICE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};


export const editServiceAction = (id, service, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: serviceConstants.PENDING_SERVICE_REQUEST
        });
        return editService(id, service).then(
            (response) => {
                if (response.data) {

                    dispatch({
                        type: serviceConstants.EDIT_SERVICE_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/services/' + id));
                    dispatch(alertActions.success('Service edited successfully'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: serviceConstants.EDIT_SERVICE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};

export const deleteServiceAction = (id) => {
    return (dispatch) => {
        dispatch({
            type: serviceConstants.PENDING_SERVICE_REQUEST
        });
        return deleteService(id).then(
            (response) => {
                if (response) {
                    dispatch({
                        type: serviceConstants.DELETE_SERVICE_SUCCESS,
                        payload: id
                    });
                    dispatch(push('/services'));
                    dispatch(alertActions.success('Service deleted successfully'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: serviceConstants.DELETE_SERVICE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};
import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import SiteWrapper from "../../SiteWrapper";
import {useDispatch, useSelector} from "react-redux";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import useStyles from "../../styles";
import {getSettings} from "../../services/SettingService";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import BreadCrumb from "../../components/BreadCrumb";
import Switch from "@material-ui/core/Switch";
import {addMachineAction, editMachineAction} from "../../store/actions/MachineAction";
import {alertActions} from "../../store/actions/AlertAction";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

function AddEditVehiclePage({history, match}) {

    const classes = useStyles();

    const { id } = match.params;

    const [types, setTypes] = useState([]);
    const [models, setModels] = useState([]);

    const isAddMode = !id;

    const dispatch = useDispatch();

    const machines = useSelector(state => state.machine.machines);
    const machine = machines.find(machine => machine.id === parseInt(id));

    useEffect(() => {

        // Get Setting Types

        getSettings({group: 'machine types'}).then(
            (response) => {
                if (response.data) {
                    setTypes(response.data.results)
                }
            },
            (error) => {
                if (error.response) {
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );

        // Get Model Types
        getSettings({group: 'machine models'}).then(
            (response) => {
                if (response.data) {
                    setModels(response.data.results)
                }
            },
            (error) => {
                if (error.response) {
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );

    }, [dispatch]);

    const formik = useFormik({

        initialValues: {
            reg_no: machine ? machine.reg_no : '',
            fuel_km: machine ? machine.fuel_km : '',
            make: machine ? machine.make : '',
            nickname: machine ? machine.nickname : '',
            status: machine ? machine.status : true,
            type: machine && machine.type ? machine.type.url : '',
            model: machine && machine.model ? machine.model.url : ''
        },

        validationSchema: Yup.object().shape({
            reg_no: Yup.string().required('Registration Number is required'),
            fuel_km: Yup.number().required('Fuel consumption per KM is required'),
            model: Yup.string().required('Vehicle model is required'),
            type: Yup.string().required('Vehicle types is required'),
        }),

        onSubmit(values) {
            isAddMode ? dispatch(addMachineAction(values, errorCallback)) : dispatch(editMachineAction(id, values, errorCallback));
        },

        enableReinitialize: true
    });

    const errorCallback = (error) => {
        formik.setSubmitting(false);
    };

    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[{
                'name': 'Trucks',
                'link': '/vehicles'
            }]}/>

            <Grid container className={classes.marginTop}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography className={clsx(classes.pageTitle)}>
                            New Truck
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Paper className={classes.paper}>
                <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="reg_no"
                                variant="outlined"
                                required
                                fullWidth
                                id="reg_no"
                                label="Registration No"
                                value={formik.values.reg_no || ''} onChange={formik.handleChange}
                                error={formik.touched.reg_no && Boolean(formik.errors.reg_no)}
                                helperText={formik.touched.reg_no && formik.errors.reg_no}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="make"
                                variant="outlined"
                                required
                                fullWidth
                                id="make"
                                label="Make"
                                value={formik.values.make || ''} onChange={formik.handleChange}
                                error={formik.touched.make && Boolean(formik.errors.make)}
                                helperText={formik.touched.make && formik.errors.make}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="nickname"
                                variant="outlined"
                                fullWidth
                                id="nickname"
                                label="Nickname"
                                value={formik.values.nickname || ''} onChange={formik.handleChange}
                                error={formik.touched.nickname && Boolean(formik.errors.nickname)}
                                helperText={formik.touched.nickname && formik.errors.nickname}
                            />
                        </Grid>

                        <Grid item xs={4} sm={2}>
                            <FormControl className={classes.formControlMin}>
                                <InputLabel>Model</InputLabel>
                                <Select
                                    name='model'
                                    onChange={formik.handleChange}
                                    value={formik.values.model || ''}
                                    error={formik.touched.model && Boolean(formik.errors.model)}
                                    helperText={formik.touched.model && formik.errors.model}
                                >
                                    {models && models.map((m, idx) => <MenuItem
                                        value={m.url}
                                        key={idx}>{m.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={4} sm={3}>
                            <FormControl className={classes.formControlMin}>
                                <InputLabel>Type</InputLabel>
                                <Select
                                    name='type'
                                    onChange={formik.handleChange}
                                    value={formik.values.type || ''}
                                    error={formik.touched.type && Boolean(formik.errors.type)}
                                    helperText={formik.touched.type && formik.errors.type}
                                >
                                    {types && types.map((t, idx) => <MenuItem
                                        value={t.url}
                                        key={idx}>{t.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>



                        <Grid item xs={4} sm={7}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="Fuel/KM"
                                label="Fuel consumption per KM"
                                name="fuel_km"
                                value={formik.values.fuel_km || ''} onChange={formik.handleChange}
                                error={formik.touched.fuel_km && Boolean(formik.errors.fuel_km)}
                                helperText={formik.touched.fuel_km && formik.errors.fuel_km}
                            />
                        </Grid>

                        <Grid item xs={6} sm={1}>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.status}
                                        onChange={event => formik.setFieldValue('status', event.target.checked)}
                                        color="primary"
                                        name="Active"
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                }
                                label="Active"
                            />
                        </Grid>
                        <Grid container justify="flex-end">

                            <Grid item xs={6} sm={1}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="default"
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                > Cancel </Button>

                            </Grid>

                            <Grid item xs={6} sm={1}>
                                <Button
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.success}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </form>
            </Paper>
        </SiteWrapper>
    );
}

export default AddEditVehiclePage;

import React, {useEffect, useRef, useState} from 'react';
import Grid from "@material-ui/core/Grid";


import {withRouter} from 'react-router-dom';
import Box from "@material-ui/core/Box";
import useStyles from "../../styles";
import {useDispatch, useSelector} from "react-redux";
import EnhancedTable from "../../components/EnhancedTable";
import SiteWrapper from "../../SiteWrapper";
import Typography from "@material-ui/core/Typography";
import {httpWrapper} from "../../helper/Http";
import {contentTypeApplicationJson} from "../../constants/httpConstants";
import {utils} from "../../helper/Utils";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useFormik} from "formik";
import dayjs from "dayjs"
import * as Yup from "yup";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import {getProductsAction} from "../../store/actions/ProductAction";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import ExportToPdfComponent from "../../components/ExportToPdfComponent";
import BreadCrumb from "../../components/BreadCrumb";
import Autocomplete from "@material-ui/lab/Autocomplete";

const StockBalancePage = () => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const [balances, setBalances] = useState([]);
    const [total, setTotal] = useState(0.00);

    const products = useSelector(state => state.product.products);
    const [count, setCount] = useState(0);

    const fetchIdRef = useRef(0);

    const url = '/reports/stock-balance/';


    const fetchData = React.useCallback((query, cb) => {

        const fetchId = ++fetchIdRef.current;

        if (fetchId === fetchIdRef.current) {

            httpWrapper.get(url, contentTypeApplicationJson, query).then(
                (response) => {
                    if (response.data) {
                        setBalances(response.data.results.items ?? []);
                        setCount(response.data.results.items.length);
                        setTotal(utils.numberFormatter(response.data.results.total ?? 0.00));
                    }

                    cb && cb();
                },
                (error) => {

                    cb && cb();
                }
            );
        }
    }, []);

    useEffect(() => {

        dispatch(getProductsAction());

        fetchData({
            date: utils.DEFAULT_START_DATE
        }, null);

    }, [dispatch, fetchData]);


    const formik = useFormik({

        initialValues: {

            date: utils.DEFAULT_START_DATE,
            product: '',
            control: '',
            quantity: 0
        },

        validationSchema: Yup.object().shape({
            date: Yup.date("YYYY-mm-dd").required('Date is required'),
        }),

        onSubmit(values) {

            fetchData(values, callback);
        },

        enableReinitialize: true

    });

    const callback = () => {
        formik.setSubmitting(false);
    };

    const columns = [

        {
            Header: "Code",
            accessor: (d => d.code ?? '-')
        },
        {
            Header: "Item",
            accessor: "product"
        },
        {
            Header: "Quantity",
            accessor: (d => utils.numberFormatter(d.balance))
        },
        {
            Header: "Unit",
            accessor: "uom"
        },
        {
            Header: "Price",
            accessor: (d => utils.numberFormatter(d.price))
        },
        {
            Header: "Total",
            accessor: (d => utils.numberFormatter(d.line_total))
        },
    ];

    return (
        <>
            <SiteWrapper>
                <BreadCrumb crumbs={[{
                    'name': 'Reports',
                    'link': '/reports'
                },
                    {
                        'name': 'Stock Balance',
                        'link': '#'
                    }]}/>
                <Grid container className={classes.marginTop}>
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <Typography className={classes.medium}>
                                Stock Balance
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid container item xs={12} sm={8} justify="flex-end">
                        <Box>
                            {balances && <ExportToPdfComponent url={url} values={formik.values}/>}
                        </Box>
                    </Grid>
                </Grid>

                <Grid container className={classes.marginTop}>

                    <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>

                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={3}>
                                <MuiPickersUtilsProvider utils={DayjsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Date"
                                        maxDate={utils.maxDate}
                                        format="MM/DD/YYYY"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        value={formik.values.date}
                                        onChange={val => formik.setFieldValue('date', dayjs(val).format("YYYY-MM-DD"))}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                {/*<FormControl className={clsx(classes.formControl, classes.formsWithMui)}>*/}
                                {/*    <InputLabel>Product</InputLabel>*/}
                                {/*    <Select*/}
                                {/*        name="product"*/}
                                {/*        onChange={formik.handleChange}*/}
                                {/*        value={formik.values.product || ''}*/}
                                {/*        error={formik.touched.product && Boolean(formik.errors.product)}*/}
                                {/*        helperText={formik.touched.product && formik.errors.product}*/}
                                {/*    >*/}
                                {/*        <MenuItem value="">Select Value</MenuItem>*/}
                                {/*        {products && products.map((m, idp) => <MenuItem*/}
                                {/*            value={m.id}*/}
                                {/*            key={idp}>{m.name}</MenuItem>)}*/}
                                {/*    </Select>*/}
                                {/*</FormControl>*/}

                                <Autocomplete
                                    filterOptions={utils.autocompleteFilterOptions}
                                    className={clsx(classes.formControl, classes.formsWithMui)}
                                    disablePortal
                                    options={products}
                                    getOptionLabel={option => option.name}
                                    name='product'
                                    defaultValue={formik.values.product || null}
                                    getOptionSelected={(option, value) => option.url === value.url}
                                    onChange={(_, value) => value != null ? formik.setFieldValue('product', value.id) : ''}
                                    renderInput={(params) => <TextField {...params}
                                                                        error={formik.errors.product && Boolean(formik.touched.product)}
                                                                        helperText={formik.errors.product && formik.touched.product}
                                                                        name='product'
                                                                        label="Spare"/>}
                                    classes={{paper: classes.autocomplete}}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <FormControl className={clsx(classes.formControl, classes.formsWithMui)}>
                                    <InputLabel>Control</InputLabel>
                                    <Select
                                        name="control"
                                        onChange={formik.handleChange}
                                        value={formik.values.control || ''}
                                        error={formik.touched.control && Boolean(formik.errors.control)}
                                        helperText={formik.touched.control && formik.errors.control}
                                    >
                                        <MenuItem value="">Select Value</MenuItem>
                                        <MenuItem value="eql" key="expense">Equal To</MenuItem>
                                        <MenuItem value="gt" key="mileage">Greater Than</MenuItem>
                                        <MenuItem value="lt" key="service">Less Than</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <TextField
                                    className={clsx(classes.formControl, classes.formsWithMui)}
                                    name="quantity"
                                    variant="standard"
                                    id="quantity"
                                    label="Quantity"
                                    value={formik.values.quantity || ''} onChange={formik.handleChange}
                                    error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                                    helperText={formik.touched.quantity && formik.errors.quantity}
                                />
                            </Grid>

                        </Grid>
                    </form>
                    <Grid container justify="center">
                        <Button
                            onClick={formik.handleSubmit}
                            type="button"
                            size="small"
                            variant="contained"
                            color="secondary"
                            className={clsx(classes.primary, classes.formsWithMui)}
                            disabled={formik.isSubmitting}
                        >
                            Filter
                        </Button>
                    </Grid>
                </Grid>

                <Grid container justify="flex-end" className={classes.marginTop}>
                    {/* Recent Deposits */}
                    <Grid item xs={12} md={12} lg={12}>
                        {balances && <EnhancedTable
                            columns={columns}
                            data={balances}
                            summaries={[{
                                "title": "Total",
                                "value": total,
                                "colSpan": 2,
                                "freeSpan": 3
                            }]}
                            pageCount={count}
                        />}
                    </Grid>
                </Grid>
            </SiteWrapper>
        </>
    );
};

export default withRouter(StockBalancePage);
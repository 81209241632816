import React, {useContext} from 'react';


import useStyles from "../styles";


import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {useDispatch} from "react-redux";

import ListIcon from '@material-ui/icons/List';
import IconButton from "@material-ui/core/IconButton";

import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';

import {Link} from "react-router-dom";
import ConfirmDialogContext from "./Dialog/ConfirmDialogContext";

function MenuActionComponent(props) {

    const classes = useStyles();

    const item = props.item;
    const url = props.url;
    const dispatch = useDispatch();

    const { setConfirmDialog } = useContext(ConfirmDialogContext);

    const deleteAction = props.deleteAction ? props.deleteAction : null;

    const [open, setOpen] = React.useState(false);

    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };


    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    const handleDelete = (id) => {

        // Check if delete action is set
        if (null !== deleteAction) {

            setConfirmDialog({
                isOpen: true,
                text: 'Are you sure want to delete this item ?',
                handler: () => {
                    dispatch(deleteAction(id))
                }
            });
        }
    };

    return (
        <>
            <IconButton color="primary" aria-label="upload picture" component="span"
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
            >
                <ListIcon/>
            </IconButton>

            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
                    style={{zIndex: 1000}}>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" className={classes.actionMenu}>
                                    {item.showView && <MenuItem><Link to={"/" + url + "/" + item.id}
                                                                      className={classes.actionLink}><VisibilityIcon
                                        className={classes.actionLinkIcon}/>view</Link></MenuItem>}
                                    {!item.hideEdit && <MenuItem><Link to={"/" + url + "/" + item.id + "/edit"}
                                                                       className={classes.actionLink}><EditIcon
                                        className={classes.actionLinkIcon}/>edit</Link></MenuItem>}
                                    {!item.hideDelete && <MenuItem><Link to="#" className={classes.actionLink}
                                                                         onClick={() => handleDelete(item.id)}><DeleteIcon
                                        className={classes.actionLinkIcon}/>delete</Link></MenuItem>}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

export default MenuActionComponent;

import React from 'react';

import Button from "@material-ui/core/Button";
import {utils} from "../helper/Utils";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {auth} from "../helper/Auth";

function ExportToPdfComponent({url, values}) {

    const exportPDF = () => {



        const params = Object.assign({}, values);

        params['generate_report'] = 'pdf';

        const uri = utils.downloadFileLink(url, params);


        fetch(uri, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
                "Authorization" : "Token " + auth.getToken(),
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `protrack_download.pdf`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    };

    return (
        <div>
            <Button
                type="button"
                size="small"
                color="primary"
                onClick={exportPDF}
                startIcon={<CloudDownloadIcon/>}
            >
                PDF
            </Button>
        </div>
    );
}

export default ExportToPdfComponent;

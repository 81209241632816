import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import SiteWrapper from "../../SiteWrapper";
import {useDispatch, useSelector} from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import dayjs from "dayjs";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import useStyles from "../../styles";
import {utils} from "../../helper/Utils";
import {getProductsAction} from "../../store/actions/ProductAction";
import {addAdjustmentAction} from "../../store/actions/StockAdjustmentAction";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import {httpService} from "../../services/HttpService";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";


function AddStockAdjustmentPage() {

    const classes = useStyles();

    const dispatch = useDispatch();

    const products = useSelector(state => state.product.products);

    const [productSelected, setProductSelected] = useState({
        'product': null,
        'selected': false
    });

    const [isReducing, setIsReducing] = useState(false);

    const [productPurchase, setProductPurchase] = useState([]);
    const [openInventoryDialog, setOpenInventoryDialog] = React.useState(false);
    const [quantityStatus, setQuantityStatus] = React.useState('Loading ...');


    useEffect(() => {

        dispatch(getProductsAction());

    }, [dispatch]);


    const formik = useFormik({

        initialValues: {
            effective_date: dayjs(new Date()).format("YYYY-MM-DD"),
            product: '',
            sn_no: '',
            quantity: 0.00,
            type: '',
            price: 0.00,
            vat: 18,
            adjusted_items: [],

        },

        validationSchema: Yup.object().shape({
            effective_date: Yup.date("YYYY-mm-dd").required('Departure date is required'),
            product: Yup.string().required('Product is required'),
            type: Yup.string().required('Type is required'),

            quantity: Yup.number().when('type', {
                is: type => type && type === "I",
                then: Yup.number().min(1, "Quantity should be greater than zero").required('Quantity is required')
            }),

            price: Yup.number().when('type', {
                is: type => type && type === "I",
                then: Yup.number().min(1, "Buying price must be greater than zero").required('Buying price is required')
            }),
        }),

        onSubmit(values) {

           dispatch(addAdjustmentAction(values, errorCallback));
        },

        enableReinitialize: true

    });



    const formikPurchase = useFormik({

        initialValues: {
            service_line_items: productPurchase ? Object.assign([], productPurchase.map((item, key) => ({
                'product': item && item.product ? item.product.id : '',
                'purchase_reference': item ? item.id : '',
                'quantity_serviced': item && item.quantity_serviced ? item.quantity_serviced : 0.00,
                'sn_no': item ? item.sn_no : '',
                'buying_price': item ? item.buying_price : '',
                'quantity_available': item ? item.quantity_available : ''
            }))) : [],
        },


        validationSchema: Yup.object().shape({
            service_line_items: Yup.array()
                .min(1, "Enter at least one quantity line")
                .of(
                    Yup.object()
                        .shape({
                            quantity_serviced: Yup.number().required('Quantity is required').typeError('Qty must be a number').max(Yup.ref("quantity_available")).moreThan(0)
                        })
                        .nullable()
                        .required("Quantity line is required")
                ),

        }),

        onSubmit(values) {

            formik.setFieldValue('adjusted_items', values.service_line_items);

            handleInventoryDialogClose();
        },

        enableReinitialize: true
    });

    const errorCallback = (error) => {
        formik.setSubmitting(false);
    };


    const handleInventoryDialogClickOpen = () => {
        setOpenInventoryDialog(true);
    };

    const handleInventoryDialogClose = () => {

        setOpenInventoryDialog(false);
    };


    const handleTypeChange = (event) => {

        if(event.target.value === 'D'){

            setIsReducing(true);
            handleInventoryDialogClickOpen();

        } else {

            setIsReducing(false);
        }
    };

    const handleProductChange = (name, value, setFieldValue) => {

        if (null != value) {

            setFieldValue(name, value.url);


            setProductSelected({'product': value.id, 'selected': true});


            setQuantityStatus('Loading ...');

            // Reset products
            setProductPurchase([]);

            httpService.get('/inventory-transactions/get-product-purchase/', {'product': value.id}).then(
                (response) => {
                    if (response.data) {
                        response.data.length > 0 ? setProductPurchase(response.data) : setQuantityStatus('No available quantity. Purchase new items');
                    }
                },
                (error) => {
                    alert('Failed to load purchase history');
                    setQuantityStatus('Failed to load product details');
                }
            );

            isReducing && handleInventoryDialogClickOpen();
        }
    };


    const inventoryDialog = () => {

        return (
            <>
                <Dialog open={openInventoryDialog} onClose={handleInventoryDialogClose}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Enter quantity to reduce</DialogTitle>
                    <DialogContent>
                        <form className={classes.form} noValidate autoComplete="off">
                            <Grid container>

                                {productPurchase.length > 0 ? productPurchase.map((m, j) => {

                                    const purchaseLineErrors = (formikPurchase.errors.service_line_items?.length && formikPurchase.errors.service_line_items[j]) || {};
                                    const purchaseLineTouched = (formikPurchase.touched.service_line_items?.length && formikPurchase.touched.service_line_items[j]) || {};
                                    const purchaseLineValue = (formikPurchase.values.service_line_items?.length && formikPurchase.values.service_line_items[j]) || {};

                                    return (<Grid container spacing={1} key={j}>
                                        <TextField
                                            type="hidden"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            name={`service_line_items.${j}.purchase_reference`}
                                            onChange={formikPurchase.handleChange}
                                            value={(purchaseLineValue && purchaseLineValue.purchase_reference) || ''}
                                            error={purchaseLineErrors.purchase_reference && Boolean(purchaseLineTouched.purchase_reference)}
                                            helperText={purchaseLineErrors.purchase_reference && purchaseLineTouched.purchase_reference}
                                        />
                                        <TextField
                                            type="hidden"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            name={`service_line_items.${j}.product`}
                                            onChange={formikPurchase.handleChange}
                                            value={(purchaseLineValue && purchaseLineValue.product) || ''}
                                            error={purchaseLineErrors.product && Boolean(purchaseLineTouched.product)}
                                            helperText={purchaseLineErrors.product && purchaseLineTouched.product}
                                        />
                                        <Grid item xs={3} sm={3}>
                                            <TextField
                                                margin="dense"
                                                id="buying_price"
                                                label="Buying Price"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                name={`service_line_items.${j}.buying_price`}
                                                onChange={formikPurchase.handleChange}
                                                value={(purchaseLineValue && purchaseLineValue.buying_price) || ''}
                                                error={purchaseLineErrors.buying_price && Boolean(purchaseLineTouched.buying_price)}
                                                helperText={purchaseLineErrors.buying_price && purchaseLineTouched.buying_price}
                                            />
                                        </Grid>
                                        <Grid item xs={3} sm={4}>
                                            <TextField
                                                margin="dense"
                                                id="quantity_remained"
                                                label="Quantity Remained"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                name={`service_line_items.${j}.quantity_available`}
                                                onChange={formikPurchase.handleChange}
                                                value={(purchaseLineValue && purchaseLineValue.quantity_available) || ''}
                                                error={purchaseLineErrors.quantity_available && Boolean(purchaseLineTouched.quantity_available)}
                                                helperText={purchaseLineErrors.quantity_available && purchaseLineTouched.quantity_available}
                                            />

                                        </Grid>
                                        <Grid item xs={3} sm={3}>
                                            <TextField
                                                margin="dense"
                                                id="serial_number"
                                                label="Serial Number"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                name={`service_line_items.${j}.sn_no`}
                                                onChange={formikPurchase.handleChange}
                                                value={(purchaseLineValue && purchaseLineValue.sn_no) || ''}
                                                error={purchaseLineErrors.sn_no && Boolean(purchaseLineTouched.sn_no)}
                                                helperText={purchaseLineErrors.sn_no && purchaseLineTouched.sn_no}
                                            />
                                        </Grid>
                                        <Grid item xs={3} sm={2}>
                                            <TextField
                                                margin="dense"
                                                id="quantity_serviced"
                                                label="Quantity"
                                                name={`service_line_items.${j}.quantity_serviced`}
                                                onChange={formikPurchase.handleChange}
                                                value={(purchaseLineValue && purchaseLineValue.quantity_serviced) || ''}
                                                error={purchaseLineErrors.quantity_serviced && Boolean(purchaseLineTouched.quantity_serviced)}
                                                helperText={purchaseLineErrors.quantity_serviced && purchaseLineTouched.quantity_serviced}
                                            />
                                        </Grid>
                                    </Grid>)
                                }) : <Grid container spacing={1}><Typography>{quantityStatus}</Typography></Grid>}

                            </Grid>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleInventoryDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={formikPurchase.handleSubmit} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    };

    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[{
                'name': 'Adjustments',
                'link': '/stock-adjustments'
            }]}/>

            <Grid container className={classes.marginTop}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography className={clsx(classes.pageTitle)}>
                            New Adjustment
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Paper className={classes.paper}>
                {inventoryDialog()}
                <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <MuiPickersUtilsProvider utils={DayjsUtils}>
                                <KeyboardDatePicker
                                    id="date-picker-dialog"
                                    label="Adjustment Date"
                                    format="MM/DD/YYYY"
                                    maxDate={utils.maxDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    value={formik.values.effective_date}
                                    onChange={val => formik.setFieldValue('departure', dayjs(val).format("YYYY-MM-DD"))}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4} sm={3}>
                            {/*<FormControl className={classes.formControlMin}>*/}
                            {/*    <InputLabel>Product</InputLabel>*/}
                            {/*    <Select*/}
                            {/*        name='product'*/}
                            {/*        value={formik.values.product}*/}
                            {/*        error={formik.errors.product && Boolean(formik.touched.product)}*/}
                            {/*        helperText={formik.errors.product && formik.touched.product}*/}
                            {/*        onChange={(e) => {*/}
                            {/*            formik.handleChange(e);*/}
                            {/*            handleProductChange(e);*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        {products && products.map((m, idx) => <MenuItem*/}
                            {/*            value={m.url}*/}
                            {/*            data-id={m.id}*/}
                            {/*            key={idx}>{m.name}</MenuItem>)}*/}
                            {/*    </Select>*/}
                            {/*</FormControl>*/}

                            <Autocomplete
                                filterOptions={utils.autocompleteFilterOptions}
                                className={classes.formControl}
                                disablePortal
                                options={products}
                                getOptionLabel={option => option.name}
                                name='product'
                                defaultValue={formik.values.product || null}
                                getOptionSelected={(option, value) => option.url === value.url}
                                onChange={(e, value) => {
                                    handleProductChange('product', value, formik.setFieldValue);
                                }}
                                renderInput={(params) => <TextField {...params}
                                                                    error={formik.errors.product && Boolean(formik.errors.product)}
                                                                    helperText={formik.errors.product && formik.errors.product}
                                                                    name='product'
                                                                    label="Product"/>}
                                classes={{ paper: classes.autocomplete }}
                            />
                        </Grid>


                        <Grid item xs={4} sm={2}>
                            <FormControl className={classes.formControlMin} disabled={!productSelected.selected}>
                                <InputLabel>Type</InputLabel>
                                <Select
                                    name='type'
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        handleTypeChange(e);
                                    }}
                                    value={formik.values.type}
                                    error={formik.errors.type && Boolean(formik.touched.type)}
                                    helperText={formik.errors.type && formik.touched.type}
                                >
                                    <MenuItem value="I">Increase</MenuItem>
                                    <MenuItem value="D">Decrease</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>


                        <Grid item xs={3} sm={3}>
                            <TextField
                                type="number"
                                name='quantity'
                                required
                                label='Quantity'
                                onChange={formik.handleChange}
                                value={formik.values.quantity}
                                error={formik.errors.quantity && Boolean(formik.touched.quantity)}
                                helperText={formik.errors.quantity && formik.touched.quantity}
                            />
                        </Grid>

                    </Grid>

                    <Grid container spacing={3}>


                        <Grid item xs={3} sm={2}>
                            <TextField
                                name='sn_no'
                                label='Serial'
                                onChange={formik.handleChange}
                                value={formik.values.sn_no}
                                error={formik.errors.sn_no && Boolean(formik.touched.sn_no)}
                                helperText={formik.errors.sn_no && formik.touched.sn_no}
                            />
                        </Grid>

                        <Grid item xs={3} sm={2}>
                            <TextField
                                type="number"
                                name='price'
                                required
                                label='Buying Price'
                                onChange={formik.handleChange}
                                value={formik.values.price}
                                error={formik.errors.price && Boolean(formik.touched.price)}
                                helperText={formik.errors.price && formik.touched.price}
                            />
                        </Grid>

                        <Grid item xs={3} sm={1}>
                            <TextField
                                name='vat'
                                label='VAT'
                                onChange={formik.handleChange}
                                value={formik.values.vat}
                                error={formik.errors.vat && Boolean(formik.touched.vat)}
                                helperText={formik.errors.vat && formik.touched.vat}
                            />
                        </Grid>
                    </Grid>


                    <Grid container justify="flex-end" className={classes.marginTop}>
                        <Grid item xs={6} sm={1}>
                            <Button
                                type="submit"
                                size="small"
                                variant="contained"
                                color="default"
                                className={classes.primary}
                                onClick={() => formik.setFieldValue('status', 0)}
                                disabled={formik.isSubmitting}
                            > Save </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </SiteWrapper>
    );
}

export default AddStockAdjustmentPage;

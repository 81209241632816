import {tripConstants} from "../ActionTypes";

const initialState = {
    trips: [],
    count: 0,
    trip: {},
    isLoading: false
};

function TripReducer(state = initialState, action) {

    switch (action.type) {
        case tripConstants.PENDING_TRIP_REQUEST:
            return {...state, isLoading: true};
        case tripConstants.GET_TRIPS_SUCCESS:
            return {...state, trips: action.payload.results, count: action.payload.count, isLoading: false};
        case tripConstants.GET_TRIPS_ERROR:
            return {...state, isLoading: false};
        case tripConstants.GET_TRIP_SUCCESS:
            return {...state, trip: action.payload, isLoading: false};
        case tripConstants.GET_TRIP_ERROR:
            return {...state, isLoading: false};
        case tripConstants.ADD_TRIP_SUCCESS:
            return {...state, trips: state.trips.concat(action.payload), isLoading: false};
        case tripConstants.ADD_TRIP_ERROR:
            return {...state, isLoading: false};
        case tripConstants.EDIT_TRIP_SUCCESS:
            return {...state, trip: action.payload, isLoading: false};
        case tripConstants.EDIT_TRIP_ERROR:
            return {...state, isLoading: false};
        case tripConstants.DELETE_TRIP_SUCCESS:
            return {
                ...state,
                trips: state.trips.filter((trip) => trip.id !== action.payload),
                count: state.count - 1,
                isLoading: false
            };
        case tripConstants.DELETE_TRIP_ERROR:
            return {...state.trips, isLoading: false};
        default:
            return state;
    }
}

export default TripReducer;
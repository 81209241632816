import React, {useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {loginAction, logoutAction} from "../store/actions/AuthAction";
import {allowedSubdomains} from "../constants";
import { Link, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import Alert from "@material-ui/lab/Alert";


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" to="#">
                ProTrack System
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
    },
    alert: {
        margin: theme.spacing(2, 0, 0),
        minWidth: '100%'
    }
}));

function LoginPage() {

    const classes = useStyles();

    const dispatch = useDispatch();

    const message = useSelector(state => state.auth.message);

    const location = useLocation();

    // reset login status
    useEffect(() => {
        dispatch(logoutAction());
    });


    const formik = useFormik({

        initialValues: {
            company: '',
            username: '',
            password: ''
        },

        validationSchema: Yup.object().shape({
            company: Yup.mixed().required('Company name is required').oneOf(allowedSubdomains, 'Company name is not valid'),
            username: Yup.string().required('Username is required'),
            password: Yup.string().required('Password is required'),
        }),

        onSubmit(values, {setSubmitting}) {

            // get return url from location state or default to home page
            const {from} = location.state || { from: { pathname: "/" } };

            dispatch(loginAction(values)).then((response) => {
                window.location.replace(from.pathname);

            }).catch(() => {
                setSubmitting(false);
            });
        },
    });

    return (
        <>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    { message && <Alert severity="error" className={classes.alert}>{message}</Alert> }
                    <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="company"
                            label="Company"
                            name="company"
                            value={formik.values.company} onChange={formik.handleChange}
                            error={formik.touched.company && Boolean(formik.errors.company)}
                            helperText={formik.touched.company && formik.errors.company}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            value={formik.values.username} onChange={formik.handleChange}
                            error={formik.touched.username && Boolean(formik.errors.username)}
                            helperText={formik.touched.username && formik.errors.username}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={formik.values.password} onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        {/*<FormControlLabel*/}
                        {/*    control={<Checkbox value="remember" color="primary"/>}*/}
                        {/*    label="Remember me"*/}
                        {/*/>*/}
                        <Box textAlign='center'>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={formik.isSubmitting}
                            >
                                {formik.isSubmitting ? 'Loggin in ...' : 'Sign In'}
                            </Button>
                        </Box>
                        {/*<Grid container>*/}
                        {/*    <Grid item xs>*/}
                        {/*        <Link href="#" variant="body2">*/}
                        {/*            Forgot password?*/}
                        {/*        </Link>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item>*/}
                        {/*        <Link href="#" variant="body2">*/}
                        {/*            {"Don't have an account? Sign Up"}*/}
                        {/*        </Link>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright/>
                </Box>
            </Container>
        </>
    );
}

export default LoginPage;

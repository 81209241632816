import React, {useEffect} from 'react';

import {useDispatch, useSelector} from "react-redux";
import useStyles from "../styles";
import {alertActions} from "../store/actions/AlertAction";
import history from '../helper/History';
import Alert from "@material-ui/lab/Alert/Alert";
import Grid from "@material-ui/core/Grid";

function AlertComponent() {

    const classes = useStyles();

    const alert = useSelector(state => state.alert);

    const dispatch = useDispatch();

    useEffect(() => {
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
    });


    const renderMessage = (response) => {

        if(response && typeof response === 'object'){
            return response.message;
        }

        if(response && typeof response === 'string'){
            return response;
        }
    };

    return (
        <>
            <Grid item xs={12} sm={12}>
                {Object.keys(alert).length > 0 && <Alert severity={alert.type} className={classes.alert}>{renderMessage(alert.response)}</Alert>}
            </Grid>
        </>
    )
}

export default AlertComponent;

import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import SiteWrapper from "../../SiteWrapper";
import {useDispatch, useSelector} from "react-redux";
import {getSettingsAction} from "../../store/actions/SettingAction";
import {getMachinesAction} from "../../store/actions/MachineAction";
import BreadCrumb from "../../components/BreadCrumb";
import TextField from "@material-ui/core/TextField";
import useStyles from "../../styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import dayjs from "dayjs";
import DayjsUtils from "@date-io/dayjs";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import {getProductsAction} from "../../store/actions/ProductAction";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import {httpService} from "../../services/HttpService";
import {addServiceAction, editServiceAction} from "../../store/actions/ServiceAction";
import {utils} from "../../helper/Utils";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Box from "@material-ui/core/Box";
import clsx from "clsx";

function AddEditServicePage({match}) {

    const classes = useStyles();

    const {id} = match.params;

    const isAddMode = !id;

    const dispatch = useDispatch();

    const [serviceLines, setServiceLines] = useState([{
        'product': '',
        'machine': '',
        'attached_to': ''
    }]);

    const [productPurchase, setProductPurchase] = useState([]);
    const [openInventoryDialog, setOpenInventoryDialog] = React.useState(false);
    const [quantityStatus, setQuantityStatus] = React.useState('Loading ...');

    const [inventoryPurchase, setInventoryPurchase] = useState({
        'reference': ''
    });

    const services = useSelector(state => state.service.services);

    const products = useSelector(state => state.product.products);
    const machines = useSelector(state => state.machine.machines);
    const attached = useSelector(state => state.setting.settings);

    const service = services.find(service => service.id === parseInt(id));


    useEffect(() => {

        dispatch(getSettingsAction({can_be_attached: true}));
        dispatch(getMachinesAction());
        dispatch(getProductsAction());

        service && service.service_lines && setServiceLines(Object.assign([], service.service_lines.map((item, key) => ({
            'product': item && item.product ? item.product.url : '',
            service_lines: service && service.service_lines ? Object.assign([], service.service_lines.map((line, key) => ({
                'product': line ? line.product : null,
                'service_line_items': line && line.service_line_items ? Object.assign([], line.service_line_items.map((item, key) => ({
                    'product': item && item.product ? typeof item.product === 'object' ? item.product.url : item.product : '',
                    'purchase_reference': item && item.purchase_reference ? item.purchase_reference.url : '',
                    'quantity_serviced': item && item.quantity_serviced ? item.quantity_serviced : 0.00,
                    'sn_no': item ? item.sn_no : '',
                    'buying_price': item ? item.buying_price : '',
                    'quantity_available': item && item.purchase_reference ? item.purchase_reference.quantity_available : '',
                }))) : [],
                'machine': line && line.machine ? line.machine.url : '',
                'attached_to': line && line.attached_to ? line.attached_to.url : ''
            }))) : [],
            'machine': item && item.machine ? item.machine.url : '',
        }))));


    }, [dispatch, service]);

    const formik = useFormik({

        initialValues: {
            service_date: service ? service.service_date : dayjs().format("YYYY-MM-DD"),
            service_lines: service && service.service_lines ? Object.assign([], service.service_lines.map((line, key) => ({
                'product': line ? line.product : null,
                'service_line_items': line && line.service_line_items ? Object.assign([], line.service_line_items.map((item, key) => ({
                    'product': item && item.product ? typeof item.product === 'object' ? item.product.url : item.product : '',
                    'purchase_reference': item && item.purchase_reference ? typeof item.purchase_reference === 'object' ? item.purchase_reference.url : item.purchase_reference : item.url ? item.url : '',
                    'quantity_serviced': item && item.quantity_serviced ? item.quantity_serviced : 0.00,
                    'sn_no': item ? item.sn_no : '',
                    'buying_price': item ? item.buying_price : '',
                    'quantity_available': item && item.purchase_reference ? item.purchase_reference.quantity_available : 0.00,
                }))) : [],
                'machine': line ? line.machine : null,
                'attached_to': line && line.attached_to ? line.attached_to.url : ''
            }))) : [],
            status: 0
        },

        validationSchema: Yup.object().shape({
            service_date: Yup.date("YYYY-mm-dd").required('Effective date is required'),
            service_lines: Yup.array()
                .min(1, "Enter at least one service line")
                .of(
                    Yup.object()
                        .shape({
                            product: Yup.string('Must be a string').required('Product is required'),
                            machine: Yup.string('Must be a string').required('Machine is required'),
                        })
                        .required("Service line is required")
                ),

        }),

        onSubmit(values) {

            isAddMode ? dispatch(addServiceAction(values, errorCallback)) : dispatch(editServiceAction(id, values, errorCallback));

        },

        enableReinitialize: true
    });

    const formikPurchase = useFormik({

        initialValues: {
            service_line_items: productPurchase ? Object.assign([], productPurchase.map((item, key) => ({
                'product': item && item.product ? typeof item.product === 'object' ? item.product.url : item.product : '',
                'purchase_reference': item && item.purchase_reference ? typeof item.purchase_reference === 'object' ? item.purchase_reference.url : item.purchase_reference : item.url ? item.url : '',
                'quantity_serviced': item && item.quantity_serviced ? item.quantity_serviced : 0.00,
                'sn_no': item ? item.sn_no : '',
                'buying_price': item ? item.buying_price : '',
                'quantity_available': item ? item.quantity_available : ''
            }))) : [],
        },


        validationSchema: Yup.object().shape({
            service_line_items: Yup.array()
                .min(1, "Enter at least one quantity line")
                .of(
                    Yup.object()
                        .shape({
                            quantity_serviced: Yup.number().required('Quantity is required').typeError('Qty must be a number').max(Yup.ref("quantity_available"))
                        })
                        .nullable()
                        .required("Quantity line is required")
                ),

        }),

        onSubmit(values) {

            formik.values.service_lines[inventoryPurchase.reference] && formik.setFieldValue(`service_lines.${inventoryPurchase.reference}.service_line_items`, values.service_line_items);

            handleInventoryDialogClose();
        },

        enableReinitialize: true
    });

    const errorCallback = (error) => {
        formik.setSubmitting(false);
    };

    const handleAddServiceLine = index => {

        setServiceLines([...serviceLines, {
            'product': '',
            'quantity': 0.00,
            'sn_no': '',
            'machine': '',
            'attached_to': ''
        }]);
    };

    const handleRemoveServiceLine = index => {

        const list = [...serviceLines];

        list.splice(index, 1);

        setServiceLines(list);

        formik.values.service_lines[index] &&  formik.values.service_lines.splice(index, 1);
    };

    const handleInventoryDialogClickOpen = () => {
        setOpenInventoryDialog(true);
    };

    const handleInventoryDialogClose = () => {

        setOpenInventoryDialog(false);
    };


    const handleProductChange = (reference, name, value, setFieldValue) => {

        if (null !== value) {

            setFieldValue(name, value.url);

            setQuantityStatus('Loading ...');

            // Reset products
            setProductPurchase([]);

            httpService.get('/inventory-transactions/get-product-purchase/', {'product': value.id}).then(
                (response) => {
                    if (response.data) {
                        response.data.length > 0 ? setProductPurchase(response.data) : setQuantityStatus('No available quantity. Purchase new items');
                    }
                },
                (error) => {
                    alert('Failed to load purchase history');
                    setQuantityStatus('Failed to load product details');
                }
            );

            setInventoryPurchase({
                'reference': reference
            });

            handleInventoryDialogClickOpen();

        }
    };


    const updatePurchaseLines = (reference) => {

        if (formik.values.service_lines[reference].service_line_items) {
            setProductPurchase(formik.values.service_lines[reference].service_line_items);
            setInventoryPurchase({
                'reference': reference
            });

            handleInventoryDialogClickOpen();
        }
    };

    const handleInputChange = (event, name, value, setFieldValue) => {

        // Check if event is null to prevent multiple value set
        // If on change occurred, the event will be click

        if (null === event && value.url) {
            setFieldValue(name, value.url)
        }
    };

    const inventoryDialog = () => {

        return (
            <>
                <Dialog open={openInventoryDialog} onClose={handleInventoryDialogClose}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Enter Quantity</DialogTitle>
                    <DialogContent>
                        <form className={classes.form} noValidate autoComplete="off">
                            <Grid container>

                                {productPurchase.length > 0 ? productPurchase.map((m, j) => {

                                    const purchaseLineErrors = (formikPurchase.errors.service_line_items?.length && formikPurchase.errors.service_line_items[j]) || {};
                                    const purchaseLineTouched = (formikPurchase.touched.service_line_items?.length && formikPurchase.touched.service_line_items[j]) || {};
                                    const purchaseLineValue = (formikPurchase.values.service_line_items?.length && formikPurchase.values.service_line_items[j]) || {};

                                    return (<Grid container spacing={1} key={j}>
                                        <TextField
                                            type="hidden"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            name={`service_line_items.${j}.purchase_reference`}
                                            onChange={formikPurchase.handleChange}
                                            value={(purchaseLineValue && purchaseLineValue.purchase_reference) || ''}
                                            error={purchaseLineErrors.purchase_reference && Boolean(purchaseLineTouched.purchase_reference)}
                                            helperText={purchaseLineErrors.purchase_reference && purchaseLineTouched.purchase_reference}
                                        />
                                        <TextField
                                            type="hidden"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            name={`service_line_items.${j}.product`}
                                            onChange={formikPurchase.handleChange}
                                            value={(purchaseLineValue && purchaseLineValue.product) || ''}
                                            error={purchaseLineErrors.product && Boolean(purchaseLineTouched.product)}
                                            helperText={purchaseLineErrors.product && purchaseLineTouched.product}
                                        />
                                        <Grid item xs={3} sm={3}>
                                            <TextField
                                                margin="dense"
                                                id="buying_price"
                                                label="Buying Price"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                name={`service_line_items.${j}.buying_price`}
                                                onChange={formikPurchase.handleChange}
                                                value={(purchaseLineValue && purchaseLineValue.buying_price) || ''}
                                                error={purchaseLineErrors.buying_price && Boolean(purchaseLineTouched.buying_price)}
                                                helperText={purchaseLineErrors.buying_price && purchaseLineTouched.buying_price}
                                            />
                                        </Grid>
                                        <Grid item xs={3} sm={4}>
                                            <TextField
                                                margin="dense"
                                                id="quantity_remained"
                                                label="Quantity Remained"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                name={`service_line_items.${j}.quantity_available`}
                                                onChange={formikPurchase.handleChange}
                                                value={(purchaseLineValue && purchaseLineValue.quantity_available) || ''}
                                                error={purchaseLineErrors.quantity_available && Boolean(purchaseLineTouched.quantity_available)}
                                                helperText={purchaseLineErrors.quantity_available && purchaseLineTouched.quantity_available}
                                            />

                                        </Grid>
                                        <Grid item xs={3} sm={3}>
                                            <TextField
                                                margin="dense"
                                                id="serial_number"
                                                label="Serial Number"
                                                name={`service_line_items.${j}.sn_no`}
                                                onChange={formikPurchase.handleChange}
                                                value={(purchaseLineValue && purchaseLineValue.sn_no) || ''}
                                                error={purchaseLineErrors.sn_no && Boolean(purchaseLineTouched.sn_no)}
                                                helperText={purchaseLineErrors.sn_no && purchaseLineTouched.sn_no}
                                            />
                                        </Grid>
                                        <Grid item xs={3} sm={2}>
                                            <TextField
                                                margin="dense"
                                                id="quantity_serviced"
                                                label="Quantity"
                                                name={`service_line_items.${j}.quantity_serviced`}
                                                onChange={formikPurchase.handleChange}
                                                value={(purchaseLineValue && purchaseLineValue.quantity_serviced) || ''}
                                                error={purchaseLineErrors.quantity_serviced && Boolean(purchaseLineTouched.quantity_serviced)}
                                                helperText={purchaseLineErrors.quantity_serviced && purchaseLineTouched.quantity_serviced}
                                            />
                                        </Grid>
                                    </Grid>)
                                }) : <Grid container spacing={1}><Typography>{quantityStatus}</Typography></Grid>}

                            </Grid>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleInventoryDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={formikPurchase.handleSubmit} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    };

    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[{
                'name': 'Services',
                'link': '/services'
            }]}/>

            <Grid container className={classes.marginTop}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography className={clsx(classes.pageTitle)}>
                            New Service
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Paper className={classes.paper}>
                {inventoryDialog()}
                <form className={classes.form} onSubmit={formik.handleSubmit} noValidate autoComplete="off">
                    <Grid container spacing={4}>
                        <MuiPickersUtilsProvider utils={DayjsUtils}>
                            <Grid item xs={4} sm={2}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Service Date"
                                    format="MM/DD/YYYY"
                                    maxDate={utils.maxDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    value={formik.values.service_date}
                                    onChange={val => formik.setFieldValue('service_date', dayjs(val).format("YYYY-MM-DD"))}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid container spacing={2} className={classes.marginTop}>
                        <Grid item>
                            <Typography variant="subtitle2">Service Items</Typography>
                        </Grid>

                        {formik.errors.service_lines && typeof formik.errors.service_lines == 'string' &&
                        <Grid item md={12} xs={12}>
                            <FormHelperText error>** {formik.errors.service_lines}</FormHelperText>
                        </Grid>}
                    </Grid>

                    {serviceLines.map((serviceLine, i) => {

                        const serviceLineErrors = (formik.errors.service_lines?.length && formik.errors.service_lines[i]) || {};
                        const serviceLineTouched = (formik.touched.service_lines?.length && formik.touched.service_lines[i]) || {};
                        const serviceLineValue = (formik.values.service_lines?.length && formik.values.service_lines[i]) || {};

                        return (<Grid container spacing={2} key={i}>

                            <Grid item xs={12} sm={2}>
                                {/*<FormControl className={classes.formControlMin}>*/}
                                {/*    <InputLabel>Truck</InputLabel>*/}
                                {/*    <Select*/}
                                {/*        name={`service_lines.${i}.machine`}*/}
                                {/*        onChange={formik.handleChange}*/}
                                {/*        value={(serviceLineValue && serviceLineValue.machine) || ''}*/}
                                {/*        error={serviceLineErrors.machine && Boolean(serviceLineTouched.machine)}*/}
                                {/*        helperText={serviceLineErrors.machine && serviceLineTouched.machine}*/}
                                {/*    >*/}
                                {/*        <MenuItem value="" key="select-value">Select Value</MenuItem>*/}
                                {/*        {machines && machines.map((m, idx) => <MenuItem*/}
                                {/*            value={m.url}*/}
                                {/*            key={idx}>{m.reg_no}</MenuItem>)}*/}
                                {/*    </Select>*/}
                                {/*</FormControl>*/}

                                <Autocomplete
                                    filterOptions={utils.autocompleteFilterOptions}
                                    className={classes.formControlMin}
                                    disablePortal
                                    options={machines}
                                    getOptionLabel={option => option.reg_no}
                                    name={`service_lines.${i}.machine`}
                                    defaultValue={(serviceLineValue && serviceLineValue.machine) || null}
                                    getOptionSelected={(option, value) => option.url === value.url}
                                    onInputChange={(event, value) => {
                                        handleInputChange(event, `service_lines.${i}.machine`, serviceLineValue.machine, formik.setFieldValue);
                                    }}
                                    onChange={(_, value) => value != null ? formik.setFieldValue(`service_lines.${i}.machine`, value.url) : ''}
                                    renderInput={(params) => <TextField {...params}
                                                                        error={serviceLineErrors.machine && Boolean(serviceLineTouched.machine)}
                                                                        helperText={serviceLineErrors.machine && serviceLineTouched.machine}
                                                                        name={`service_lines.${i}.machine`}
                                                                        label="Truck"/>}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                {/*<FormControl className={classes.formControl}>*/}
                                {/*    <InputLabel>Product</InputLabel>*/}
                                {/*    <Select*/}
                                {/*        name={`service_lines.${i}.product`}*/}
                                {/*        value={(serviceLineValue && serviceLineValue.product) || ''}*/}
                                {/*        error={serviceLineErrors.product && Boolean(serviceLineTouched.product)}*/}
                                {/*        helperText={serviceLineErrors.product && serviceLineTouched.product}*/}
                                {/*        onChange={(e) => {*/}
                                {/*            formik.handleChange(e);*/}
                                {/*            onSelectProduct(e, i);*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        <MenuItem value="" key="select-value">Select Value</MenuItem>*/}
                                {/*        {products && products.map((p, idx) => <MenuItem*/}
                                {/*            value={p.url} data-id={p.id} key={idx}>{p.name}</MenuItem>)}*/}
                                {/*    </Select>*/}
                                {/*</FormControl>*/}

                                <Autocomplete
                                    filterOptions={utils.autocompleteFilterOptions}
                                    className={classes.formControl}
                                    disablePortal
                                    options={products}
                                    getOptionLabel={option => option.name}
                                    name={`service_lines.${i}.product`}
                                    defaultValue={(serviceLineValue && serviceLineValue.product) || null}
                                    getOptionSelected={(option, value) => option.url === value.url}
                                    onInputChange={(event, value) => {
                                        handleInputChange(event, `service_lines.${i}.product`, serviceLineValue.product, formik.setFieldValue);
                                    }}
                                    onChange={(e, value) => {
                                        handleProductChange(i ,`service_lines.${i}.product`, value, formik.setFieldValue);
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                                                        error={serviceLineErrors.product && Boolean(serviceLineTouched.product)}
                                                                        helperText={serviceLineErrors.product && serviceLineTouched.product}
                                                                        name={`service_lines.${i}.product`}
                                                                        label="Product"/>}
                                    classes={{paper: classes.autocomplete}}
                                />
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <FormControl className={classes.formControlMin}>
                                    <InputLabel>Attached To</InputLabel>
                                    <Select
                                        name={`service_lines.${i}.attached_to`}
                                        onChange={formik.handleChange}
                                        value={(serviceLineValue && serviceLineValue.attached_to) || ''}
                                        error={serviceLineErrors.attached_to && Boolean(serviceLineTouched.attached_to)}
                                        helperText={serviceLineErrors.attached_to && serviceLineTouched.attached_to}
                                    >
                                        <MenuItem value="" key="select-value">Select Value</MenuItem>
                                        {attached && attached.map((m, idx) => <MenuItem
                                            value={m.url}
                                            key={idx}>{m.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {productPurchase &&
                            <Grid item xs={12} sm={2}><Button color="primary" className={classes.marginTop}
                                                              onClick={e => updatePurchaseLines(i)}> View
                                Quantity</Button></Grid>}
                            <Grid item xs={12} sm={1}></Grid>


                            <Grid item xs={2} sm={1}>

                                {serviceLines.length !== 1 && serviceLines.length - 1 !== i &&
                                <IconButton className={classes.danger} aria-label="Remove line" component="span"
                                            onClick={() => handleRemoveServiceLine(i)}>
                                    <RemoveIcon/>
                                </IconButton>}
                            </Grid>
                        </Grid>)
                    })}


                    <Grid container>
                        <Grid item>
                            <Button className={classes.info} aria-label="Add line" size="small"
                                    onClick={() => handleAddServiceLine()}> <AddIcon/> Add Line
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-end" className={classes.marginTop}>
                        <Grid item xs={6} sm={2}>
                            <Button
                                type="submit"
                                size="small"
                                variant="contained"
                                className={classes.success}
                                disabled={formik.isSubmitting}
                                onClick={() => formik.setFieldValue('status', 1)}
                            >
                                Submit for approval
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={1}>
                            <Button
                                type="submit"
                                size="small"
                                variant="contained"
                                color="default"
                                className={classes.primary}
                                disabled={formik.isSubmitting}
                                onClick={() => formik.setFieldValue('status', 0)}
                            > Save </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </SiteWrapper>
    );
}

export default AddEditServicePage;

import {
    contentTypeApplicationJson,
} from "../constants/httpConstants";

import {httpWrapper} from "../helper/Http";

export const getAdjustments = async (data) => {
    return httpWrapper.get('/stock-adjustments/', contentTypeApplicationJson, data);
};


export const addAdjustment = async (adjustment) => {
    return httpWrapper.post('/stock-adjustments/' , adjustment, contentTypeApplicationJson);
};
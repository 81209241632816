import React, {useEffect, useRef} from 'react';
import Grid from "@material-ui/core/Grid";
import BreadCrumb from "../../components/BreadCrumb";


import {Link, withRouter} from 'react-router-dom';
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import Box from "@material-ui/core/Box";
import useStyles from "../../styles";
import {useDispatch, useSelector} from "react-redux";
import {deleteSaleAction, getSalesAction} from "../../store/actions/SaleAction";
import EnhancedTable from "../../components/EnhancedTable";
import SiteWrapper from "../../SiteWrapper";
import Typography from "@material-ui/core/Typography";
import MenuActionComponent from "../../components/MenuActionComponent";
import {utils} from "../../helper/Utils";
import ApprovalStatusComponent from "../../components/ApprovalStatusComponent";
import dayjs from "dayjs";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import {useFormik} from "formik";
import * as Yup from "yup";


const SalesPage = () => {

    const classes = useStyles();

    const dispatch = useDispatch();
    const sales = useSelector(state => state.sale.sales);
    const count = useSelector(state => state.sale.count);

    const fetchIdRef = useRef(0);

    const fetchData = React.useCallback((pager, query) => {

        let search = Object.assign({}, {limit: pager.pageSize, offset: pager.pageIndex * pager.pageSize}, query);

        const fetchId = ++fetchIdRef.current;

        if (fetchId === fetchIdRef.current) {
            dispatch(getSalesAction(search));
        }

    }, [dispatch]);

    useEffect(() => {
        fetchData({pageIndex: utils.DEFAULT_PAGE_INDEX, pageSize: utils.DEFAULT_PAGE_SIZE});
    }, [fetchData]);


    const columns = [
        {
            Header: "Date",
            accessor: d => (dayjs(d.effective_date).format(utils.dateFormat))
        },
        {
            Header: "Invoice",
            accessor: d => (d.invoice ? d.invoice : "-")
        },
        {
            Header: "Manual Invoice",
            accessor: d => (d.invoice_no ? d.invoice_no : "-")
        },
        {
            Header: "Customer",
            accessor: d => (d.customer ? d.customer.name : "-")
        },
        {
            Header: "Truck",
            accessor: d => (d.truck ? d.truck.reg_no : "-")
        },
        {
            Header: "Trip",
            accessor: d => (d.trip && d.trip.route ? d.trip.route.name : "-")
        },
        {
            Header: "Status",
            accessor: d => <ApprovalStatusComponent status={d.status}/>
        },
        {
            Header: 'Action',
            Cell: row => (<MenuActionComponent item={{
                id: row.row.original.id,
                showView: true,
                hideEdit: row.row.original.status !== 'Saved',
                hideDelete: row.row.original.status !== 'Saved'
            }}
                                               url={"sales"} deleteAction={deleteSaleAction}
            />)
        }
    ];

    const formik = useFormik({

        initialValues: {
            start_date: dayjs().subtract(utils.filterStart, 'day').format("YYYY-MM-DD"),
            end_date: dayjs().format("YYYY-MM-DD"),
        },

        validationSchema: Yup.object().shape({
            start_date: Yup.date("YYYY-mm-dd").required('Start date is required'),
            end_date: Yup.date("YYYY-mm-dd").required('End date is required'),
        }),

        onSubmit(values, {setSubmitting}) {

            fetchData({
                pageIndex: utils.DEFAULT_PAGE_INDEX,
                pageSize: utils.DEFAULT_PAGE_SIZE
            }, values);
            setSubmitting(false);
        },

        enableReinitialize: true
    });

    return (
        <>
            <SiteWrapper>
                <BreadCrumb crumbs={[{
                    'name': 'Sales',
                    'link': '#'
                }]}/>
                <Grid container>
                    <Grid container className={classes.marginTop}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <Typography className={clsx(classes.medium, classes.pageTitle)}>
                                    Sales
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid container className={classes.marginTop}>
                            <form className={classes.form} noValidate>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <MuiPickersUtilsProvider utils={DayjsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="Start Date"
                                                maxDate={utils.maxDate}
                                                format="MM/DD/YYYY"
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                value={formik.values.start_date}
                                                onChange={val => formik.setFieldValue('start_date', dayjs(val).format("YYYY-MM-DD"))}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <MuiPickersUtilsProvider utils={DayjsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="End Date"
                                                maxDate={utils.maxDate}
                                                format="MM/DD/YYYY"
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                value={formik.values.end_date}
                                                onChange={val => formik.setFieldValue('end_date', dayjs(val).format("YYYY-MM-DD"))}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                            </form>
                            <Grid container justify="center">
                                <Button
                                    onClick={formik.handleSubmit}
                                    type="button"
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    className={clsx(classes.primary, classes.formsWithMui)}
                                    disabled={formik.isSubmitting}
                                >
                                    Filter
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sm={8} justify="flex-end">
                            <Box>
                                <Button className={classes.actionButton} startIcon={<AddIcon/>}>
                                    <Link to="/sales/create" className={classes.unstyledLink}>New Sale</Link>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    {/* Recent Deposits */}
                    <Grid item xs={12} md={12} lg={12}>
                        {sales && <EnhancedTable
                            columns={columns}
                            data={sales}
                            fetchData={fetchData}
                            pageCount={count && count}
                        />}
                    </Grid>
                </Grid>
            </SiteWrapper>
        </>
    );
};

export default withRouter(SalesPage);
import {routeConstants} from "../ActionTypes";

const initialState = {
    routes: [],
    count: 0,
    route: {},
    isLoading: false
};

function RouteReducer(state = initialState, action) {

    switch (action.type) {
        case routeConstants.PENDING_ROUTE_REQUEST:
            return {...state, isLoading: true};
        case routeConstants.GET_ROUTES_SUCCESS:
            return {...state, routes: action.payload.results, count: action.payload.count, isLoading: false};
        case routeConstants.GET_ROUTES_ERROR:
            return {...state, isLoading: false};
        case routeConstants.ADD_ROUTE_SUCCESS:
            return {...state, routes: state.routes.concat(action.payload), isLoading: false};
        case routeConstants.ADD_ROUTE_ERROR:
            return {...state, isLoading: false};
        case routeConstants.EDIT_ROUTE_SUCCESS:
            return {...state, route: action.payload, isLoading: false};
        case routeConstants.EDIT_ROUTE_ERROR:
            return {...state, isLoading: false};
        case routeConstants.DELETE_ROUTE_SUCCESS:
            return {
                ...state,
                routes: state.routes.filter((route) => route.id !== action.payload),
                count: state.count - 1,
                isLoading: false
            };
        case routeConstants.DELETE_ROUTE_ERROR:
            return {...state.routes, isLoading: false};
        default:
            return state;
    }
}

export default RouteReducer;
import {userConstants} from "../ActionTypes";

const initialState = {
    users: [],
    count: 0,
    user: {},
    isLoading: false
};

function UserReducer(state = initialState, action) {

    switch (action.type) {
        case userConstants.PENDING_USER_REQUEST:
            return {...state, isLoading: true};
        case userConstants.GET_USERS_SUCCESS:
            return {...state, users: action.payload.results, count: action.payload.count, isLoading: false};
        case userConstants.GET_USERS_ERROR:
            return {...state, isLoading: false};
        case userConstants.ADD_USER_SUCCESS:
            return {...state, users: state.users.concat(action.payload), isLoading: false};
        case userConstants.ADD_USER_ERROR:
            return {...state, isLoading: false};
        case userConstants.EDIT_USER_SUCCESS:
            return {...state, user: action.payload, isLoading: false};
        case userConstants.EDIT_USER_ERROR:
            return {...state, isLoading: false};
        case userConstants.DELETE_USER_SUCCESS:
            return {
                ...state, users: state.users.filter((user) => user.id !== action.payload),
                count: state.count - 1,
                isLoading: false
            };
        case userConstants.DELETE_USER_ERROR:
            return {...state.users, isLoading: false};
        default:
            return state;
    }
}

export default UserReducer;
import React, {useEffect, useRef, useState} from 'react';
import Grid from "@material-ui/core/Grid";


import {withRouter} from 'react-router-dom';
import Box from "@material-ui/core/Box";
import useStyles from "../../styles";
import {useDispatch, useSelector} from "react-redux";
import EnhancedTable from "../../components/EnhancedTable";
import SiteWrapper from "../../SiteWrapper";
import Typography from "@material-ui/core/Typography";
import {httpWrapper} from "../../helper/Http";
import {contentTypeApplicationJson} from "../../constants/httpConstants";
import {utils} from "../../helper/Utils";
import {useFormik} from "formik";
import dayjs from "dayjs"
import * as Yup from "yup";
import {getMachinesAction} from "../../store/actions/MachineAction";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import {getProductsAction} from "../../store/actions/ProductAction";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import ExportToPdfComponent from "../../components/ExportToPdfComponent";
import BreadCrumb from "../../components/BreadCrumb";
import TextField from "@material-ui/core/TextField/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";


const ExpensesSummaryPage = () => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const [expenses, setExpenses] = useState([]);
    const machines = useSelector(state => state.machine.machines);

    const [total, setTotal] = useState(0.00);
    const [count, setCount] = useState(0);

    const fetchIdRef = useRef(0);

    const url = '/reports/expenses-summary/';


    const fetchData = React.useCallback((query, cb) => {

        const fetchId = ++fetchIdRef.current;

        if (fetchId === fetchIdRef.current) {

            httpWrapper.get(url, contentTypeApplicationJson, query).then(
                (response) => {
                    if (response.data) {
                        setExpenses(response.data.results);
                        setCount(response.data.results.length);
                        setTotal(utils.numberFormatter(response.data.total));
                    }

                    cb && cb();
                },
                (error) => {

                    cb && cb();
                }
            );
        }
    }, []);

    useEffect(() => {

        dispatch(getMachinesAction());
        dispatch(getProductsAction());

        fetchData({
            start_date: utils.DEFAULT_START_DATE,
            end_date: utils.DEFAULT_END_DATE
        }, null);


    }, [dispatch, fetchData]);


    const formik = useFormik({

        initialValues: {

            start_date: utils.DEFAULT_START_DATE,
            end_date: utils.DEFAULT_END_DATE,
            machine: '',
            type: '',
            product: ''
        },

        validationSchema: Yup.object().shape({
            start_date: Yup.date("YYYY-mm-dd").required('Start date is required'),
            end_date: Yup.date("YYYY-mm-dd").required('End date is required'),
        }),

        onSubmit(values) {

            fetchData(values, callback);
        },

        enableReinitialize: true

    });

    const callback = () => {
        formik.setSubmitting(false);
    };

    const columns = [
        {
            Header: "Truck",
            accessor: "truck"
        },
        {
            Header: "Spare Parts",
            accessor: (d => utils.numberFormatter(d.spare))
        },
        {
            Header: "Fuel",
            accessor: (d => utils.numberFormatter(d.fuel))
        },
        {
            Header: "Others",
            accessor: (d => utils.numberFormatter(d.others))
        },
        {
            Header: "Total",
            accessor: (d => utils.numberFormatter(d.line_total))
        }
    ];

    return (
        <>
            <SiteWrapper>
                <BreadCrumb crumbs={[{
                    'name': 'Reports',
                    'link': '/reports'
                },
                    {
                        'name': 'Expenses Summary',
                        'link': '#'
                    }]}/>
                <Grid container className={classes.marginTop}>
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <Typography className={classes.medium}>
                                Expenses Summary
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid container item xs={12} sm={8} justify="flex-end">
                        <Box>
                            {expenses && <ExportToPdfComponent url={url} values={formik.values}/>}
                        </Box>
                    </Grid>
                </Grid>

                <Grid container className={classes.marginTop}>

                    <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>

                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={3}>
                                <MuiPickersUtilsProvider utils={DayjsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Start Date"
                                        format="MM/DD/YYYY"
                                        maxDate={utils.maxDate}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        value={formik.values.start_date}
                                        onChange={val => formik.setFieldValue('start_date', dayjs(val).format("YYYY-MM-DD"))}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>


                            <Grid item xs={12} sm={3}>
                                <MuiPickersUtilsProvider utils={DayjsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="End Date"
                                        format="MM/DD/YYYY"
                                        maxDate={utils.maxDate}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        value={formik.values.end_date}
                                        onChange={val => formik.setFieldValue('end_date', dayjs(val).format("YYYY-MM-DD"))}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>


                            <Grid item xs={12} sm={4}>
                                {/*<FormControl className={clsx(classes.formControlMin, classes.formsWithMui)}>*/}
                                {/*    <InputLabel>Truck</InputLabel>*/}
                                {/*    <Select*/}
                                {/*        name="machine"*/}
                                {/*        onChange={formik.handleChange}*/}
                                {/*        value={formik.values.machine || ''}*/}
                                {/*        error={formik.touched.machine && Boolean(formik.errors.machine)}*/}
                                {/*        helperText={formik.touched.machine && formik.errors.machine}*/}
                                {/*    >*/}
                                {/*        <MenuItem value="">Select Value</MenuItem>*/}
                                {/*        {machines && machines.map((m, idk) => <MenuItem*/}
                                {/*            value={m.reg_no}*/}
                                {/*            key={idk}>{m.reg_no}</MenuItem>)}*/}
                                {/*    </Select>*/}
                                {/*</FormControl>*/}

                                <Autocomplete
                                    filterOptions={utils.autocompleteFilterOptions}
                                    className={clsx(classes.formControlMin, classes.formsWithMui)}
                                    disablePortal
                                    options={machines}
                                    getOptionLabel={option => option.reg_no}
                                    name='machine'
                                    defaultValue={formik.values.machine || null}
                                    getOptionSelected={(option, value) => option.reg_no === value.reg_no}
                                    onChange={(_, value) => value != null ? formik.setFieldValue('machine', value.reg_no) : ''}
                                    renderInput={(params) => <TextField {...params}
                                                                        error={formik.errors.machine && Boolean(formik.touched.machine)}
                                                                        helperText={formik.errors.machine && formik.touched.machine}
                                                                        name={'machine'}
                                                                        label="Truck"/>}
                                />
                            </Grid>

                        </Grid>
                    </form>
                    <Grid container justify="center">
                        <Button
                            onClick={formik.handleSubmit}
                            type="button"
                            size="small"
                            variant="contained"
                            color="secondary"
                            className={clsx(classes.primary, classes.formsWithMui)}
                            disabled={formik.isSubmitting}
                        >
                            Filter
                        </Button>
                    </Grid>
                </Grid>

                <Grid container justify="flex-end" className={classes.marginTop}>
                    {/* Recent Deposits */}
                    <Grid item xs={12} md={12} lg={12}>
                        {expenses && <EnhancedTable
                            columns={columns}
                            data={expenses}
                            pageCount={count}
                            summaries={[{
                                "title": "Total",
                                "value": total,
                                "colSpan": 3,
                                "freeSpan": 1
                            }]}

                        />}
                    </Grid>

                </Grid>
            </SiteWrapper>
        </>
    );
};

export default withRouter(ExpensesSummaryPage);
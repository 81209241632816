import React, {useEffect} from 'react';

import SiteWrapper from "../../SiteWrapper";
import {useDispatch, useSelector} from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import useStyles from "../../styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {utils} from "../../helper/Utils";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Link, withRouter} from "react-router-dom";
import {deleteServiceAction, getServiceAction} from "../../store/actions/ServiceAction";
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import MenuActionComponent from "../../components/MenuActionComponent";
import dayjs from "dayjs";


const ServicePage = ({match}) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const {id} = match.params;

    const service = useSelector(state => state.service.service);

    useEffect(() => {
        dispatch(getServiceAction(id));
    }, [dispatch, id]);


    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[{
                'name': 'Services',
                'link': '/services'
            }]}/>


            {service ?
                <div>
                    <Grid container className={classes.marginTop}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <Typography className={classes.pageTitle}>
                                    {service.reference}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid container item xs={12} md={8} justify="flex-end">
                            <Box>
                                <Button className={classes.actionButton} startIcon={<AddIcon/>}>
                                    <Link to="/services/create" className={classes.unstyledLink}>New Service</Link>
                                </Button>
                            </Box>
                            <Box>
                                <MenuActionComponent item={{
                                    id: service.id,
                                    showView: false,
                                    hideEdit: service.status !== 'Saved',
                                    hideDelete: service.status !== 'Saved'
                                }}
                                    url={"services"} deleteAction={deleteServiceAction}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.marginTop}>
                        <Grid item xs={12} sm={2}>
                            <Box mr={2}>
                                <Box mt={3}></Box>
                                <Box mt={2}>
                                    <Typography variant="caption">
                                        Date
                                    </Typography>
                                    <Typography variant="body1">
                                        {dayjs(service.service_date).format(utils.dateFormat)}
                                    </Typography>
                                </Box>
                                {service.created_by && <Box mt={2}>
                                    <Typography variant="caption">
                                        Created By
                                    </Typography>
                                    <Typography variant="body1">
                                        {service.created_by.full_name}
                                    </Typography>
                                </Box>}


                                {service.created_at && <Box mt={2}>
                                    <Typography variant="caption">
                                        Created At
                                    </Typography>
                                    <Typography variant="body1">
                                        {dayjs(service.created_at).format(utils.dateFormat)}
                                    </Typography>
                                </Box>}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <Paper className={classes.paper}>

                                <Grid container spacing={4}>
                                    <Grid item><Typography variant="subtitle1">Service Items</Typography>
                                    </Grid></Grid>


                                <Grid container spacing={2}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Product</TableCell>
                                                <TableCell>Truck</TableCell>
                                                <TableCell>Serial</TableCell>
                                                <TableCell>Quantity</TableCell>
                                                <TableCell align="right">Buying Price</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                service.service_lines && service.service_lines.map((line, i) => {

                                                    return line.service_line_items && line.service_line_items.map((item, j) => {
                                                        return (
                                                            <TableRow key={j}>
                                                                <TableCell>{line.product.name}</TableCell>
                                                                <TableCell>{line.machine.reg_no}</TableCell>
                                                                <TableCell>{item.sn_no ?? '-'}</TableCell>
                                                                <TableCell>{utils.numberFormatter(item.quantity_serviced)}</TableCell>
                                                                <TableCell
                                                                    align="right">{utils.numberFormatter(item.buying_price)}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid></div> :

                <Grid container className={classes.marginTop}>
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <Typography>
                                Oops! Service not found. View all services
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            }
        </SiteWrapper>
    )
};

export default withRouter(ServicePage);
import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import SiteWrapper from "../../SiteWrapper";
import {useDispatch, useSelector} from "react-redux";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import useStyles from "../../styles";
import {getSettings} from "../../services/SettingService";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import BreadCrumb from "../../components/BreadCrumb";
import Switch from "@material-ui/core/Switch";
import {alertActions} from "../../store/actions/AlertAction";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import dayjs from "dayjs";
import {addEmployeeAction, editEmployeeAction} from "../../store/actions/EmployeeAction";
import DayjsUtils from "@date-io/dayjs";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {utils} from "../../helper/Utils";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

function AddEditEmployeePage({history, match}) {

    const classes = useStyles();

    const {id} = match.params;

    const isAddMode = !id;

    const dispatch = useDispatch();

    const [departments, setDepartment] = useState([]);
    const [designations, setDesignation] = useState([]);

    const employees = useSelector(state => state.employee.employees);

    const employee = employees.find(employee => employee.id === parseInt(id));


    useEffect(() => {

        getSettings({group: 'departments'}).then(
            (response) => {
                if (response.data) {
                    setDepartment(response.data.results)
                }
            },
            (error) => {
                if (error.response) {
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );

        getSettings({group: 'designations'}).then(
            (response) => {
                if (response.data) {
                    setDesignation(response.data.results)
                }
            },
            (error) => {
                if (error.response) {
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );

    }, [dispatch]);

    const formik = useFormik({

        initialValues: {

            employee_no: employee ? employee.employee_no : '',
            first_name: employee ? employee.first_name : '',
            middle_name: employee ? employee.middle_name : '',
            last_name: employee ? employee.last_name : '',
            email: employee ? employee.email : '',
            phone_number: employee ? employee.phone_number : '',
            gender: employee ? employee.gender : '',
            designation: employee && employee.designation ? employee.designation.url : '',
            department: employee && employee.department ? employee.department.url : '',
            join_date: employee ? employee.join_date : dayjs(new Date()).format("YYYY-MM-DD"),
            is_active: employee ? employee.is_active : false,

        },

        validationSchema: Yup.object().shape({
            join_date: Yup.date("YYYY-mm-dd").required('Join date is required'),
            employee_no: Yup.string().nullable(),
            first_name: Yup.string().required('First Name is required'),
            middle_name: Yup.string().nullable(),
            last_name: Yup.string().required('Last Name is required'),
            phone_number: Yup.number().required('Phone number is required'),
            gender: Yup.string().required('Gender is required'),
            designation: Yup.string().required('Designation is required'),
            department: Yup.string().required('Department is required'),
            email: Yup.string().email('Enter a valid email address').nullable(),
        }),

        onSubmit(values) {

            isAddMode ? dispatch(addEmployeeAction(values, errorCallback)) : dispatch(editEmployeeAction(id, values, errorCallback));
        },

        enableReinitialize: true

    });

    const errorCallback = (error) => {
        formik.setSubmitting(false);
    };

    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[{
                'name': 'Employees',
                'link': '/employees'
            }]}/>


            <Grid container className={classes.marginTop}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography className={clsx(classes.pageTitle)}>
                            New Employee
                        </Typography>
                    </Box>
                </Grid>
            </Grid>


            <Paper className={classes.paper}>
                <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="employee_no"
                                variant="outlined"
                                required
                                fullWidth
                                id="employee_no"
                                label="Employee No"
                                value={formik.values.employee_no || ''} onChange={formik.handleChange}
                                error={formik.touched.employee_no && Boolean(formik.errors.employee_no)}
                                helperText={formik.touched.employee_no && formik.errors.employee_no}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="first_name"
                                variant="outlined"
                                required
                                fullWidth
                                id="first_name"
                                label="First Name"
                                value={formik.values.first_name || ''} onChange={formik.handleChange}
                                error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                helperText={formik.touched.first_name && formik.errors.first_name}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="middle_name"
                                variant="outlined"
                                required
                                fullWidth
                                id="middle_name"
                                label="Middle Name"
                                value={formik.values.middle_name || ''} onChange={formik.handleChange}
                                error={formik.touched.middle_name && Boolean(formik.errors.middle_name)}
                                helperText={formik.touched.middle_name && formik.errors.middle_name}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="last_name"
                                variant="outlined"
                                required
                                fullWidth
                                id="last_name"
                                label="Last Name"
                                value={formik.values.last_name || ''} onChange={formik.handleChange}
                                error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                helperText={formik.touched.last_name && formik.errors.last_name}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                type="email"
                                name="email"
                                variant="outlined"
                                fullWidth
                                id="email"
                                label="Email"
                                value={formik.values.email || ''} onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="phone_number"
                                variant="outlined"
                                fullWidth
                                id="phone_number"
                                label="Phone Number"
                                value={formik.values.phone_number || ''} onChange={formik.handleChange}
                                error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                                helperText={formik.touched.phone_number && formik.errors.phone_number}
                            />
                        </Grid>

                        <Grid item xs={4} sm={2}>
                            <MuiPickersUtilsProvider utils={DayjsUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Join Date"
                                    maxDate={utils.maxDate}
                                    format="MM/DD/YYYY"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    value={formik.values.join_date}
                                    onChange={val => formik.setFieldValue('join_date', dayjs(val).format("YYYY-MM-DD"))}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={4} sm={3}>
                            <FormControl className={clsx(classes.formControl, classes.formsWithMui)}>
                                <InputLabel>Department</InputLabel>
                                <Select
                                    name='department'
                                    onChange={formik.handleChange}
                                    value={formik.values.department || ''}
                                    error={formik.touched.department && Boolean(formik.errors.department)}
                                    helperText={formik.touched.department && formik.errors.department}
                                >
                                    {departments && departments.map((m, idx) => <MenuItem
                                        value={m.url}
                                        key={idx}>{m.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={4} sm={3}>
                            <FormControl className={clsx(classes.formControl, classes.formsWithMui)}>
                                <InputLabel>Designation</InputLabel>
                                <Select
                                    name='designation'
                                    onChange={formik.handleChange}
                                    value={formik.values.designation || ''}
                                    error={formik.touched.designation && Boolean(formik.errors.designation)}
                                    helperText={formik.touched.designation && formik.errors.designation}
                                >
                                    {designations && designations.map((m, idx) => <MenuItem
                                        value={m.url}
                                        key={idx}>{m.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={4} sm={2}>
                            <FormControl className={clsx(classes.formControlMin, classes.formsWithMui)}>
                                <InputLabel>Gender</InputLabel>
                                <Select
                                    name='gender'
                                    onChange={formik.handleChange}
                                    value={formik.values.gender || ''}
                                    error={formik.touched.gender && Boolean(formik.errors.gender)}
                                    helperText={formik.touched.gender && formik.errors.gender}
                                >
                                    <MenuItem value="Male" key="male">Male</MenuItem>
                                    <MenuItem value="Female" key="female">Female</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>


                        <Grid item xs={6} sm={1}>

                            <FormControlLabel
                                className={clsx(classes.formsWithMui)}
                                control={
                                    <Switch
                                        checked={formik.values.status}
                                        onChange={event => formik.setFieldValue('is_active', event.target.checked)}
                                        color="primary"
                                        name="Active"
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                }
                                label="Active"
                            />
                        </Grid>


                        <Grid container justify="flex-end">

                            <Grid item xs={6} sm={1}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="default"
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                > Cancel </Button>

                            </Grid>

                            <Grid item xs={6} sm={1}>
                                <Button
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.success}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </form>
            </Paper>
        </SiteWrapper>
    );
}

export default AddEditEmployeePage;

import {BASE_URL} from "../constants";
import axios from "axios";
import {utils} from "../helper/Utils";

export const loginService = (data) => {

    const url = utils.getScheme() + data.company + BASE_URL + "/login/";

    return axios
        .post(url, data)
        .then((response) => {

            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response;
        });
};

export const logoutService = () => {
    localStorage.removeItem('user');
};

import {
    contentTypeApplicationJson,
} from "../constants/httpConstants";

import {httpWrapper} from "../helper/Http";

export const getWorkflowProcesses = async (data) => {
    return httpWrapper.get('/workflow/processes/', contentTypeApplicationJson, data);
};

export const getWorkflowRules = async (data) => {
    return httpWrapper.get('/workflow/rules/', contentTypeApplicationJson, data);
};

export const getWorkflowRule = async (id) => {
    return httpWrapper.get('/workflow/rules/' + id, contentTypeApplicationJson);
};

export const addWorkflowRule = async (rule) => {
    return httpWrapper.post('/workflow/rules/' , rule, contentTypeApplicationJson);
};

export const editWorkflowRule = async (id, rules) => {
    return httpWrapper.update('/workflow/rules/' + id + '/' , rules, contentTypeApplicationJson);
};

export const deleteWorkflowRule = async (id) => {
    return httpWrapper.delete('/workflow/rules/' + id + '/' , contentTypeApplicationJson);
};

export const getWorkflowApprovers = async (data) => {
    return httpWrapper.get('/workflow/approvers/', contentTypeApplicationJson, data);
};

export const getWorkflowApprover = async (id) => {
    return httpWrapper.get('/workflow/approvers/' + id, contentTypeApplicationJson);
};

export const addWorkflowApprover = async (approver) => {
    return httpWrapper.post('/workflow/approvers/' , approver, contentTypeApplicationJson);
};

export const editWorkflowApprover = async (id, approver) => {
    return httpWrapper.update('/workflow/approvers/' + id + '/' , approver, contentTypeApplicationJson);
};

export const deleteWorkflowApprover = async (id) => {
    return httpWrapper.delete('/workflow/approvers/' + id + '/' , contentTypeApplicationJson);
};

export const getWorkflowTransactions = async (data) => {
    return httpWrapper.get('/workflow/transactions/', contentTypeApplicationJson, data);
};

export const approveWorkflowTransaction = async (data) => {
    return httpWrapper.post('/workflow/transactions/' + data.transaction_id + '/approve/', data, contentTypeApplicationJson);
};
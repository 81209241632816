import {auth} from "../helper/Auth";


export const contentTypeUrlEncoded = {
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },
};
export const contentTypeFormData = {
    headers: {
        "Content-Type": "multipart/form-data",
    },
};
export const contentTypeApplicationJson = {
    headers: {
        "Content-Type": "application/json",
        "Authorization" : "Token " + auth.getToken(),
        accept: "application/json",
    },
};

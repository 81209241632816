const getUser  = () => {

    const user = JSON.parse(localStorage.getItem("user"));

    if(user && user.token){

        return user.user;
    }

    return null;
};

const getToken = () => {

    const user = JSON.parse(localStorage.getItem("user"));

    if(user && user.token){

        return user.token;
    }

    return '';
};

export const auth = {
    getUser,
    getToken
};

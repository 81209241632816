import React, {useEffect} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import SiteWrapper from "../../SiteWrapper";
import {addSaleAction, editSaleAction} from "../../store/actions/SaleAction";
import {useDispatch, useSelector} from "react-redux";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import dayjs from "dayjs";
import Grid from "@material-ui/core/Grid";
import useStyles from "../../styles";
import Button from "@material-ui/core/Button";
import BreadCrumb from "../../components/BreadCrumb";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import {utils} from "../../helper/Utils";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import {withRouter} from 'react-router-dom';
import {getMachinesAction} from "../../store/actions/MachineAction";
import {getCustomersAction} from "../../store/actions/CustomerAction";
import {getTripsAction} from "../../store/actions/TripAction";

function AddEditSalePage({match}) {

    const classes = useStyles();

    const {id} = match.params;
    const isAddMode = !id;

    const dispatch = useDispatch();

    const customers = useSelector(state => state.customer.customers);
    const trips = useSelector(state => state.trip.trips);
    const trucks = useSelector(state => state.machine.machines);

    const sales = useSelector(state => state.sale.sales);
    const sale = sales.find(sale => sale.id === parseInt(id));

    useEffect(() => {

        dispatch(getMachinesAction());
        dispatch(getCustomersAction());
        sale && sale.trip && dispatch(getTripsAction({'id': sale.trip.id}));

    }, [dispatch, sale]);

    const formik = useFormik({

        initialValues: {
            effective_date: sale ? sale.effective_date : dayjs(new Date()).format("YYYY-MM-DD"),
            amount: sale ? sale.amount : 0.0,
            invoice_no: sale ? sale.invoice_no : '',
            customer: sale && sale.customer ? sale.customer.url : '',
            truck: sale && sale.truck ? sale.truck.url : '',
            trip: sale && sale.trip ? sale.trip.url : '',
        },

        validationSchema: Yup.object().shape({
            effective_date: Yup.date("YYYY-mm-dd").required('Effective date is required'),
            customer: Yup.string().required('Driver is required'),
            amount: Yup.number().required('Amount is required'),
            truck: Yup.string().required('Truck is required'),
            trip: Yup.string().required('Trip is required')
        }),

        onSubmit(values) {
            isAddMode ? dispatch(addSaleAction(values, errorCallback)) : dispatch(editSaleAction(id, values, errorCallback));
        },

        enableReinitialize: true
    });

    const errorCallback = (error) => {
        formik.setSubmitting(false);
    };

    const onSelectTruck = (value) => {
        if(null !== value && undefined !== value){
            let selectedTruck = trucks && trucks.find((truck) => truck.url === value);
            if(selectedTruck){
                dispatch(getTripsAction({'horse': selectedTruck.reg_no}));
            }
        }
    }

    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[{
                'name': 'New Sale',
                'link': '/sales/create'
            }]}/>

            <Grid container className={classes.marginTop}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography className={clsx(classes.medium, classes.pageTitle)}>
                            {!isAddMode ? 'Edit Sale' : 'New sale'}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Paper className={classes.paper}>
                <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                    <Grid container spacing={4}>
                        <MuiPickersUtilsProvider utils={DayjsUtils}>
                            <Grid item xs={4} sm={2}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Date"
                                    format="MM/DD/YYYY"
                                    maxDate={utils.maxDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    value={formik.values.effective_date}
                                    onChange={val => formik.setFieldValue('effective_date', dayjs(val).format("YYYY-MM-DD"))}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid container direction={'row'} spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="invoice_no"
                                variant="outlined"
                                required
                                fullWidth
                                id="invoice_no"
                                label="Invoice Number"
                                value={formik.values.invoice_no || ''} onChange={formik.handleChange}
                                error={formik.touched.invoice_no && Boolean(formik.errors.invoice_no)}
                                helperText={formik.touched.invoice_no && formik.errors.invoice_no}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="amount"
                                label="Amount"
                                name="amount"
                                value={formik.values.amount || ''} onChange={formik.handleChange}
                                error={formik.touched.amount && Boolean(formik.errors.amount)}
                                helperText={formik.touched.amount && formik.errors.amount}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <FormControl className={classes.formControl} error={formik.touched.customer && formik.errors.customer}>
                                <InputLabel>Customer</InputLabel>
                                <Select
                                    name="customer"
                                    onChange={formik.handleChange}
                                    value={formik.values.customer || ''}
                                >
                                    {customers && customers.map((m, idx) => <MenuItem
                                        value={m.url}
                                        key={idx}>{m.name}</MenuItem>)}
                                </Select>
                                {formik.touched.customer && formik.errors.customer && <FormHelperText>{formik.errors.customer}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <FormControl className={classes.formControl} error={formik.touched.truck && formik.errors.truck}>
                                <InputLabel>Truck</InputLabel>
                                <Select
                                    name="truck"
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        onSelectTruck(e.target.value);
                                    }}
                                    value={formik.values.truck || ''}
                                >
                                    {trucks && trucks.map((truck, tdx) => <MenuItem
                                        value={truck.url}
                                        key={tdx}>{truck.reg_no}</MenuItem>)}
                                </Select>
                                {formik.touched.truck && formik.errors.truck && <FormHelperText>{formik.errors.truck}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <FormControl className={classes.formControl} error={formik.touched.trip && formik.errors.trip}>
                                <InputLabel>Trip</InputLabel>
                                <Select
                                    disabled={trips && !trips.length > 0}
                                    name="trip"
                                    onChange={formik.handleChange}
                                    value={formik.values.trip || ''}
                                >
                                    {trips && trips.map((t, tdx) => <MenuItem
                                        value={t.url}
                                        key={tdx}>{dayjs(t.departure).format(utils.dateFormat) + "/" + t.horse.reg_no  + "/" + t.route.name}</MenuItem>)}
                                </Select>
                                {formik.touched.trip && formik.errors.trip && <FormHelperText>{formik.errors.trip}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid container justify="flex-end" className={classes.marginTop}>
                            <Grid item xs={6} sm={2}>
                                <Button
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    className={classes.success}
                                    disabled={formik.isSubmitting}
                                    onClick={() => formik.setFieldValue('status', 1)}
                                >
                                    Submit for approval
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={1}>
                                <Button
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    color="default"
                                    className={classes.primary}
                                    onClick={() => formik.setFieldValue('status', 0)}
                                    disabled={formik.isSubmitting}
                                > Save </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </SiteWrapper>
    );
}

export default withRouter(AddEditSalePage);
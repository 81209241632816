import React from 'react';

import useStyles from "../styles";
import Badge from "@material-ui/core/Badge";


function ApprovalStatusComponent(props) {

    const status = props.status;

    const classes = useStyles();

    if (undefined !== status && typeof status == 'string') {

        const lowerStatus = status.toLowerCase();

        if (lowerStatus === 'rejected') return <div ><Badge classes={{ badge: classes.danger}} className={classes.badge} variant="dot"/> {status}</div>;

        if (lowerStatus === 'processed') return <div><Badge classes={{ badge: classes.success}} className={classes.badge} variant="dot"/> {status}</div>;

        if (lowerStatus === 'approved') return <div><Badge classes={{ badge: classes.success}} className={classes.badge} variant="dot"/> {status}</div>;

        if (lowerStatus === 'pending') return <div><Badge classes={{ badge: classes.info}} className={classes.badge} variant="dot"/> {status}</div>;

        return <div><Badge classes={{ badge: classes.primary}} className={classes.badge}  variant="dot"/> {status}</div>
    }

    return <div><Badge classes={{ badge: classes.primary}} className={classes.badge} variant="dot"/> {status}</div>;
}

export default ApprovalStatusComponent;

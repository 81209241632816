import React, {useEffect, useRef} from 'react';
import Grid from "@material-ui/core/Grid";
import BreadCrumb from "../../components/BreadCrumb";


import {Link, withRouter} from 'react-router-dom';
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import Box from "@material-ui/core/Box";
import useStyles from "../../styles";
import {useDispatch, useSelector} from "react-redux";
import EnhancedTable from "../../components/EnhancedTable";
import SiteWrapper from "../../SiteWrapper";
import {deleteProductAction, getProductsAction} from "../../store/actions/ProductAction";
import StatusComponent from "../../components/StatusComponent";
import {utils} from "../../helper/Utils";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import MenuActionComponent from "../../components/MenuActionComponent";


const ProductsPage = () => {

    const classes = useStyles();

    const dispatch = useDispatch();
    const products = useSelector(state => state.product.products);
    const count = useSelector(state => state.product.count);

    const fetchIdRef = useRef(0);

    const fetchData = React.useCallback((pager, query) => {

        let search = Object.assign({}, {limit: pager.pageSize, offset: pager.pageIndex * pager.pageSize}, query);

        const fetchId = ++fetchIdRef.current;

        if (fetchId === fetchIdRef.current) {
            dispatch(getProductsAction(search));
        }

    }, [dispatch]);


    useEffect(() => {
        fetchData({pageIndex: utils.DEFAULT_PAGE_INDEX, pageSize: utils.DEFAULT_PAGE_SIZE});
    }, [fetchData]);


    const columns = [

        {
            Header: "Code",
            accessor: d => (d.code ?? '-')
        },
        {
            Header: "Name",
            accessor: 'name'
        },
        {
            Header: "Quantity",
            accessor: d => (d.quantity ? utils.numberFormatter(d.quantity) : '-')
        },
        {
            Header: "Unit",
            accessor: d => (d.uom.name ?? '-')
        },
        {
            Header: "Category",
            accessor: d => (d.category.name ?? '-')
        },
        {
            Header: "Status",
            accessor: d => <StatusComponent status={d.status}/>
        },
        {
            Header: 'Action',
            Cell: row => (<MenuActionComponent item={{id: row.row.original.id, showView: false}} url={"parts"}
                                           deleteAction={deleteProductAction}/>)
        }
    ];

    return (
        <>
            <SiteWrapper>
                <BreadCrumb crumbs={[{
                    'name': 'Spare Parts',
                    'link': '#'
                }]}/>
                <Grid container>

                    <Grid container className={classes.marginTop}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <Typography className={clsx(classes.medium, classes.pageTitle)}>
                                    Spare parts
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid container item xs={12} sm={8} justify="flex-end">
                            <Box>
                                <Button className={classes.actionButton} startIcon={<AddIcon/>}>
                                    <Link to="/parts/create" className={classes.unstyledLink}>New Part</Link>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                    {/* Recent Deposits */}
                    <Grid item xs={12} md={12} lg={12}>
                        {products && <EnhancedTable
                            columns={columns}
                            data={products}
                            fetchData={fetchData}
                            pageCount={count && count}
                        />}
                    </Grid>

                </Grid>
            </SiteWrapper>
        </>
    );
};

export default withRouter(ProductsPage);
import { push } from 'connected-react-router';

import {
    getTrips,
    getTrip,
    addTrip,
    editTrip,
    deleteTrip
} from "../../services/TripService";


import {tripConstants} from "../ActionTypes";

import {alertActions} from "./AlertAction";

export const getTripsAction = (data) => {

    return (dispatch) => {
        dispatch({
            type: tripConstants.PENDING_TRIP_REQUEST
        });
        return getTrips(data).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: tripConstants.GET_TRIPS_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: tripConstants.GET_TRIPS_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};

export const getTripAction = (data) => {

    return (dispatch) => {
        dispatch({
            type: tripConstants.PENDING_TRIP_REQUEST
        });
        return getTrip(data).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: tripConstants.GET_TRIP_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: tripConstants.GET_TRIP_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};


export const addTripAction = (trip, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: tripConstants.PENDING_TRIP_REQUEST
        });
        return addTrip(trip).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: tripConstants.ADD_TRIP_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/trips'));
                    dispatch(alertActions.success('Trip added successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: tripConstants.ADD_TRIP_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};


export const editTripAction = (id, trip, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: tripConstants.PENDING_TRIP_REQUEST
        });
        return editTrip(id, trip).then(
            (response) => {
                if (response.data) {

                    dispatch({
                        type: tripConstants.EDIT_TRIP_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/trips/' + id));
                    dispatch(alertActions.success('Trip edited successful'));

                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: tripConstants.EDIT_TRIP_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};

export const deleteTripAction = (id) => {
    return (dispatch) => {
        dispatch({
            type: tripConstants.PENDING_TRIP_REQUEST
        });
        return deleteTrip(id).then(
            (response) => {
                if (response) {
                    dispatch({
                        type: tripConstants.DELETE_TRIP_SUCCESS,
                        payload: id
                    });
                    dispatch(push('/trips'));
                    dispatch(alertActions.success('Trip deleted successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: tripConstants.DELETE_TRIP_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};
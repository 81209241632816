import React from "react";

import { useState } from "react";
import ConfirmDialogContext from "./ConfirmDialogContext";


function ConfirmDialogProvider({ children, ...props }) {

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        text: "",
        handler: null
    });

    return (
        <ConfirmDialogContext.Provider value={{ confirmDialog, setConfirmDialog }} {...props}>
            {children}
        </ConfirmDialogContext.Provider>
    );
}

export default ConfirmDialogProvider;
import {stockAdjustConstants} from "../ActionTypes";

const initialState = {
    adjustments: [],
    count: 0,
    isLoading: false
};

function StockAdjustmentReducer(state = initialState, action) {

    switch (action.type) {
        case stockAdjustConstants.PENDING_ADJUSTMENT_REQUEST:
            return { ...state, isLoading: true };
        case stockAdjustConstants.GET_ADJUSTMENTS_SUCCESS:
            return { ...state, adjustments: action.payload.results, count : action.payload.count, isLoading: false };
        case stockAdjustConstants.GET_ADJUSTMENTS_ERROR:
            return {...state, isLoading: false};
        case stockAdjustConstants.ADD_ADJUSTMENT_SUCCESS:
            return { ...state, adjustments : state.adjustments.concat(action.payload), isLoading: false };
        case stockAdjustConstants.ADD_ADJUSTMENT_ERROR:
            return {...state, isLoading: false};
        default:
            return state;
    }
}

export default StockAdjustmentReducer;
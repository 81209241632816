import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";


import {Link} from 'react-router-dom';
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import Box from "@material-ui/core/Box";
import {useDispatch, useSelector} from "react-redux";
import EnhancedTable from "../../components/EnhancedTable";
import StatusComponent from "../../components/StatusComponent";
import {deleteWorkflowApproverAction, getWorkflowApproversAction} from "../../store/actions/WorkflowAction";
import useStyles from "../../styles";
import MenuActionComponent from "../../components/MenuActionComponent";


const ApproversPage = () => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const approvers = useSelector(state => state.workflow.approvers);


    useEffect(() => {
        dispatch(getWorkflowApproversAction());
    }, [dispatch]);


    const columns = [

        {
            Header: "Process",
            accessor: d => (d.rule.workflow_process ? d.rule.workflow_process.name : '-')
        },
        {
            Header: "Rule",
            accessor: d => (d.rule ? d.rule.name : '-')
        },
        {
            Header: "Approver",
            accessor: d => (d.approver.full_name)
        },
        {
            Header: "Sequence",
            accessor: "sequence"
        },
        {
            Header: "Status",
            accessor: d => <StatusComponent status={d.status}/>
        },
        {
            Header: 'Action',
            Cell: row => (<MenuActionComponent item={{id: row.row.original.id,
                showView: false,
                hideEdit: false,
                hideDelete: false,
            }} url={"workflow/approvers"} deleteAction={deleteWorkflowApproverAction}/>)
        }
    ];


    return (
        <>
            <Grid container justify="flex-end">
                <Box className={classes.headingBar}>
                    <Grid container>
                        <Grid item>
                            <Button className={classes.actionButton} startIcon={<AddIcon/>}>
                                <Link to="workflow/approvers/create" className={classes.unstyledLink}>New Approver</Link>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                {/* Recent Deposits */}
                <Grid item xs={12} md={12} lg={12}>
                    {approvers && <EnhancedTable
                        columns={columns}
                        data={approvers}
                        pageCount={approvers.length}
                        component={false}
                    />}
                </Grid>
            </Grid>
        </>
    );
};

export default ApproversPage;
import React from 'react';
import Grid from "@material-ui/core/Grid";


import {withRouter} from 'react-router-dom';
import Box from "@material-ui/core/Box";
import useStyles from "../../styles";
import {useDispatch, useSelector} from "react-redux";
import SiteWrapper from "../../SiteWrapper";
import Typography from "@material-ui/core/Typography";
import {useFormik} from "formik";
import * as Yup from "yup";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import {approveWorkflowTransactionAction} from "../../store/actions/WorkflowAction";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";


const ApprovePage = (props) => {

    const id = props.match.params.id;

    const classes = useStyles();
    const dispatch = useDispatch();

    const transactions = useSelector(state => state.workflow.transactions);
    const transaction = transactions.find(transaction => transaction.id === parseInt(id));

    const formik = useFormik({

        initialValues: {
            comment: '',
            status: '',
            transaction_id: transaction ? transaction.id : null
        },

        validationSchema: Yup.object().shape({
            status: Yup.number().required(),
            comment: Yup.string()
                .when("status", {
                    is: 3,
                    then: Yup.string().required("Reason for rejection is required")
                }),
        }),

        onSubmit(values) {

            dispatch(approveWorkflowTransactionAction(values, errorCallback));
        },

        enableReinitialize: true

    });

    const errorCallback = (error) => {
        formik.setSubmitting(false);
    };


    const moreInformationLink = (reference) => {
        return `../../${reference.plural}/${reference.id}`;
    };


    return (
        <>
            <SiteWrapper>
                <Grid container className={classes.marginTop}>
                    <Grid item xs={12} md={12}>
                        <Box>
                            <Typography className={classes.medium}>
                                Approve Transaction
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container className={classes.marginTop}>

                    {transaction ?
                        <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>

                            <Grid container spacing={2}>

                                <Grid item xs={3} sm={1}>
                                    <TextField
                                        disabled
                                        variant="standard"
                                        fullWidth
                                        id="reference"
                                        label="Reference"
                                        name="ref"
                                        value={transaction.ref}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <MuiPickersUtilsProvider utils={DayjsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="Date"
                                            format="MM/DD/YYYY"
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            value={transaction.created_at}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>

                                <Grid item xs={8} sm={5}>
                                    <TextField
                                        disabled
                                        variant="standard"
                                        fullWidth
                                        id="initiator"
                                        label="From"
                                        name="initiator"
                                        value={transaction.initiator && transaction.initiator.full_name}
                                    />
                                </Grid>

                                <Grid item xs={7} sm={10}>
                                    <TextField
                                        disabled
                                        variant="standard"
                                        fullWidth
                                        id="description"
                                        label="Description"
                                        name="description"
                                        value={transaction.description}
                                    />
                                </Grid>

                                {transaction.reference  && <Grid item xs={12} sm={12}>
                                    <Link href={moreInformationLink(transaction.reference)} target='_blank' variant="body2">
                                        More information
                                    </Link>
                                </Grid>}


                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        multiline
                                        maxRows={8}
                                        fullWidth
                                        variant="outlined"
                                        id="comment"
                                        label="Comment"
                                        name="comment"
                                        value={formik.values.comment || ''} onChange={formik.handleChange}
                                        error={formik.touched.comment && Boolean(formik.errors.comment)}
                                        helperText={formik.touched.comment && formik.errors.comment}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justify="flex-end" className={classes.marginTop}>

                                <Grid item xs={6} sm={1}>
                                    <Button
                                        type="submit"
                                        size="small"
                                        variant="contained"
                                        className={classes.success}
                                        disabled={formik.isSubmitting}
                                        onClick={() => formik.setFieldValue('status', 2)}
                                    >
                                        Submit
                                    </Button>
                                </Grid>

                                <Grid item xs={6} sm={1}>
                                    <Button
                                        type="submit"
                                        size="small"
                                        variant="contained"
                                        className={classes.danger}
                                        disabled={formik.isSubmitting}
                                        onClick={() => formik.setFieldValue('status', 3)}
                                    >
                                        Reject
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        : "Transaction not found"
                    }
                </Grid>
            </SiteWrapper>
        </>
    );
};

export default withRouter(ApprovePage);

import {createStore, applyMiddleware} from "redux";
import rootReducer from "./reducers/index";
import thunk from 'redux-thunk'
import {routerMiddleware} from 'connected-react-router'
import {composeWithDevTools} from "redux-devtools-extension";
import history from '../helper/History';

const middleware = [routerMiddleware(history), thunk];

const store = createStore(rootReducer(history), composeWithDevTools(applyMiddleware(...middleware)));

export default store;
import React, {useEffect} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import SiteWrapper from "../../SiteWrapper";
import {useDispatch, useSelector} from "react-redux";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import BreadCrumb from "../../components/BreadCrumb";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
    addWorkflowRuleAction,
    editWorkflowRuleAction,
    getWorkflowProcessesAction
} from "../../store/actions/WorkflowAction";
import useStyles from "../../styles";

function AddEditRulePage({history, match}) {

    const classes = useStyles();

    const {id} = match.params;

    const isAddMode = !id;

    const dispatch = useDispatch();

    const rules = useSelector(state => state.workflow.rules);

    const rule = rules.find(rule => rule.id === parseInt(id));

    const processes = useSelector(state => state.workflow.processes);

    useEffect(() => {

        dispatch(getWorkflowProcessesAction());

    }, [dispatch]);

    const formik = useFormik({

        initialValues: {
            workflow_process: rule && rule.workflow_process ? rule.workflow_process.url : '',
            lower_limit: rule && rule.lower_limit ? rule.lower_limit : 0,
            upper_limit: rule && rule.upper_limit ? rule.upper_limit : 0
        },

        validationSchema: Yup.object().shape({
            workflow_process: Yup.string().required('Process is required')
        }),

        onSubmit(values) {

            isAddMode ? dispatch(addWorkflowRuleAction(values, errorCallback)) : dispatch(editWorkflowRuleAction(id, values, errorCallback));
        },

        enableReinitialize: true
    });

    const errorCallback = (error) => {
        formik.setSubmitting(false);
    };

    return (
        <SiteWrapper>
            <BreadCrumb page="New Rule"/>
            <Paper className={classes.paper}>
                <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                    <Grid container spacing={4}>

                        <Grid item xs={6} sm={2} md={2}>
                            <FormControl className={classes.formControl}>
                                <InputLabel>Work Process</InputLabel>
                                <Select
                                    name='workflow_process'
                                    onChange={formik.handleChange}
                                    value={formik.values.workflow_process || ''}
                                    error={formik.touched.workflow_process && Boolean(formik.errors.workflow_process)}
                                    helperText={formik.touched.workflow_process && formik.errors.workflow_process}
                                >
                                    {processes && processes.map((m, idx) => <MenuItem
                                        value={m.url}
                                        key={idx}>{m.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="lower_limit"
                                variant="outlined"
                                required
                                fullWidth
                                id="lower_limit"
                                label="Lower Limit"
                                value={formik.values.lower_limit || ''} onChange={formik.handleChange}
                                error={formik.touched.lower_limit && Boolean(formik.errors.lower_limit)}
                                helperText={formik.touched.lower_limit && formik.errors.lower_limit}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="upper_limit"
                                variant="outlined"
                                required
                                fullWidth
                                id="upper_limit"
                                label="Upper Limit"
                                value={formik.values.upper_limit || ''} onChange={formik.handleChange}
                                error={formik.touched.upper_limit && Boolean(formik.errors.upper_limit)}
                                helperText={formik.touched.upper_limit && formik.errors.upper_limit}
                            />
                        </Grid>

                        <Grid item xs={6} sm={2}>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.status}
                                        onChange={event => formik.setFieldValue('status', event.target.checked)}
                                        color="primary"
                                        name="Active"
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                }
                                label="Active"
                            />
                        </Grid>

                        <Grid container justify="flex-end">

                            <Grid item xs={6} sm={1}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="default"
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                > Cancel </Button>

                            </Grid>

                            <Grid item xs={6} sm={1}>
                                <Button
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.success}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </form>
            </Paper>
        </SiteWrapper>
    );
}

export default AddEditRulePage;

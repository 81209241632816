import React from 'react';

import SiteWrapper from "../../SiteWrapper";
import {useSelector} from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import useStyles from "../../styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import BreadCrumb from "../../components/BreadCrumb";
import MenuActionComponent from "../../components/MenuActionComponent";
import {deleteSupplierAction} from "../../store/actions/SupplierAction";
import dayjs from "../trip/TripPage";
import {utils} from "../../helper/Utils";

function SupplierPage({ match}) {

    const classes = useStyles();

    const {id} = match.params;

    const suppliers = useSelector(state => state.supplier.suppliers);

    const supplier = suppliers.find(supplier => supplier.id === parseInt(id));

    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[{
                'name': 'Suppliers',
                'link': '/suppliers'
            }]}/>
            <Grid container className={classes.marginTop}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography className={classes.medium}>
                            {supplier && supplier.reference}
                        </Typography>
                    </Box>
                </Grid>
                <Grid container item xs={12} sm={8} justify="flex-end">
                    {supplier &&
                    <MenuActionComponent item={{id: supplier.id, showView: false}}
                                         url={"suppliers"}  deleteAction={deleteSupplierAction}
                    />}
                </Grid>
            </Grid>

            <Grid container className={classes.marginTop}>
                <Grid item xs={12} sm={2}>
                    <Box mr={2}>
                        <Avatar variant="square" className={classes.sizeAvatar}>
                            {supplier && supplier.name}
                        </Avatar>
                    </Box>

                    {supplier.created_by && <Box mt={2}>
                        <Typography variant="caption">
                            Created By
                        </Typography>
                        <Typography variant="body1">
                            {supplier.created_by.full_name}
                        </Typography>
                    </Box>}

                    {supplier.created_at && <Box mt={2}>
                        <Typography variant="caption">
                            Created At
                        </Typography>
                        <Typography variant="body1">
                            {dayjs(supplier.created_at).format(utils.dateFormat)}
                        </Typography>
                    </Box>}
                </Grid>
                <Grid item xs={12} sm={10}>
                    <Paper className={classes.paper}>

                        {supplier ?
                        <form className={classes.form} noValidate>

                            <Grid container spacing={4}>
                                <Grid item md="auto"><Typography variant="subtitle1">Supplier
                                    Information</Typography></Grid>
                            </Grid>

                            <Grid container direction="row" spacing={4} align="left">
                                <Grid item md={3}><Typography variant="subtitle2">Name</Typography></Grid>
                                <Grid item md={6} align="left">{supplier.name}</Grid>
                            </Grid>

                            <Grid container direction="row" spacing={4} align="left">
                                <Grid item md={3}><Typography variant="subtitle2">Address</Typography></Grid>
                                <Grid item md={6} align="left">{supplier.address ? supplier.address : '-'}</Grid>
                            </Grid>

                            <Grid container direction="row" spacing={4} align="left">
                                <Grid item md={3}><Typography variant="subtitle2">Email</Typography></Grid>
                                <Grid item md={6} align="left">{supplier.email ? supplier.email : '-'}</Grid>
                            </Grid>

                            <Grid container direction="row" spacing={4} align="left">
                                <Grid item md={3}><Typography variant="subtitle2">Tell</Typography></Grid>
                                <Grid item md={6} align="left">{supplier.tel ? supplier.tel : '-'}</Grid>
                            </Grid>

                            <Grid container direction="row" spacing={4} align="left">
                                <Grid item md={3}><Typography variant="subtitle2">Status</Typography></Grid>
                                <Grid item md={6} align="left">{supplier.status ? 'Active': 'Inactive'}</Grid>
                            </Grid>
                        </form> :

                            <Grid container className={classes.marginTop}>
                                <Grid item xs={12} sm={4}>
                                    <Box>
                                        <Typography>
                                            Oops! Supplier not found. View all suppliers
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        }
                    </Paper>
                </Grid>
            </Grid>
        </SiteWrapper>
    );
}

export default SupplierPage;

import {push} from 'connected-react-router';

import {
    getRoutes,
    addRoute,
    editRoute,
    deleteRoute
} from "../../services/RouteService";


import {routeConstants} from "../ActionTypes";

import {alertActions} from "./AlertAction";


export const getRoutesAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: routeConstants.PENDING_ROUTE_REQUEST
        });
        return getRoutes(data).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: routeConstants.GET_ROUTES_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: routeConstants.GET_ROUTES_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};


export const addRouteAction = (route, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: routeConstants.PENDING_ROUTE_REQUEST
        });
        return addRoute(route).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: routeConstants.ADD_ROUTE_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/routes'));
                    dispatch(alertActions.success('Route added successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: routeConstants.ADD_ROUTE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};


export const editRouteAction = (id, route, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: routeConstants.PENDING_ROUTE_REQUEST
        });
        return editRoute(id, route).then(
            (response) => {
                if (response.data) {

                    dispatch({
                        type: routeConstants.EDIT_ROUTE_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/routes'));
                    dispatch(alertActions.success('Route edited successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: routeConstants.EDIT_ROUTE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};

export const deleteRouteAction = (id) => {
    return (dispatch) => {
        dispatch({
            type: routeConstants.PENDING_ROUTE_REQUEST
        });
        return deleteRoute(id).then(
            (response) => {
                if (response) {
                    dispatch({
                        type: routeConstants.DELETE_ROUTE_SUCCESS,
                        payload: id
                    });

                    dispatch(push('/routes'));
                    dispatch(alertActions.success('Route deleted successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: routeConstants.DELETE_ROUTE_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};
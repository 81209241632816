import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import SiteWrapper from "../../SiteWrapper";
import {withRouter} from 'react-router-dom';
import {addExpenseAction, editExpenseAction} from "../../store/actions/ExpenseAction";
import {useDispatch, useSelector} from "react-redux";
import {getSettingsAction} from "../../store/actions/SettingAction";
import {getMachinesAction} from "../../store/actions/MachineAction";
import {getEmployeesAction} from "../../store/actions/EmployeeAction";
import BreadCrumb from "../../components/BreadCrumb";
import TextField from "@material-ui/core/TextField";
import useStyles from "../../styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import dayjs from "dayjs";
import DayjsUtils from "@date-io/dayjs";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import {utils} from "../../helper/Utils";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import FormHelperText from "@material-ui/core/FormHelperText";
import Autocomplete from "@material-ui/lab/Autocomplete";

function AddEditExpensePage({match}) {

    const classes = useStyles();

    const {id} = match.params;

    const isAddMode = !id;

    const dispatch = useDispatch();

    const [expenseLines, setExpenseLines] = useState([{
        'type': '',
        'machine': '',
        'driver': '',
        'amount': 0.00
    }]);

    const expenses = useSelector(state => state.expense.expenses);
    const expense = expenses.find(expense => expense.id === parseInt(id));


    const expenseTypes = useSelector(state => state.setting.settings);
    const machines = useSelector(state => state.machine.machines);
    const drivers = useSelector(state => state.employee.employees);


    useEffect(() => {

        dispatch(getSettingsAction({group: 'expenses'}));
        dispatch(getMachinesAction());
        dispatch(getEmployeesAction());

        expense && expense.expense_lines && setExpenseLines(Object.assign([], expense.expense_lines.map((item, key) => ({
            'amount': item.amount ? item.amount : 0.00,
            'type':  item.type ? item.type.url : '',
            'machine': item.machine ? item.machine.url : '',
            'driver': item.driver ? item.driver.url : '',
        }))));


    }, [dispatch, expense]);

    const formik = useFormik({

        initialValues: {
            effective_date: expense ? expense.effective_date : dayjs().format("YYYY-MM-DD"),
            expense_lines: expense && expense.expense_lines ? Object.assign([], expense.expense_lines.map((item, key) => ({
                'amount': item.amount ? item.amount : 0.00,
                'type': item.type ? item.type.url : '',
                'machine': item ? item.machine : null,
                'driver': item ? item.driver : null,
            }))) : [],
            status: 0
        },

        validationSchema: Yup.object().shape({
            effective_date: Yup.date("YYYY-mm-dd").required('Effective date is required'),
            expense_lines: Yup.array()
                .min(1, "Enter at least one expense line")
                .of(
                    Yup.object()
                        .shape({
                            type: Yup.string('Must be a string').required('Type is required'),
                            machine: Yup.string('Must be a string').required('Machine is required'),
                            driver: Yup.string('Must be a string').required('Driver is required'),
                            amount: Yup.number('Must be a number').typeError('Amount must be a number').required('Amount is required'),
                        }).required("Expense line is required")
                ),

        }),

        onSubmit(values) {
            isAddMode ? dispatch(addExpenseAction(values, errorCallback)) : dispatch(editExpenseAction(id, values, errorCallback));
        },

        enableReinitialize: true
    });

    const errorCallback = (error) => {
        formik.setSubmitting(false);
    };

    const handleAddExpense = index => {

        setExpenseLines([...expenseLines, {
            'type': '',
            'machine': '',
            'driver': '',
            'amount': 0.00
        }]);
    };

    const handleRemoveExpense = index => {

        const list = [...expenseLines];

        list.splice(index, 1);
        setExpenseLines(list);
    };

    const handleInputChange = (event, name, value, setFieldValue) => {

        // Check if event is null to prevent multiple value set
        // If on change occurred, the event will be click

        if(null === event && value.url) {
            setFieldValue(name, value.url)
        }

    };

    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[{
                'name': 'Expenses',
                'link': '/expenes'
            }]}/>

            <Grid container className={classes.marginTop}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography className={clsx(classes.pageTitle)}>
                            New Expense
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Paper className={classes.paper}>
                <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                    <Grid container spacing={4}>
                        <MuiPickersUtilsProvider utils={DayjsUtils}>
                            <Grid item xs={4} sm={2}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Effective Date"
                                    format="MM/DD/YYYY"
                                    maxDate={utils.maxDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    value={formik.values.effective_date}
                                    onChange={val => formik.setFieldValue('effective_date', dayjs(val).format("YYYY-MM-DD"))}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>


                    <Grid container spacing={2} className={classes.marginTop}>
                        <Grid item>
                            <Typography variant="subtitle2">Expenses Items</Typography>
                        </Grid>

                        {formik.errors.expense_lines && typeof formik.errors.expense_lines == 'string' && <Grid item md={12} xs={12}>
                            <FormHelperText error>** {formik.errors.expense_lines}</FormHelperText>
                        </Grid>}
                    </Grid>

                    {expenseLines.map((expenseLine, i) => {

                        const expenseLineErrors = (formik.errors.expense_lines?.length && formik.errors.expense_lines[i]) || {};
                        const expenseLineTouched = (formik.touched.expense_lines?.length && formik.touched.expense_lines[i]) || {};
                        const expenseLineValue = (formik.values.expense_lines?.length && formik.values.expense_lines[i]) || {};

                        return (<Grid container spacing={2} key={i}>
                            <Grid item xs={12} sm={3}>
                                <FormControl className={classes.formControl} error={expenseLineErrors.type && Boolean(expenseLineTouched.type)}>
                                    <InputLabel>Type</InputLabel>
                                    <Select
                                        name={`expense_lines.${i}.type`}
                                        onChange={formik.handleChange}
                                        value={(expenseLineValue && expenseLineValue.type) || ''}
                                    >
                                        {expenseTypes && expenseTypes.map((m, idx) => <MenuItem
                                            value={m.url}
                                            key={idx}>{m.name}</MenuItem>)}
                                    </Select>
                                    {expenseLineErrors.type && expenseLineTouched.type && <FormHelperText>{expenseLineErrors.type}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                {/*<FormControl className={classes.formControl}>*/}
                                {/*    <InputLabel>Truck</InputLabel>*/}
                                {/*    <Select*/}
                                {/*        name={`expense_lines.${i}.machine`}*/}
                                {/*        onChange={formik.handleChange}*/}
                                {/*        value={(expenseLineValue && expenseLineValue.machine) || ''}*/}
                                {/*        error={expenseLineErrors.machine && Boolean(expenseLineTouched.machine)}*/}
                                {/*        helperText={expenseLineErrors.machine && expenseLineTouched.machine}*/}
                                {/*    >*/}
                                {/*        {machines && machines.map((m, idx) => <MenuItem*/}
                                {/*            value={m.url}*/}
                                {/*            key={idx}>{m.reg_no}</MenuItem>)}*/}
                                {/*    </Select>*/}
                                {/*</FormControl>*/}
                                <Autocomplete
                                    filterOptions={utils.autocompleteFilterOptions}
                                    className={classes.formControl}
                                    disablePortal
                                    options={machines}
                                    getOptionLabel={option => option.reg_no}
                                    name={`expense_lines.${i}.machine`}
                                    defaultValue={(expenseLineValue && expenseLineValue.machine) || null}
                                    getOptionSelected={(option, value) => option.url === value.url}
                                    onInputChange={(event, value) => {
                                        handleInputChange(event,`expense_lines.${i}.machine`, expenseLineValue.machine, formik.setFieldValue);
                                    }}
                                    onChange={(_, value) => value != null ? formik.setFieldValue(`expense_lines.${i}.machine`, value.url) : ''}
                                    renderInput={(params) => <TextField {...params}
                                                                        error={formik.errors.machine && Boolean(formik.touched.machine)}
                                                                        helperText={formik.errors.machine && formik.touched.machine}
                                                                        name={`expense_lines.${i}.machine`}
                                                                        label="Truck"/>}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                {/*<FormControl className={classes.formControl}>*/}
                                {/*    <InputLabel>Driver</InputLabel>*/}
                                {/*    <Select*/}
                                {/*        name={`expense_lines.${i}.driver`}*/}
                                {/*        onChange={formik.handleChange}*/}
                                {/*        value={(expenseLineValue && expenseLineValue.driver) || ''}*/}
                                {/*        error={expenseLineErrors.driver && Boolean(expenseLineTouched.driver)}*/}
                                {/*        helperText={expenseLineErrors.driver && expenseLineTouched.driver}*/}
                                {/*    >*/}
                                {/*        {drivers && drivers.map((m, idx) => <MenuItem*/}
                                {/*            value={m.url}*/}
                                {/*            key={idx}>{m.full_name}</MenuItem>)}*/}
                                {/*    </Select>*/}
                                {/*</FormControl>*/}
                                <Autocomplete
                                    filterOptions={utils.autocompleteFilterOptions}
                                    className={classes.formControl}
                                    disablePortal
                                    options={drivers}
                                    getOptionLabel={option => option.full_name}
                                    name={`expense_lines.${i}.driver`}
                                    defaultValue={(expenseLineValue && expenseLineValue.driver) || null}
                                    getOptionSelected={(option, value) => option.url === value.url}
                                    onInputChange={(event, value) => {
                                        handleInputChange(event,`expense_lines.${i}.driver`, expenseLineValue.driver, formik.setFieldValue);
                                    }}
                                    onChange={(_, value) => value != null ? formik.setFieldValue(`expense_lines.${i}.driver`, value.url) : ''}
                                    renderInput={(params) => <TextField {...params}
                                                                        error={formik.errors.driver && Boolean(formik.touched.driver)}
                                                                        helperText={formik.errors.driver && formik.touched.driver}
                                                                        name={`expense_lines.${i}.driver`}
                                                                        label="Driver"/>}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    required
                                    fullWidth
                                    name={`expense_lines[${i}].amount`} label='Amount'
                                    onChange={formik.handleChange}
                                    value={(expenseLineValue && expenseLineValue.amount) || ''}
                                    error={Boolean(expenseLineErrors.amount)}
                                    helperText={expenseLineErrors.amount}
                                />
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                {expenseLines.length !== 1 && expenseLines.length - 1 !== i &&
                                <IconButton className={classes.danger} aria-label="Remove line" component="span"
                                            onClick={() => handleRemoveExpense(i)}>
                                    <RemoveIcon/>
                                </IconButton>}
                            </Grid>
                        </Grid>)
                    })}

                    <Grid container className={classes.marginTop}>
                        <Grid item>
                            <Button className={classes.info} aria-label="Add line"  size="small"
                                    onClick={() => handleAddExpense()}> <AddIcon/> Add Line
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-end" className={classes.marginTop}>
                        <Grid item xs={6} sm={2}>
                            <Button
                                type="submit"
                                size="small"
                                variant="contained"
                                className={classes.success}
                                disabled={formik.isSubmitting}
                                onClick={() => formik.setFieldValue('status', 1)}
                            >
                                Submit for approval
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={1}>
                            <Button
                                type="submit"
                                size="small"
                                variant="contained"
                                color="default"
                                className={classes.primary}
                                disabled={formik.isSubmitting}
                                onClick={() => formik.setFieldValue('status', 0)}
                            > Save </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </SiteWrapper>
    );
}

export default withRouter(AddEditExpensePage);

import {
    contentTypeApplicationJson,
} from "../constants/httpConstants";

import {httpWrapper} from "../helper/Http";

export const getCustomers = async (data) => {
    return httpWrapper.get('/customers/', contentTypeApplicationJson, data);
};

export const getCustomer = async (customer) => {
    return httpWrapper.get('/customers/' + customer, contentTypeApplicationJson);
};

export const addCustomer = async (customer) => {
    return httpWrapper.post('/customers/' , customer, contentTypeApplicationJson);
};

export const editCustomer = async (id, customer) => {
    return httpWrapper.update('/customers/' + id + '/' , customer, contentTypeApplicationJson);
};

export const deleteCustomer = async (id) => {
    return httpWrapper.delete('/customers/' + id + '/' , contentTypeApplicationJson);
};

import {
    contentTypeApplicationJson,
} from "../constants/httpConstants";

import {httpWrapper} from "../helper/Http";

export const getSuppliers = async (data) => {
    return httpWrapper.get('/suppliers/', contentTypeApplicationJson, data);
};

export const getSupplier = async (supplier) => {
    return httpWrapper.get('/suppliers/' + supplier, contentTypeApplicationJson);
};

export const addSupplier = async (supplier) => {
    return httpWrapper.post('/suppliers/' , supplier, contentTypeApplicationJson);
};

export const editSupplier = async (id, supplier) => {
    return httpWrapper.update('/suppliers/' + id + '/' , supplier, contentTypeApplicationJson);
};

export const deleteSupplier = async (id) => {
    return httpWrapper.delete('/suppliers/' + id + '/' , contentTypeApplicationJson);
};

import {saleConstants} from "../ActionTypes";

const initialState = {
    sales: [],
    count: 0,
    sale: {},
    isLoading: false
};

function SaleReducer(state = initialState, action) {

    switch (action.type) {
        case saleConstants.PENDING_SALE_REQUEST:
            return { ...state, isLoading: true };
        case saleConstants.GET_SALES_SUCCESS:
            return { ...state, sales: action.payload.results, count: action.payload.count, isLoading: false };
        case saleConstants.GET_SALES_ERROR:
            return {...state, isLoading: false};
        case saleConstants.GET_SALE_SUCCESS:
            return { ...state, sale: action.payload, isLoading: false };
        case saleConstants.GET_SALE_ERROR:
            return {...state, isLoading: false};
        case saleConstants.ADD_SALE_SUCCESS:
            return { ...state, sales : state.sales.concat(action.payload),  isLoading: false };
        case saleConstants.ADD_SALE_ERROR:
            return {...state, isLoading: false};
        case saleConstants.EDIT_SALE_SUCCESS:
            return { ...state, sale : action.payload, isLoading: false };
        case saleConstants.EDIT_SALE_ERROR:
            return { ...state, isLoading: false };
        case saleConstants.DELETE_SALE_SUCCESS:
            return {...state, sales: state.sales.filter((sale)=> sale.id !== action.payload), count: state.count - 1, isLoading: false };
        case saleConstants.DELETE_SALE_ERROR:
            return { ...state.sales, isLoading: false };
        default:
            return state;
    }
}

export default SaleReducer;
import {productConstants} from "../ActionTypes";

const initialState = {
    products: [],
    count: 0,
    product: {},
    isLoading: false
};

function ProductReducer(state = initialState, action) {

    switch (action.type) {
        case productConstants.PENDING_PRODUCT_REQUEST:
            return {...state, isLoading: true};
        case productConstants.GET_PRODUCTS_SUCCESS:
            return {...state, products: action.payload.results, count: action.payload.count, isLoading: false};
        case productConstants.GET_PRODUCTS_ERROR:
            return {...state, isLoading: false};
        case productConstants.ADD_PRODUCT_SUCCESS:
            return {...state, products: state.products.concat(action.payload), isLoading: false};
        case productConstants.ADD_PRODUCT_ERROR:
            return {...state, isLoading: false};
        case productConstants.EDIT_PRODUCT_SUCCESS:
            return {...state, product: action.payload, isLoading: false};
        case productConstants.EDIT_PRODUCT_ERROR:
            return {...state, isLoading: false};
        case productConstants.DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                products: state.products.filter((product) => product.id !== action.payload),
                count: state.count - 1,
                isLoading: false
            };
        case productConstants.DELETE_PRODUCT_ERROR:
            return {...state.products, isLoading: false};
        default:
            return state;
    }
}

export default ProductReducer;
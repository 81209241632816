import React, {useState} from 'react'

import MaUTable from '@material-ui/core/Table'
import PropTypes from 'prop-types'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TablePaginationActions from './TablePaginationActions'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableToolbar from './TableToolbar'
import Paper from "@material-ui/core/Paper";
import useStyles from "../styles";
import clsx from "clsx";

import {useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable,} from 'react-table'
import {utils} from "../helper/Utils";


const EnhancedTable = ({
                           columns,
                           data,
                           summaries,
                           headings,
                           fetchData,
                           pageCount: controlledPageCount,
                       }) => {
    const {
        getTableProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: {pageSize, globalFilter},
    } = useTable(
        {
            columns,
            data,
            initialState: {pageIndex: utils.DEFAULT_PAGE_INDEX, pageSize: utils.DEFAULT_PAGE_SIZE},
            manualPagination: true,
            pageCount: controlledPageCount,
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect
    );

    const [customPageIndex, setCustomPageIndex] = useState(utils.DEFAULT_PAGE_INDEX);

    const handleChangePage = (event, newPage) => {
        fetchData && fetchData({pageIndex: newPage, pageSize});
        gotoPage(newPage);
        setCustomPageIndex(newPage);
    };

    const handleChangeRowsPerPage = event => {
        let perPage = Number(event.target.value);
        setPageSize(perPage);

        // If page size changes, default to index page to start afresh
        fetchData && fetchData({pageIndex: utils.DEFAULT_PAGE_INDEX, pageSize: perPage});
        setCustomPageIndex(utils.DEFAULT_PAGE_INDEX);
    };

    const classes = useStyles();

    const renderSummaries = (summaries) => {

        return summaries.map((summary, idp) => {
            return (
                <TableRow key={"extra_" + idp}>
                    <TableCell colSpan={summary.freeSpan}/>
                    <TableCell className={clsx(classes.cellStyle, classes.bolded)}
                               colSpan={summary.colSpan}>{summary.title}</TableCell>
                    <TableCell className={classes.cellStyle}>{summary.value}</TableCell>
                </TableRow>
            );
        })
    };

    const renderHeadings = (headings) => {

        return headings.map((heading, idp) => {
            return (
                <TableRow key={"hdngs_" + idp}>
                    <TableCell colSpan={heading.freeSpan}/>
                    <TableCell className={clsx(classes.cellStyle, classes.bolded)}
                               colSpan={heading.colSpan}>{heading.title}</TableCell>
                    <TableCell className={classes.cellStyle}>{heading.value}</TableCell>
                </TableRow>
            );
        })
    };

    // Render the UI for your table
    return (
        <TableContainer component={Paper}>
            <TableToolbar
                preGlobalFilteredRows={preGlobalFilteredRows}
                setGlobalFilter={setGlobalFilter}
                globalFilter={globalFilter}
            />
            <MaUTable {...getTableProps()}>
                <TableHead>
                    {headerGroups.map((headerGroup, idk) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()} key={idk}>
                            {headerGroup.headers.map((column, key) => (
                                <TableCell className={classes.cellStyle} key={key}
                                           {...(column.id === 'count'
                                               ? column.getHeaderProps()
                                               : column.getHeaderProps(column.getSortByToggleProps()))}
                                >
                                    {column.render('Header')}

                                    {column.id !== 'count' ? (
                                        <TableSortLabel
                                            active={column.isSorted}
                                            // react-table has a unsorted state which is not treated here
                                            direction={column.isSortedDesc ? 'desc' : 'asc'}
                                        />
                                    ) : null}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody>
                    {headings && renderHeadings(headings)}
                    {page.map((row, idx) => {

                        prepareRow(row);

                        return (
                            <TableRow {...row.getRowProps()} key={idx}>
                                {row.cells.map((cell, idy) => {
                                    return (
                                        <TableCell  {...cell.getCellProps()} className={classes.cellStyle} key={idy}>
                                            {cell.render('Cell')}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        )
                    })}
                    {summaries && renderSummaries(summaries)}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[
                                1,
                                2,
                                10,
                                25,
                                50
                            ]}
                            count={Math.ceil(controlledPageCount)}
                            rowsPerPage={pageSize}
                            page={customPageIndex}
                            SelectProps={{
                                inputProps: {'aria-label': 'rows per page'},
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </MaUTable>
        </TableContainer>
    )
};

EnhancedTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired
};

export default EnhancedTable

import { createMuiTheme } from "@material-ui/core";

let theme = createMuiTheme({
    palette: {
        primary: {
            light: '#63ccff',
            main: '#0061a8',
            dark: '#006db3',
        },
        text: {
            primary: '#424649'
        }
    },
    typography: {
        fontFamily: ['"Inter",-apple-system,BlinkMacSystemFont,San Francisco,Segoe UI,Roboto,Helvetica Neue,sans-serif'].join(','),
        fontSize: 13,
        h5: {
            fontWeight: 450,
            color: '#424649',
            fontSize: '1.2rem',
            letterSpacing: 0.5,
        }
    },
    shape: {
        borderRadius: 8,
    },
    props: {
        MuiTab: {
            disableRipple: true,
        },
    },
    mixins: {
        // toolbar: {
        //     minHeight: 48,
        // },
    },
}, {index: 1});

theme = {
    ...theme,
    overrides: {
        MuiDrawer: {
            paper: {
                backgroundColor: '#232e3c',
            },
        },
        MuiButton: {
            label: {
                textTransform: 'none',
            },
            contained: {
                boxShadow: 'none',
                '&:active': {
                    boxShadow: 'none',
                },
            },
        },
        MuiIconButton: {
            root: {
                padding: theme.spacing(1),
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: '#404854',
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        MuiListItemIcon: {
            root: {
                color: 'inherit',
                marginRight: 0,
                '& svg': {
                    fontSize: 'inherit',
                },
            },
        },
        MuiAvatar: {
            root: {
                width: 32,
                height: 32,
            },
        },
        MuiTable : {
            root: {
                margin: theme.spacing(0, 1, 1, 1)
            }
        },
        MuiTableRow : {
            root: {
                height: '2.6rem'
            }
        },
        MuiMenuItem : {
            root: {
                maxHeight: '1.7rem'
            }
        }
    },
};

export default theme;

import React, {useEffect} from 'react';

import SiteWrapper from "../../SiteWrapper";
import {Link, withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import useStyles from "../../styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {utils} from "../../helper/Utils";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {deleteTripAction, getTripAction} from "../../store/actions/TripAction";
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import MenuActionComponent from "../../components/MenuActionComponent";
import dayjs from "dayjs";


const TripPage = ({match}) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const {id} = match.params;

    const trip = useSelector(state => state.trip.trip);

    useEffect(() => {
        dispatch(getTripAction(id));
    }, [dispatch, id]);


    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[{
                'name': 'Trips',
                'link': '/trips'
            }]}/>


            {trip ?
                <div>
                    <Grid container className={classes.marginTop}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <Typography className={classes.pageTitle}>
                                    {trip.reference}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid container item xs={12} md={8} justify="flex-end">
                            <Box>
                                <Button className={classes.actionButton} startIcon={<AddIcon/>}>
                                    <Link to="/trips/create" className={classes.unstyledLink}>New Trip</Link>
                                </Button>
                            </Box>
                            <Box>
                                <MenuActionComponent item={{
                                    id: trip.id,
                                    showView: false,
                                    hideEdit: trip.status !== 'Saved',
                                    hideDelete: trip.status !== 'Saved'
                                }}
                                    url={"trips"} deleteAction={deleteTripAction}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.marginTop}>
                        <Grid item xs={12} sm={2}>
                            <Box mr={2}>
                                <Box mt={3}></Box>
                                <Box mt={2}>
                                    <Typography variant="caption">
                                        Date
                                    </Typography>
                                    <Typography variant="body1">
                                        {dayjs(trip.effective_date).format(utils.dateFormat)}
                                    </Typography>
                                </Box>
                                {trip.created_by && <Box mt={2}>
                                    <Typography variant="caption">
                                        Created By
                                    </Typography>
                                    <Typography variant="body1">
                                        {trip.created_by.full_name}
                                    </Typography>
                                </Box>}

                                {trip.created_at && <Box mt={2}>
                                    <Typography variant="caption">
                                        Created At
                                    </Typography>
                                    <Typography variant="body1">
                                        {dayjs(trip.created_at).format(utils.dateFormat)}
                                    </Typography>
                                </Box>}

                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <Paper className={classes.paper}>
                                <form className={classes.form} noValidate>

                                    <Grid container spacing={4}>
                                        <Grid item md="auto"><Typography variant="subtitle1">Trip Information</Typography></Grid>
                                    </Grid>

                                    <Grid container direction="row" spacing={4} align="left">
                                        <Grid item md={3}><Typography variant="subtitle2">Horse</Typography></Grid>
                                        <Grid item md={6} align="left">{trip.horse ? trip.horse.reg_no : '-'}</Grid>
                                    </Grid>

                                    <Grid container direction="row" spacing={4} align="left">
                                        <Grid item md={3}><Typography variant="subtitle2">Trailer</Typography></Grid>
                                        <Grid item md={6} align="left">{trip.trailer ? trip.trailer.reg_no : '-'}</Grid>
                                    </Grid>

                                    <Grid container direction="row" spacing={4} align="left">
                                        <Grid item md={3}><Typography variant="subtitle2">Route</Typography></Grid>
                                        <Grid item md={6} align="left">{trip.route ? trip.route.name : '-'}</Grid>
                                    </Grid>

                                    <Grid container direction="row"  spacing={4} align="left">
                                        <Grid item md={3}><Typography variant="subtitle2">Mileage</Typography></Grid>
                                        <Grid item md={6} align="left">{utils.numberFormatter(trip.mileage ?? 0)}</Grid>
                                    </Grid>

                                    <Grid container direction="row" spacing={4} align="left">
                                        <Grid item md={3}><Typography variant="subtitle2">Distance </Typography></Grid>
                                        <Grid item md={6} align="left">{utils.numberFormatter(trip.distance ?? 0)}</Grid>
                                    </Grid>

                                    <Grid container direction="row"  spacing={4} align="left">
                                        <Grid item md={3}><Typography variant="subtitle2">Driver</Typography></Grid>
                                        <Grid item md={6} >{trip.driver ? trip.driver.full_name : '-'}</Grid>
                                    </Grid>

                                    <Grid container direction="row" spacing={4} align="left">
                                        <Grid item md={3}><Typography variant="subtitle2">Co-Driver</Typography></Grid>
                                        <Grid item md={6}>{trip.co_driver ? trip.co_driver.full_name : '-'}</Grid>
                                    </Grid>

                                    <Grid container direction="row" spacing={4} align="left">
                                        <Grid item md={3}><Typography variant="subtitle2">Fuel</Typography></Grid>
                                        <Grid item md={6}>{utils.numberFormatter(trip.fuel)}</Grid>
                                    </Grid>

                                    <Grid container direction="row" spacing={4} align="left">
                                        <Grid item md={3}><Typography variant="subtitle2">Fuel Price</Typography></Grid>
                                        <Grid item md={6}>{utils.numberFormatter(trip.fuel_price)}</Grid>
                                    </Grid>

                                    <Grid container direction="row" spacing={4} align="left">
                                        <Grid item md={3}><Typography variant="subtitle2">Fuel Supplier</Typography></Grid>
                                        <Grid item md={6}>{trip.fuel_supplier ? trip.fuel_supplier.name : '-'}</Grid>
                                    </Grid>
                                </form>
                            </Paper>
                        </Grid>
                    </Grid></div> :

                <Grid container className={classes.marginTop}>
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <Typography>
                                Oops! Trip not found. View all trips
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            }
        </SiteWrapper>
    )
};

export default withRouter(TripPage);
import {settingConstants} from "../ActionTypes";

const initialState = {
    settings: [],
    count: 0,
    setting: {},
    isLoading: false
};

function SettingReducer(state = initialState, action) {

    switch (action.type) {
        case settingConstants.PENDING_SETTING_REQUEST:
            return {...state, isLoading: true};
        case settingConstants.GET_SETTINGS_SUCCESS:
            return {...state, settings: action.payload.results, count: action.payload.count, isLoading: false};
        case settingConstants.GET_SETTINGS_ERROR:
            return {...state, isLoading: false};
        case settingConstants.GET_SETTING_SUCCESS:
            return {...state, setting: action.payload, isLoading: false};
        case settingConstants.GET_SETTING_ERROR:
            return {...state, isLoading: false};
        case settingConstants.ADD_SETTING_SUCCESS:
            return {...state, settings: state.settings.concat(action.payload), isLoading: false};
        case settingConstants.ADD_SETTING_ERROR:
            return {...state, isLoading: false};
        case settingConstants.EDIT_SETTING_SUCCESS:
            return {...state, setting: action.payload, isLoading: false};
        case settingConstants.EDIT_SETTING_ERROR:
            return {...state, isLoading: false};
        case settingConstants.DELETE_SETTING_SUCCESS:
            return {
                ...state,
                settings: state.settings.filter((setting) => setting.id !== action.payload),
                count: state.count - 1,
                isLoading: false
            };
        case settingConstants.DELETE_SETTING_ERROR:
            return {...state.settings, isLoading: false};
        default:
            return state;
    }
}

export default SettingReducer;
import React, {useEffect, useRef} from 'react';
import Grid from "@material-ui/core/Grid";
import BreadCrumb from "../../components/BreadCrumb";


import {Link, withRouter} from 'react-router-dom';
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import Box from "@material-ui/core/Box";
import useStyles from "../../styles";
import {useDispatch, useSelector} from "react-redux";
import EnhancedTable from "../../components/EnhancedTable";
import SiteWrapper from "../../SiteWrapper";
import StatusComponent from "../../components/StatusComponent";
import {deleteEmployeeAction, getEmployeesAction} from "../../store/actions/EmployeeAction";
import ApprovalStatusComponent from "../../components/ApprovalStatusComponent";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import MenuActionComponent from "../../components/MenuActionComponent";
import {utils} from "../../helper/Utils";


const EmployeesPage = (props) => {

    const classes = useStyles();

    const dispatch = useDispatch();
    const employees = useSelector(state => state.employee.employees);
    const count = useSelector(state => state.employee.count);

    const fetchIdRef = useRef(0);

    const fetchData = React.useCallback((pager, query) => {

        let search = Object.assign({}, {limit: pager.pageSize, offset: pager.pageIndex * pager.pageSize}, query);

        const fetchId = ++fetchIdRef.current;

        if (fetchId === fetchIdRef.current) {
            dispatch(getEmployeesAction(search));
        }

    }, [dispatch]);


    useEffect(() => {
        fetchData({pageIndex: utils.DEFAULT_PAGE_INDEX, pageSize: utils.DEFAULT_PAGE_SIZE});
    }, [fetchData]);

    const columns = [

        {
            Header: "No",
            accessor: d => (d.employee_no ? d.employee_no : '-')
        },
        {
            Header: "First Name",
            accessor: 'first_name'
        },
        {
            Header: "Last Name",
            accessor: "last_name"
        },
        {
            Header: "Gender",
            accessor: 'gender'
        },
        {
            Header: "Designation",
            accessor: d => (d.designation.name ?? '-')
        },
        {
            Header: "Status",
            accessor: d => <StatusComponent status={d.is_active}/>
        },
        {
            Header: "Approval",
            accessor: d => <ApprovalStatusComponent status={d.status}/>
        },
        {
            Header: 'Action',
            Cell: row => (<MenuActionComponent item={{id: row.row.original.id, showView: false}} url={"employees"}
                                           deleteAction={deleteEmployeeAction}/>)
        }
    ];

    return (
        <>
            <SiteWrapper>
                <BreadCrumb crumbs={[{
                    'name': 'Employees',
                    'link': '#'
                }]}/>
                <Grid container justify="flex-end">

                    <Grid container className={classes.marginTop}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <Typography className={clsx(classes.medium, classes.pageTitle)}>
                                    Employees
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid container item xs={12} sm={8} justify="flex-end">
                            <Box>
                                <Button className={classes.actionButton} startIcon={<AddIcon/>}>
                                    <Link to="/employees/create" className={classes.unstyledLink}>New Employee</Link>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>


                    {/* Recent Deposits */}
                    <Grid item xs={12} md={12} lg={12}>
                        {employees && <EnhancedTable
                            columns={columns}
                            data={employees}
                            fetchData={fetchData}
                            pageCount={count && count}
                        />}
                    </Grid>

                </Grid>
            </SiteWrapper>
        </>
    );
};

export default withRouter(EmployeesPage);
import React, {useEffect} from 'react';

import SiteWrapper from "../../SiteWrapper";
import {Link, withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import useStyles from "../../styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {utils} from "../../helper/Utils";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {deletePurchaseAction, getPurchaseAction} from "../../store/actions/PurchaseAction";
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import clsx from "clsx";
import MenuActionComponent from "../../components/MenuActionComponent";
import dayjs from "dayjs";


const PurchasePage = ({match}) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const {id} = match.params;

    const purchase = useSelector(state => state.purchase.purchase);

    useEffect(() => {
        dispatch(getPurchaseAction(id));
    }, [dispatch, id]);


    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[{
                'name': 'Purchases',
                'link': '/purchases'
            }]}/>


            {purchase ?
                <div>
                    <Grid container className={classes.marginTop}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <Typography className={clsx(classes.pageTitle)}>
                                    {purchase.reference}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid container item xs={12} md={8} justify="flex-end">
                            <Box>
                                <Button className={classes.actionButton} startIcon={<AddIcon/>}>
                                    <Link to="/purchases/create" className={classes.unstyledLink}>New Purchase</Link>
                                </Button>
                            </Box>
                            <MenuActionComponent item={{
                                id: purchase.id,
                                showView: false,
                                hideEdit: purchase.status !== 'Saved',
                                hideDelete: purchase.status !== 'Saved'
                            }} url={"purchases"} deleteAction={deletePurchaseAction}/>
                        </Grid>

                    </Grid>
                    <Grid container className={classes.marginTop}>
                        <Grid item xs={12} sm={2}>
                            <Box mr={2}>
                                <Box mt={2}>
                                    <Typography variant="caption">
                                        Date
                                    </Typography>
                                    <Typography variant="body1">
                                        {(dayjs(purchase.delivery_date).format(utils.dateFormat))}
                                    </Typography>
                                </Box>
                                <Box mt={2}>
                                    <Typography variant="caption">
                                        Invoice No
                                    </Typography>
                                    <Typography variant="body1">
                                        {purchase.delivery_note}
                                    </Typography>
                                </Box>
                                {purchase.created_by && <Box mt={2}>
                                    <Typography variant="caption">
                                        Created By
                                    </Typography>
                                    <Typography variant="body1">
                                        {purchase.created_by.full_name}
                                    </Typography>
                                </Box>}

                                {purchase.created_at && <Box mt={2}>
                                    <Typography variant="caption">
                                        Created At
                                    </Typography>
                                    <Typography variant="body1">
                                        {dayjs(purchase.created_at).format(utils.dateFormat)}
                                    </Typography>
                                </Box>}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <Paper className={classes.paper}>


                                <Grid container spacing={4}>
                                    <Grid item><Typography variant="subtitle1">Purchase Items</Typography>
                                    </Grid></Grid>


                                <Grid container spacing={2}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Product</TableCell>
                                                <TableCell>Serial</TableCell>
                                                <TableCell align="right">Quantity</TableCell>
                                                <TableCell align="right">Unit Price</TableCell>
                                                <TableCell align="right">Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                purchase.purchase_lines && purchase.purchase_lines.map((line, i) => {

                                                    return (line &&
                                                        <TableRow key={i}>
                                                            <TableCell component="th"
                                                                       scope="row">{line.product.name}</TableCell>
                                                            <TableCell>{line.sn_no ?? '-'}</TableCell>
                                                            <TableCell align="right">{line.quantity}</TableCell>
                                                            <TableCell
                                                                align="right">{utils.numberFormatter(line.unit_price)}</TableCell>
                                                            <TableCell
                                                                align="right">{utils.numberFormatter(line.line_total)}</TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            }
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="right">Sub Total</TableCell>
                                                <TableCell
                                                    align="right">{utils.numberFormatter(purchase.sub_total)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="right">VAT ({utils.numberFormatter(purchase.vat)}%)
                                                </TableCell>
                                                <TableCell
                                                    align="right">{utils.numberFormatter(purchase.vat_total)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="right">Total</TableCell>
                                                <TableCell
                                                    align="right">{utils.numberFormatter(purchase.total)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid></div> :

                <Grid container className={classes.marginTop}>
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <Typography>
                                Oops! Purchase not found. View all expenses
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            }
        </SiteWrapper>
    )
};

export default withRouter(PurchasePage);
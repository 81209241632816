import React, {useEffect} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import SiteWrapper from "../../SiteWrapper";
import {useDispatch, useSelector} from "react-redux";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import useStyles from "../../styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import BreadCrumb from "../../components/BreadCrumb";
import Switch from "@material-ui/core/Switch";
import {addUserAction, editUserAction} from "../../store/actions/UserAction";
import {getEmployeesAction} from "../../store/actions/EmployeeAction";
import {utils} from "../../helper/Utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

function AddEditUserPage({history, match}) {

    const classes = useStyles();

    const {id} = match.params;

    const isAddMode = !id;

    const dispatch = useDispatch();

    const users = useSelector(state => state.user.users);
    const employees = useSelector(state => state.employee.employees);

    const user = users.find(user => user.id === parseInt(id));

    useEffect(() => {

        dispatch(getEmployeesAction());

    }, [dispatch]);

    const formik = useFormik({

        initialValues: {
            username: user ? user.username : '',
            first_name: user ? user.first_name : '',
            last_name: user ? user.last_name : '',
            email: user ? user.email : '',
            is_active: user ? user.is_active : true,
            password: user ? '***********' : '',
            confirm_password: user ? '***********' : '',
            passwordChanged : false,
            employee: user ? user.employee : null
        },

        validationSchema: Yup.object().shape({
            username: Yup.string().required('Username is required'),
            first_name: Yup.string().required('First name is required'),
            last_name: Yup.string().required('Last name is required'),
            password: Yup.string().min(6).required('Password is required'),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
        }),

        onSubmit(values) {

            isAddMode ? dispatch(addUserAction(values, errorCallback)) : dispatch(editUserAction(id, values, errorCallback));
        },

        enableReinitialize: true

    });

    const errorCallback = (error) => {
        formik.setSubmitting(false);
    };

    const handleInputChange = (event, name, value, setFieldValue) => {

        // Check if event is null to prevent multiple value set
        // If on change occurred, the event will be click

        if(null === event && value.url) {
            setFieldValue(name, value.url)
        }

    };

    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[{
                'name': 'Users',
                'link': '/users'
            }]}/>

            <Grid container className={classes.marginTop}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography className={clsx(classes.pageTitle)}>
                            New User
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Paper className={classes.paper}>
                <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="username"
                                variant="outlined"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                value={formik.values.username || ''} onChange={formik.handleChange}
                                error={formik.touched.username && Boolean(formik.errors.username)}
                                helperText={formik.touched.username && formik.errors.username}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="first_name"
                                variant="outlined"
                                required
                                fullWidth
                                id="first_name"
                                label="First Name"
                                value={formik.values.first_name || ''} onChange={formik.handleChange}
                                error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                helperText={formik.touched.first_name && formik.errors.first_name}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="middle_name"
                                variant="outlined"
                                required
                                fullWidth
                                id="middle_name"
                                label="Middle Name"
                                value={formik.values.middle_name || ''} onChange={formik.handleChange}
                                error={formik.touched.middle_name && Boolean(formik.errors.middle_name)}
                                helperText={formik.touched.middle_name && formik.errors.middle_name}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="last_name"
                                variant="outlined"
                                required
                                fullWidth
                                id="last_name"
                                label="Last Name"
                                value={formik.values.last_name || ''} onChange={formik.handleChange}
                                error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                helperText={formik.touched.last_name && formik.errors.last_name}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                type="password"
                                name="password"
                                variant="outlined"
                                required
                                fullWidth
                                id="password"
                                label="Password"
                                value={formik.values.password || ''} onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                type="password"
                                name="confirm_password"
                                variant="outlined"
                                fullWidth
                                id="confirm_password"
                                label="Confirm Password"
                                value={formik.values.confirm_password || ''} onChange={formik.handleChange}
                                error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                                helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                            />
                        </Grid>

                        <Grid item xs={4} sm={3}>
                            {/*<FormControl className={classes.formControl}>*/}
                            {/*    <InputLabel>Employee</InputLabel>*/}
                            {/*    <Select*/}
                            {/*        name='employee'*/}
                            {/*        onChange={formik.handleChange}*/}
                            {/*        value={formik.values.employee || ''}*/}
                            {/*        error={formik.touched.employee && Boolean(formik.errors.employee)}*/}
                            {/*        helperText={formik.touched.employee && formik.errors.employee}*/}
                            {/*    >*/}
                            {/*        {employees && employees.map((t, idx) => <MenuItem*/}
                            {/*            value={t.url}*/}
                            {/*            key={idx}>{t.full_name}</MenuItem>)}*/}
                            {/*    </Select>*/}
                            {/*</FormControl>*/}

                            <Autocomplete
                                filterOptions={utils.autocompleteFilterOptions}
                                className={classes.formControl}
                                disablePortal
                                options={employees}
                                getOptionLabel={option => option.full_name}
                                name='employee'
                                defaultValue={formik.values.employee || null}
                                getOptionSelected={(option, value) => option.url === value.url}
                                onInputChange={(event, value) => {
                                    handleInputChange(event,'employee', formik.values.employee, formik.setFieldValue);
                                }}
                                onChange={(_, value) => value != null ? formik.setFieldValue('employee', value.url) : ''}
                                renderInput={(params) => <TextField {...params}
                                                                    error={formik.errors.employee && Boolean(formik.touched.employee)}
                                                                    helperText={formik.errors.employee && formik.touched.employee}
                                                                    name='employee'
                                                                    label="Employee"/>}
                            />
                        </Grid>

                        <Grid item xs={6} sm={2}>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.is_active}
                                        onChange={event => formik.setFieldValue('is_active', event.target.checked)}
                                        color="primary"
                                        name="Active"
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                }
                                label="Active"
                            />
                        </Grid>

                        <Grid container justify="flex-end">

                            <Grid item xs={6} sm={1}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="default"
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                > Cancel </Button>

                            </Grid>

                            <Grid item xs={6} sm={1}>
                                <Button
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.success}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </SiteWrapper>
    );
}

export default AddEditUserPage;

import {
    contentTypeApplicationJson,
} from "../constants/httpConstants";

import {httpWrapper} from "../helper/Http";

export const getTrips = async (data) => {
    return httpWrapper.get('/trips/', contentTypeApplicationJson, data);
};

export const getTrip = async (id) => {
    return httpWrapper.get('/trips/' + id, contentTypeApplicationJson);
};

export const addTrip = async (trip) => {
    return httpWrapper.post('/trips/' , trip, contentTypeApplicationJson);
};

export const editTrip = async (id, trips) => {
    return httpWrapper.update('/trips/' + id + '/' , trips, contentTypeApplicationJson);
};

export const deleteTrip = async (id) => {
    return httpWrapper.delete('/trips/' + id + '/' , contentTypeApplicationJson);
};

import { push } from 'connected-react-router';

import {
    getSuppliers,
    addSupplier,
    editSupplier,
    deleteSupplier
} from "../../services/SupplierService";


import {
    GET_SUPPLIERS_SUCCESS,
    GET_SUPPLIERS_ERROR,
    ADD_SUPPLIER_SUCCESS,
    ADD_SUPPLIER_ERROR,
    EDIT_SUPPLIER_SUCCESS,
    EDIT_SUPPLIER_ERROR,
    DELETE_SUPPLIER_SUCCESS,
    DELETE_SUPPLIER_ERROR,
    PENDING_SUPPLIER_REQUEST
} from "../ActionTypes";

import {alertActions} from "./AlertAction";

export const getSuppliersAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: PENDING_SUPPLIER_REQUEST
        });
        return getSuppliers(data).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: GET_SUPPLIERS_SUCCESS,
                        payload: response.data
                    });
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: GET_SUPPLIERS_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};



export const addSupplierAction = (supplier, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: PENDING_SUPPLIER_REQUEST
        });
        return addSupplier(supplier).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: ADD_SUPPLIER_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/suppliers'));
                    dispatch(alertActions.success('Supplier added successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: ADD_SUPPLIER_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};


export const editSupplierAction = (id, supplier, errorCallback) => {
    return (dispatch) => {
        dispatch({
            type: PENDING_SUPPLIER_REQUEST
        });
        return editSupplier(id, supplier).then(
            (response) => {
                if (response.data) {

                    dispatch({
                        type: EDIT_SUPPLIER_SUCCESS,
                        payload: response.data
                    });
                    dispatch(push('/suppliers'));
                    dispatch(alertActions.success('Supplier edited successful'));
                }
            },
            (error) => {
                if (error.response) {
                    dispatch({
                        type: EDIT_SUPPLIER_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                    errorCallback && errorCallback(error.response.data);
                }
            }
        );
    };
};

export const deleteSupplierAction = (id) => {
    return (dispatch) => {
        dispatch({
            type: PENDING_SUPPLIER_REQUEST
        });
        return deleteSupplier(id).then(
            (response) => {
                if (response) {
                    dispatch({
                        type: DELETE_SUPPLIER_SUCCESS,
                        payload: id
                    });
                    dispatch(push('/suppliers'));
                    dispatch(alertActions.success('Supplier deleted successful'));
                }
            },
            (error) => {
                if (error.response) {

                    dispatch({
                        type: DELETE_SUPPLIER_ERROR,
                    });
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );
    };
};
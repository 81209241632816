import React, {useCallback, useContext, useEffect, useRef} from "react";
import ConfirmDialogContext from "./ConfirmDialogContext";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function ConfirmDialog({children, ...rest}) {

    const {confirmDialog, setConfirmDialog} = useContext(ConfirmDialogContext);
    const {isOpen, text, handler} = confirmDialog;
    const btnRef = useRef(null);

    const resetConfirmDialog = useCallback(() => {
        setConfirmDialog({ isOpen: false, text: "", handler: null });
    }, [setConfirmDialog]);


    const handleYesClick = () => {
        handler();
        resetConfirmDialog();
    };

    const handleNoClick = () => {
        resetConfirmDialog();
    };

    useEffect(() => {
        const { current } = btnRef;
        if (current) current.focus();
    }, [isOpen]);

    if (!isOpen) return null;


    return(
        <>
            <Dialog
                open={isOpen}
                onClose={handleNoClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button ref={btnRef} onClick={handleNoClick} color="primary">
                        No
                    </Button>
                    <Button onClick={handleYesClick} color="secondary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>);


}

export default ConfirmDialog;
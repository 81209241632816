import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import SiteWrapper from "../../SiteWrapper";
import {useDispatch, useSelector} from "react-redux";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import useStyles from "../../styles";
import {getSettings} from "../../services/SettingService";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import BreadCrumb from "../../components/BreadCrumb";
import Switch from "@material-ui/core/Switch";
import {alertActions} from "../../store/actions/AlertAction";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {addProductAction, editProductAction} from "../../store/actions/ProductAction";
import {getSuppliersAction} from "../../store/actions/SupplierAction";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

function AddEditProductPage({history, match}) {

    const classes = useStyles();

    const {id} = match.params;

    const isAddMode = !id;

    const dispatch = useDispatch();

    const [uoms, setUoms] = useState([]);
    const [categories, setCategories] = useState([]);

    const products = useSelector(state => state.product.products);
    const product = products.find(product => product.id === parseInt(id));
    const suppliers = useSelector(state => state.supplier.suppliers);

    useEffect(() => {

        dispatch(getSuppliersAction());

        getSettings({group: 'unit of measure'}).then(
            (response) => {
                if (response.data) {
                    setUoms(response.data.results)
                }
            },
            (error) => {
                if (error.response) {
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );

        getSettings({group: 'product categories'}).then(
            (response) => {
                if (response.data) {
                    setCategories(response.data.results)
                }
            },
            (error) => {
                if (error.response) {
                    dispatch(alertActions.error(error.response.data));
                }
            }
        );

    }, [dispatch]);


    const errorCallback = (error) => {
        formik.setSubmitting(false);
    };

    const formik = useFormik({

        initialValues: {

            code: product ? product.code : '',
            name: product ? product.name : '',
            quantity: product ? product.quantity : 0.00,
            min_quantity: product ? product.min_quantity : 0.00,
            reorder_level: product ? product.reorder_level : 0.00,
            barcode: product ? product.barcode : '',
            uom: product && product.uom ? product.uom.url : '',
            category: product && product.category ? product.category.url : '',
            vat: product ? product.vat : true,
            use_sno: product ? product.use_sno : false,
            status: product ? product.status : true,
            buying_product: product ? product.buying_product : '',
            supplier: product && product.supplier ? product.supplier.url : ''

        },

        validationSchema: Yup.object().shape({
            code: Yup.string().nullable(),
            barcode: Yup.string().nullable(),
            name: Yup.string().required('Name is required'),
            quantity: Yup.number().typeError('Qty must be a number').nullable(),
            min_quantity: Yup.number().typeError('Minimum quantity must be a number').nullable(),
            reorder_level: Yup.number().typeError('Re order level must be a number').nullable(),
            uom: Yup.string().required('Unit of measure is required'),
            category: Yup.string().required('Category is required'),
            supplier: Yup.string().required('Supplier is required')
        }),

        onSubmit(values) {

            isAddMode ? dispatch(addProductAction(values, errorCallback)) : dispatch(editProductAction(id, values,errorCallback));
        },

        enableReinitialize: true
    });

    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[{
                'name': 'Spare parts',
                'link': '/parts'
            }]}/>

            <Grid container className={classes.marginTop}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography className={clsx(classes.pageTitle)}>
                            New Part
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Paper className={classes.paper}>
                <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="code"
                                variant="outlined"
                                fullWidth
                                id="code"
                                label="Code"
                                value={formik.values.code || ''} onChange={formik.handleChange}
                                error={formik.touched.code && Boolean(formik.errors.code)}
                                helperText={formik.touched.code && formik.errors.code}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="name"
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                value={formik.values.name || ''}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="quantity"
                                variant="outlined"
                                fullWidth
                                id="quantity"
                                label="Quantity"
                                value={formik.values.quantity || ''} onChange={formik.handleChange}
                                error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                                helperText={formik.touched.quantity && formik.errors.quantity}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="min_quantity"
                                variant="outlined"
                                fullWidth
                                id="min_quantity"
                                label="Min Quantity"
                                value={formik.values.min_quantity || ''} onChange={formik.handleChange}
                                error={formik.touched.min_quantity && Boolean(formik.errors.min_quantity)}
                                helperText={formik.touched.min_quantity && formik.errors.min_quantity}
                            />
                        </Grid>


                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="reorder_level"
                                variant="outlined"
                                fullWidth
                                id="reorder_level"
                                label="Re-Order level"
                                value={formik.values.reorder_level || ''} onChange={formik.handleChange}
                                error={formik.touched.reorder_level && Boolean(formik.errors.reorder_level)}
                                helperText={formik.touched.reorder_level && formik.errors.reorder_level}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="buying_price"
                                variant="outlined"
                                fullWidth
                                id="buying_price"
                                label="Buying Price"
                                value={formik.values.buying_price || ''} onChange={formik.handleChange}
                                error={formik.touched.buying_price && Boolean(formik.errors.buying_price)}
                                helperText={formik.touched.buying_price && formik.errors.buying_price}
                            />
                        </Grid>


                        <Grid item xs={4} sm={2}>
                            <FormControl className={classes.formControlMin}>
                                <InputLabel>Unit Of Measure</InputLabel>
                                <Select
                                    name='uom'
                                    onChange={formik.handleChange}
                                    value={formik.values.uom || ''}
                                    error={formik.touched.uom && Boolean(formik.errors.uom)}
                                    helperText={formik.touched.uom && formik.errors.uom}
                                >
                                    {uoms && uoms.map((m, idx) => <MenuItem
                                        value={m.url}
                                        key={idx}>{m.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={4} sm={2}>
                            <FormControl className={classes.formControlMin}>
                                <InputLabel>Category</InputLabel>
                                <Select
                                    name='category'
                                    onChange={formik.handleChange}
                                    value={formik.values.category || ''}
                                    error={formik.touched.category && Boolean(formik.errors.category)}
                                    helperText={formik.touched.category && formik.errors.category}
                                >
                                    {categories && categories.map((m, idx) => <MenuItem
                                        value={m.url}
                                        key={idx}>{m.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={4} sm={2}>
                            <FormControl className={classes.formControlMin}>
                                <InputLabel>Supplier</InputLabel>
                                <Select
                                    name='supplier'
                                    onChange={formik.handleChange}
                                    value={formik.values.supplier || ''}
                                    error={formik.touched.supplier && Boolean(formik.errors.supplier)}
                                    helperText={formik.touched.supplier && formik.errors.supplier}
                                >
                                    {suppliers && suppliers.map((t, idx) => <MenuItem
                                        value={t.url}
                                        key={idx}>{t.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} sm={2}>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.status}
                                        onChange={event => formik.setFieldValue('status', event.target.checked)}
                                        color="primary"
                                        name="Active"
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                }
                                label="Active"
                            />
                        </Grid>

                        <Grid item xs={6} sm={2}>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.vat}
                                        onChange={event => formik.setFieldValue('vat', event.target.checked)}
                                        color="primary"
                                        name="vat"
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                }
                                label="VAT"
                            />
                        </Grid>

                        <Grid item xs={6} sm={2}>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.use_sno}
                                        onChange={event => formik.setFieldValue('use_sno', event.target.checked)}
                                        color="primary"
                                        name="use_sno"
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                }
                                label="Serial No"
                            />
                        </Grid>

                        <Grid container justify="flex-end">

                            <Grid item xs={6} sm={1}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="default"
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                > Cancel </Button>

                            </Grid>

                            <Grid item xs={6} sm={1}>
                                <Button
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.success}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </SiteWrapper>
    );
}

export default AddEditProductPage;

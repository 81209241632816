import React from 'react'

import clsx from 'clsx'
import GlobalFilter from './GlobalFilter'
import {makeStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Toolbar from '@material-ui/core/Toolbar'

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: '#0061a8',
                backgroundColor: '#fafafa',
            }
            : {
                color: '#0061a8',
                backgroundColor: '#fafafa',
            },
    title: {
        flex: '1 1 100%',
    },
}), {index: 1});

const TableToolbar = props => {

    const classes = useToolbarStyles();

    const {
        preGlobalFilteredRows,
        setGlobalFilter,
        globalFilter,
    } = props;

    return (
        <Toolbar className={clsx(classes.root)}>
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
        </Toolbar>
    )
};

TableToolbar.propTypes = {
    setGlobalFilter: PropTypes.func.isRequired,
    preGlobalFilteredRows: PropTypes.array.isRequired,
    // globalFilter: PropTypes.string.isRequired,
};

export default TableToolbar;

import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import SiteWrapper from "../../SiteWrapper";
import {withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import TextField from "@material-ui/core/TextField";
import useStyles from "../../styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {addRouteAction, editRouteAction} from "../../store/actions/RouteAction";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

function AddEditRoutesPage({match}) {

    const classes = useStyles();

    const {id} = match.params;

    const isAddMode = !id;

    const dispatch = useDispatch();

    const [checkPoints, setCheckPoints] = useState([{
        'checkPoint': '',
    }]);

    const routes = useSelector(state => state.route.routes);
    const route = routes.find(route => route.id === parseInt(id));

    useEffect(() => {

        route && route.check_points && setCheckPoints(Object.assign([], route.check_points.map((item, key) => ({
            'name': item.name ? item.name : '',
        }))));


    }, [route]);

    const formik = useFormik({

        initialValues: {
            start: route ? route.start : '',
            mileage: route ? route.mileage : '',
            distance: route ? route.distance : '',
            check_points: checkPoints ? Object.assign([], checkPoints.map((item, key) => ({
                'name': item.name ? item.name : '',
            }))) : [],
            status: route ? !!route.status : true,
            round_trip: route ? route.round_trip : true,
        },

        validationSchema: Yup.object().shape({
            start: Yup.string('Must be a string').required('Start location is required'),
            mileage: Yup.number().typeError('Mileage must be a number').required('Mileage is required'),
            distance: Yup.number().typeError('Distance must be a number').required('Distance is required'),
            check_points: Yup.array()
                .min(1, "Enter at least one check point")
                .of(
                    Yup.object()
                        .shape({
                            name: Yup.string('Must be a string').required('Name is required'),
                        })
                        .nullable()
                        .required("Check point is required")
                ),

        }),

        onSubmit(values) {

            isAddMode ? dispatch(addRouteAction(values, errorCallback)) : dispatch(editRouteAction(id, values, errorCallback));
        },

        enableReinitialize: true
    });

    const errorCallback = (error) => {
        formik.setSubmitting(false);
    };

    const handleAddCheckPoint = index => {

        setCheckPoints([...checkPoints, {
            'name': ''
        }]);
    };

    const handleRemoveCheckPoint = index => {

        const list = [...checkPoints];

        list.splice(index, 1);

        setCheckPoints(list);
        formik.values.check_points[index] && formik.values.check_points.splice(index, 1);
    };

    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[
                {
                    'name': 'Routes',
                    'link': '/routes'
                }
            ]}/>

            <Grid container className={classes.marginTop}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography className={clsx(classes.pageTitle)}>
                            New Route
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Paper className={classes.paper}>
                <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                    <Grid container spacing={4}>

                        <Grid item xs={3} sm={2}>
                            <TextField
                                required
                                fullWidth
                                name='start'
                                label='Start'
                                onChange={formik.handleChange}
                                value={formik.values.start}
                                error={formik.errors.start && Boolean(formik.touched.start)}
                                helperText={formik.errors.start && formik.touched.start}
                            />
                        </Grid>

                        <Grid item xs={3} sm={2}>
                            <TextField
                                required
                                fullWidth
                                name='mileage'
                                label='Mileage'
                                onChange={formik.handleChange}
                                value={formik.values.mileage}
                                error={formik.errors.mileage && Boolean(formik.touched.mileage)}
                                helperText={formik.errors.mileage && formik.touched.mileage}
                            />
                        </Grid>

                        <Grid item xs={3} sm={2}>
                            <TextField
                                required
                                fullWidth
                                name='distance'
                                label='Distance'
                                onChange={formik.handleChange}
                                value={formik.values.distance}
                                error={formik.errors.distance && Boolean(formik.touched.distance)}
                                helperText={formik.errors.distance && formik.touched.distance}
                            />
                        </Grid>
                        <Grid item xs={6} sm={2}>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.status}
                                        onChange={event => formik.setFieldValue('status', event.target.checked)}
                                        color="primary"
                                        name="tatus"
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                }
                                label="Active"
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.round_trip}
                                        onChange={event => formik.setFieldValue('round_trip', event.target.checked)}
                                        color="primary"
                                        name="Round Trip"
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                }
                                label="Round Trip"
                            />
                        </Grid>
                    </Grid>

                    {checkPoints.map((checkPoint, i) => {

                        const checkPointErrors = (formik.errors.check_points?.length && formik.errors.check_points[i]) || {};
                        const checkPointTouched = (formik.touched.check_points?.length && formik.touched.check_points[i]) || {};
                        const checkPointValue = (formik.values.check_points?.length && formik.values.check_points[i]) || {};

                        return (<Grid container spacing={2} key={i} className={classes.marginTop}>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    required
                                    fullWidth
                                    name={`check_points.${i}.name`} label='Check Point'
                                    onChange={formik.handleChange}
                                    value={(checkPointValue && checkPointValue.name) || ''}
                                    error={checkPointErrors.name && Boolean(checkPointTouched.name)}
                                    helperText={checkPointErrors.name && checkPointTouched.name}
                                />
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                {checkPoints.length !== 1 && checkPoints.length - 1 !== i &&
                                <IconButton className={classes.danger} aria-label="Remove line" component="span"
                                            onClick={() => handleRemoveCheckPoint(i)}>
                                    <RemoveIcon/>
                                </IconButton>}
                            </Grid>
                        </Grid>)
                    })}

                    <Grid container>
                        <Grid item>
                            <Button className={classes.info} aria-label="Add line"
                                    onClick={() => handleAddCheckPoint()}> <AddIcon/> Add Line
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-end" className={classes.marginTop}>
                        <Grid item xs={6} sm={1}>
                            <Button
                                type="submit"
                                size="small"
                                variant="contained"
                                color="default"
                                className={classes.primary}
                                disabled={formik.isSubmitting}
                            > Save </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </SiteWrapper>
    );
}

export default withRouter(AddEditRoutesPage);

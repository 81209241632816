import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import SiteWrapper from "../../SiteWrapper";
import {useDispatch, useSelector} from "react-redux";
import {getSuppliersAction} from "../../store/actions/SupplierAction";
import {getProductsAction} from "../../store/actions/ProductAction";
import {addPurchaseAction, editPurchaseAction} from "../../store/actions/PurchaseAction";
import BreadCrumb from "../../components/BreadCrumb";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import useStyles from "../../styles";
import dayjs from "dayjs";
import RemoveIcon from '@material-ui/icons/Remove';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {utils} from "../../helper/Utils";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormHelperText from "@material-ui/core/FormHelperText";

function AddEditPurchasePage({match}) {

    const classes = useStyles();

    const {id} = match.params;

    const isAddMode = !id;

    const dispatch = useDispatch();

    const [purchaseLines, setPurchaseLines] = useState([{
        'product': '',
        'quantity': 0.00,
        'unit_price': 0.00,
        'sn_no': ''
    }]);

    const purchases = useSelector(state => state.purchase.purchases);
    const suppliers = useSelector(state => state.supplier.suppliers);
    const products = useSelector(state => state.product.products);
    const purchase = purchases.find(purchase => purchase.id === parseInt(id));

    const [subTotal, setSubTotal] = useState(0.00);
    const [vatTotal, setVatTotal] = useState(0.00);
    const [total, setTotal] = useState(0.00);


    useEffect(() => {

        purchase && purchase.purchase_lines && setPurchaseLines(Object.assign([], purchase.purchase_lines.map((item, key) => ({
            'product': item ? item.product : null,
            'quantity': item ? item.quantity : 0.00,
            'unit_price': item ? item.unit_price : 0.00,
            'sn_no': item ? item.sn_no : '',
        }))));


        dispatch(getSuppliersAction());
        dispatch(getProductsAction());

    }, [dispatch, purchase]);


    useEffect(() => {
        findTotal()
    });



    const formik = useFormik({

        initialValues: {
            delivery_date: purchase ? purchase.delivery_date : dayjs().format("YYYY-MM-DD"),
            supplier: purchase && purchase.supplier ? purchase.supplier.url : '',
            delivery_note: purchase ? purchase.delivery_note : '',
            vat: purchase ? purchase.vat : 18,
            driver: purchase ? purchase.driver : '',
            truck: purchase ? purchase.truck : '',
            purchase_lines: purchase && purchase.purchase_lines ? Object.assign([], purchase.purchase_lines.map((item, key) => ({
                'product': item ? item.product : null,
                'quantity': item ? item.quantity : 0.00,
                'unit_price': item ? item.unit_price : 0.00,
                'sn_no': item ? item.sn_no : '',
            }))) : [],
            status: 0
        },

        validationSchema: Yup.object().shape({
            delivery_date: Yup.date("YYYY-mm-dd").required('Date is required'),
            supplier: Yup.string().required("Supplier is required"),
            delivery_note: Yup.string().required("Delivery note is required"),
            vat: Yup.number().typeError("Must be a number").required("VAT is required"),
            driver: Yup.string().required("Driver is required"),
            truck: Yup.string().required("Truck is required"),
            purchase_lines: Yup.array()
                .min(1, "Enter at least one purchase line")
                .of(
                    Yup.object()
                        .shape({
                            product: Yup.string('Must be a string').required('Product is required'),
                            quantity: Yup.number().required('Quantity is required').typeError('Qty must be a number'),
                            unit_price: Yup.number().required('Unit price is required').typeError('Price must be a number'),
                            sn_no: Yup.string('Must be a string').nullable()
                        })
                        .required("Purchase line is required")
                ),
        }),

        onSubmit(values) {

            isAddMode ? dispatch(addPurchaseAction(values, errorCallback)) : dispatch(editPurchaseAction(id, values, errorCallback));
        },

        enableReinitialize: true
    });

    const handleAddPurchaseLine = index => {

        setPurchaseLines([...purchaseLines, {
            'product': '',
            'quantity': 0.00,
            'uom': '',
            'unit_price': 0.00,
            'sn_no': ''
        }]);

    };

    const handleRemovePurchaseLine = index => {

        const list = [...purchaseLines];

        list.splice(index, 1);
        setPurchaseLines(list);

        // Delete element from formik

        formik.values.purchase_lines[index] && formik.values.purchase_lines.splice(index, 1);
    };

    const errorCallback = (error) => {
        formik.setSubmitting(false);
    };


    const findTotal = () => {

        let sub = 0.00;

        formik.values.purchase_lines.map((line) => {

            let quantity = line.quantity ? line.quantity : 1;
            let unit_price = line.unit_price ? line.unit_price : 1;

            sub += (quantity * unit_price);

            return sub;
        });

        setSubTotal(sub);

        let vat = (formik.values.vat / 100) * sub;

        setVatTotal(vat);

        setTotal(sub + vat);

    };

    const handleInputChange = (event, name, value, setFieldValue) => {

        // Check if event is null to prevent multiple value set
        // If on change occurred, the event will be click

        if(null === event && value.url) {
            setFieldValue(name, value.url)
        }
    };

    return (
        <SiteWrapper>
            <BreadCrumb crumbs={[{
                'name': 'Puchases',
                'link': '/purchases'
            }]}/>

            <Grid container className={classes.marginTop}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography className={clsx(classes.pageTitle)}>
                            New Purchase
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Paper className={classes.paper}>
                <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>

                    <Grid container spacing={2}>

                        <Grid item xs={4} sm={2}>
                            <MuiPickersUtilsProvider utils={DayjsUtils}>
                                <KeyboardDatePicker
                                    id="date-picker-dialog"
                                    label="Delivery Date"
                                    format="MM/DD/YYYY"
                                    maxDate={utils.maxDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    value={formik.values.delivery_date}
                                    onChange={val => formik.setFieldValue('delivery_date', dayjs(val).format("YYYY-MM-DD"))}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4} sm={3}>
                            <FormControl className={classes.formControlMin} error={formik.errors.supplier && Boolean(formik.touched.supplier)}>
                                <InputLabel>Supplier</InputLabel>
                                <Select
                                    name='supplier'
                                    onChange={formik.handleChange}
                                    value={formik.values.supplier}
                                    defaultValue={formik.values.supplier || ''}
                                >
                                    {suppliers && suppliers.map((m, idx) => <MenuItem
                                        value={m.url}
                                        key={idx}>{m.name}</MenuItem>)}
                                </Select>
                                {formik.errors.supplier && formik.touched.supplier && <FormHelperText>{formik.errors.supplier}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} sm={3}>
                            <TextField
                                name='delivery_note'
                                label='Invoice No'
                                onChange={formik.handleChange}
                                value={formik.values.delivery_note}
                                error={formik.errors.delivery_note && Boolean(formik.touched.delivery_note)}
                                helperText={formik.errors.delivery_note && formik.touched.delivery_note}
                            />
                        </Grid>

                        <Grid item xs={3} sm={1}>
                            <TextField
                                name='vat'
                                label='VAT'
                                onChange={formik.handleChange}
                                onKeyUp={(e) => {
                                    findTotal(e);
                                }}
                                value={formik.values.vat}
                                error={formik.errors.vat && Boolean(formik.touched.vat)}
                                helperText={formik.errors.vat && formik.touched.vat}
                            />
                        </Grid>

                    </Grid>

                    <Grid container spacing={2}>

                        <Grid item xs={3} sm={2}>
                            <TextField
                                name='driver'
                                label='Driver'
                                onChange={formik.handleChange}
                                value={formik.values.driver}
                                error={formik.errors.driver && Boolean(formik.touched.driver)}
                                helperText={formik.errors.driver && formik.touched.driver}
                            />
                        </Grid>

                        <Grid item xs={3} sm={2}>
                            <TextField
                                name='truck'
                                label='Truck'
                                onChange={formik.handleChange}
                                value={formik.values.truck}
                                error={formik.errors.truck && Boolean(formik.touched.truck)}
                                helperText={formik.errors.truck && formik.touched.truck}
                            />
                        </Grid>

                    </Grid>


                    <Grid container spacing={2} className={classes.marginTop}>
                        <Grid item>
                            <Typography variant="subtitle2">Purchase Items</Typography>
                        </Grid>

                        {formik.errors.purchase_lines && typeof formik.errors.purchase_lines == 'string' && <Grid item md={12} xs={12}>
                            <FormHelperText error>** {formik.errors.purchase_lines}</FormHelperText>
                        </Grid>}
                    </Grid>


                    {purchaseLines.map((purchaseLine, i) => {

                        const purchaseLineErrors = (formik.errors.purchase_lines?.length && formik.errors.purchase_lines[i]) || {};
                        const purchaseLineTouched = (formik.touched.purchase_lines?.length && formik.touched.purchase_lines[i]) || {};
                        const purchaseLineValue = (formik.values.purchase_lines?.length && formik.values.purchase_lines[i]) || {};

                        return (
                            <Grid container spacing={2} key={i}>
                                <Grid item xs={12} sm={5}>
                                    {/*<FormControl className={classes.formControl}>*/}
                                    {/*    <InputLabel>Product</InputLabel>*/}
                                    {/*    <Select*/}
                                    {/*        name={`purchase_lines.${i}.product`}*/}
                                    {/*        onChange={formik.handleChange}*/}
                                    {/*        value={(purchaseLineValue && purchaseLineValue.product) || ''}*/}
                                    {/*        error={purchaseLineErrors.product && Boolean(purchaseLineTouched.product)}*/}
                                    {/*        helperText={purchaseLineErrors.product && purchaseLineTouched.product}*/}
                                    {/*    >*/}
                                    {/*        {products && products.map((m, idx) => <MenuItem*/}
                                    {/*            value={m.url}*/}
                                    {/*            key={idx}>{m.name}</MenuItem>)}*/}
                                    {/*    </Select>*/}
                                    {/*</FormControl>*/}

                                    <Autocomplete
                                        filterOptions={utils.autocompleteFilterOptions}
                                        className={classes.formControl}
                                        disablePortal
                                        options={products}
                                        getOptionLabel={option => option.name}
                                        name={`purchase_lines.${i}.product`}
                                        defaultValue={(purchaseLineValue && purchaseLineValue.product) || null}
                                        getOptionSelected={(option, value) => option.url === value.url}
                                        onInputChange={(event, value) => {
                                            handleInputChange(event,`purchase_lines.${i}.product`, purchaseLineValue.product, formik.setFieldValue);
                                        }}
                                        onChange={(_, value) => value != null ? formik.setFieldValue(`purchase_lines.${i}.product`, value.url) : ''}
                                        renderInput={(params) => <TextField {...params}
                                                                            error={purchaseLineErrors.machine && Boolean(purchaseLineTouched.machine)}
                                                                            helperText={purchaseLineErrors.machine && purchaseLineTouched.machine}
                                                                            name={`purchase_lines.${i}.product`}
                                                                            label="Product"/>}
                                        classes={{ paper: classes.autocomplete }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <TextField
                                        required
                                        name={`purchase_lines.${i}.quantity`}
                                        label='Quantity'
                                        onChange={formik.handleChange}
                                        onKeyUp={() => {
                                            findTotal();
                                        }}
                                        value={(purchaseLineValue && purchaseLineValue.quantity) || ''}
                                        error={Boolean(purchaseLineErrors.quantity)}
                                        helperText={purchaseLineErrors.quantity}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        required
                                        name={`purchase_lines.${i}.unit_price`}
                                        label='Unit Price'
                                        onChange={formik.handleChange}
                                        onKeyUp={() => {
                                            findTotal();
                                        }}
                                        value={(purchaseLineValue && purchaseLineValue.unit_price) || ''}
                                        error={Boolean(purchaseLineErrors.unit_price)}
                                        helperText={purchaseLineErrors.unit_price}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <TextField
                                        name={`purchase_lines.${i}.sn_no`}
                                        label='Serial Number'
                                        onChange={formik.handleChange}
                                        value={(purchaseLineValue && purchaseLineValue.sn_no) || ''}
                                        error={Boolean(purchaseLineErrors.sn_no)}
                                        helperText={purchaseLineErrors.sn_no}
                                    />
                                </Grid>
                                <Grid item xs={2} sm={1}>

                                    {purchaseLines.length !== 1 && purchaseLines.length - 1 !== i &&
                                    <IconButton className={classes.danger} aria-label="Remove line" component="span"
                                                onClick={() => handleRemovePurchaseLine(i)}>
                                        <RemoveIcon/>
                                    </IconButton>}
                                </Grid>
                            </Grid>)
                    })}

                    <Grid container className={classes.marginTop}>
                        <Grid item>
                            <Button className={classes.info} aria-label="Add line"
                                    onClick={() => handleAddPurchaseLine()}> <AddIcon/> Add Line
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.marginTop} justify="flex-end">
                        <Grid item md={2}><Typography variant="subtitle2">SubTotal</Typography></Grid>
                        <Grid item md={3}>{utils.numberFormatter(subTotal)}</Grid>
                    </Grid>

                    <Grid container className={classes.marginTop} justify="flex-end">
                        <Grid item md={2}><Typography variant="subtitle2">VAT
                            ({utils.numberFormatter(formik.values.vat)} %)</Typography></Grid>
                        <Grid item md={3}>{utils.numberFormatter(vatTotal)}</Grid>
                    </Grid>

                    <Grid container className={classes.marginTop} justify="flex-end">
                        <Grid item md={2}><Typography variant="subtitle2">Total</Typography></Grid>
                        <Grid item md={3}>{utils.numberFormatter(total)}</Grid>
                    </Grid>

                    <Grid container justify="flex-end" className={classes.marginTop}>
                        <Grid item xs={6} md={2}>
                            <Button
                                type="submit"
                                size="small"
                                variant="contained"
                                className={classes.success}
                                disabled={formik.isSubmitting}
                                onClick={() => formik.setFieldValue('status', 1)}
                            >
                                Submit for approval
                            </Button>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Button
                                type="submit"
                                size="small"
                                variant="contained"
                                color="default"
                                className={classes.primary}
                                disabled={formik.isSubmitting}
                                onClick={() => formik.setFieldValue('status', 0)}
                            > Save </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </SiteWrapper>
    );
}

export default AddEditPurchasePage;
